import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import ReportsList from 'containers/reports/ReportsList';
import ReportDetail from './ReportDetail';

function ReportsRoutes() {
  const intl = useIntl();

  return (
    <Switch data-test='reportsComponent'>
      <PrivateRoute
        permission='REPORTS_INDEX'
        exact
        path='/reports'
        component={withLayout(
          ReportsList,
          intl.formatMessage({id: 'ROUTES.REPORTS.INDEX'}),
        )}
      />
      <PrivateRoute
        permission='REPORTS_SHOW'
        exact
        path='/reports/:id'
        component={withLayout(
          ReportDetail,
          intl.formatMessage({id: 'ROUTES.REPORTS.SHOW'}),
        )}
      />
    </Switch>
  );
}

export default ReportsRoutes;
