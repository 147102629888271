import React from 'react';
import {injectIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import StarterList from '../../components/starter/StarterList';
import API from '../../common/utils/API';

export class WaterTypesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      page: 1,
      pageSize: 12,
      pageCount: 2,
      total: 0,
      searchTimeout: 0,
      search: '',
      error: false,
    };
  }

  async componentDidMount() {
    await Promise.all([this.fetchData()]);
  }

  fetchData = async (pageNumber = 1) => {
    try {
      const result = await API.waterTypes.get(pageNumber, this.state.search);
      this.setState({
        items: result.items,
        page: result.page,
        pageSize: result.pageSize,
        pageCount: result.pageCount,
        total: result.total,
        error: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({error: true}, this.clearErroMessage);
    }
  };

  handleSearchKeywordChange = (event) => {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }
    this.setState({
      [event.target.name]: event.target.value,
      searchTimeout: setTimeout(() => {
        return this.fetchData(1);
      }, 300),
    });
  };

  handleSearchKeywordClear = (field = 'search') => {
    this.setState(
      {
        pageSize: 1,
        [field]: '',
      },
      () => {
        return this.fetchData(1);
      },
    );
  };

  handlePageChange = (pageNumber) => {
    return this.fetchData(pageNumber);
  };

  render() {
    return (
      <div data-test='waterTypesListComponent'>
        <Alert
          data-test='errorAlert'
          variant='danger'
          className='mb-4'
          show={this.state.error}
        >
          <p className='mb-0'>
            {this.props.intl.formatMessage({
              id: 'ERROR_PAGES.LOADING_DATA',
            })}
          </p>
        </Alert>

        <StarterList
          onSearchKeywordClear={this.handleSearchKeywordClear}
          onSearchKeywordChange={this.handleSearchKeywordChange}
          onPageChange={this.handlePageChange}
          page={this.state.page}
          pageSize={this.state.pageSize}
          total={this.state.total}
          addNewPermission='WATERTYPES_CREATE'
          addNewLink='water-types/create'
          data={{
            headerTitles: [
              '#',
              this.props.intl.formatMessage({
                id: 'WATERTYPES.NAME_TABLE_HEADER',
              }),
            ],
            rows: this.state.items.map((wt) => ({
              content: [wt.id, wt.name],
              link: `/water-types/${wt.id}`,
            })),
          }}
        ></StarterList>
      </div>
    );
  }
}

export default injectIntl(WaterTypesList);
