import React from 'react';
import { injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Row, Col} from 'react-bootstrap';
import API from '../../common/utils/API';
import Property from '../../common/models/Property';
import MapContainer from '../../components/Map';
import IrrigationSystemType from './components/IrrigationSystemType';
import WaterHole from './components/WaterHole';
import Can from 'common/security/Can';

export class PropertyDetail extends React.Component {
constructor(props) {
  super(props);
  this.state = {
      property: new Property(),
      loading: false,
      isLoadErrorModalOpen: false,
      isDeleteModalOpen: false,
      error: '',
  }
}

async componentDidMount() {
  await this.loadDetail();
}

loadDetail = async () => {
  this.setState({
      loading: true,
  });
  try {
      const property = await API.properties.detail(this.props.match.params.id);
      this.setState({
          property,
          loading: false,
          isLoadErrorModalOpen: false,
      });
  } catch (error) {
      this.setState({
          isLoadErrorModalOpen: true,
          loading: false,
      });
  }
}

render() {
  return (
    <>
      <Alert data-test='errorAlert'
          variant='danger'
          className='mb-4'
          show={!!this.state.error}>
      </Alert>

      <div className='mb-4 d-flex justify-content-end'>
          <Button as={NavLink}
              to='/properties'
              className='btn btn-dark btn-bold btn-light-dark'
          >
              {this.props.intl.formatMessage({
                  id: 'LIST.BACK_TO_LIST',
              })}
          </Button>
      </div>

      <Card className='mb-4'>
          <Card.Header>
              {this.props.intl.formatMessage({
                  id: 'USERS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
              })}
          </Card.Header>
          <Card.Body>
              <Row>
                  <Col md={4}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_NAME_FIELD_LABEL'
                          })}
                      </label>
                      <p>{this.state.property.name}</p>
                  </Col>

                  <Col md={4}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_PRODUCER_FIELD_LABEL'
                          })}
                      </label>
                      <p>{this.state.property.producer.name}</p>
                  </Col>

                  <Col md={4}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_TYPE_FIELD_LABEL'
                          })}
                      </label>
                      <p>{ this.state.property.propertyType ? 
                          this.props.intl.formatMessage({id: 'PROPERTIES.PROPERTY_TYPE_' + this.state.property.propertyType}):''
                      }</p>
                  </Col>
                  
              </Row>
          </Card.Body>
          <Card.Header>
              {this.props.intl.formatMessage({
                  id: 'PROPERTIES.DETAIL_SECOND_TITLE',
              })}
          </Card.Header>
          <Card.Body>
              <Row>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_AREA_FIELD_LABEL'
                          })}
                      </label>
                      <p>{this.state.property.area}</p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_MSNM_FIELD_LABEL'
                          })}
                      </label>
                      <p>{this.state.property.masl}</p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_TYPE_FIELD_LABEL'
                          })}
                      </label>
                      <p>{
                          this.props.intl.formatMessage({id: 'PROPERTIES.PROPERTY_TYPE_' + this.state.property.propertyType})
                      }</p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_GROUNDTYPE_FIELD_LABEL'
                          })}
                      </label>
                      <p>{
                          this.state.property.soilType.name
                      }</p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_WATERTYPE_FIELD_LABEL'
                          })}
                      </label>
                      <p>{
                          this.state.property.waterType.name
                      }</p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_RAINSEASON_FIELD_LABEL'
                          })}
                      </label>
                      <p>{
                          this.state.property.rainSeason
                      }</p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_FROZENPERYEAR_FIELD_LABEL'
                          })}
                      </label>
                      <p>{this.state.property.frostPerYear}</p>
                  </Col>
              </Row>
          </Card.Body>
      </Card>

      <Card className='mb-4'>
          <Card.Header>
              <Row>
                  <Col md={6}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_ADDRESS_TABLE_HEADER',
                          })}
                      </label>
                      <p>{this.state.property.address}</p>
                  </Col>
                  <Col md={6}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_BOUNDARIES_FIELD_LABEL',
                          })}
                      </label>
                      <p>{this.state.property.boundaries}</p>
                  </Col>
              </Row>
          </Card.Header>
          <Card.Body>
              <Col>
                  <div style={{ height: `350px`, width: `100% !important`,
                  border:'1px solid #999'}}>
                      <MapContainer 
                          lat={this.state.property.latitude}
                          lng={this.state.property.longitude}
                          API_KEY={process.env.REACT_APP_GOOGLE_API_KEY} 
                          property={this.state.property.name}
                          address={this.state.property.address}
                      />
                  </div>
              </Col>
          </Card.Body>
      </Card>

      <Card className='mb-4'>
          <Card.Header>
              {this.props.intl.formatMessage({
                  id: 'PROPERTIES.DETAIL_THIRD_TITLE',
              })}
          </Card.Header>
          <Card.Body>
              <Row>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_LASTESTCROPS_FIELD_LABEL',
                          })}
                      </label>
                      <p>{this.state.property.latestCrops}</p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_HASCERTIFICATION_LABEL',
                          })}
                      </label>
                      <p className={this.state.property.certificateType ? 'text-success':'text-danger'}>
                          {   
                              this.state.property.certificateType ? 
                              this.props.intl.formatMessage({id: 'PROPERTIES.TRUE_VALUE'}):
                              this.props.intl.formatMessage({id: 'PROPERTIES.FALSE_VALUE'})
                          }
                      </p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_CERTIFICATION_VALIDITY_LABEL',
                          })}
                      </label>
                      <p>{this.state.property.certificateValidityFormatted}</p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_CERTIFICATION_TYPE_LABEL',
                          })}
                      </label>
                      <p>{this.state.property.certificateType.name}</p>
                  </Col>
              </Row>
              <WaterHole data={this.state.property}/>
          </Card.Body>
          <Card.Header>
              {this.props.intl.formatMessage({
                  id: 'PROPERTIES.DETAIL_FIFTH_TITLE',
              })}
          </Card.Header>
          <Card.Body>
              <IrrigationSystemType type={this.state.property.irrigationSystemType} 
                  data={this.state.property} />
          </Card.Body>
          <Card.Header>
              {this.props.intl.formatMessage({
                  id: 'PROPERTIES.DETAIL_FOURTH_TITLE',
              })}
          </Card.Header>
          <Card.Body>
              <Row>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_HAS_LAVATORIES',
                          })}
                      </label>
                      <p className={this.state.property.lavatories ? 'text-success':'text-danger'}>
                          {this.state.property.lavatories ? 
                          this.props.intl.formatMessage({id: 'PROPERTIES.TRUE_VALUE'}):
                          this.props.intl.formatMessage({id: 'PROPERTIES.FALSE_VALUE'})
                      }</p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_HAS_SINKS',
                          })}
                      </label>
                      <p className={this.state.property.sinks ? 'text-success':'text-danger'}>
                          {this.state.property.sinks ? 
                          this.props.intl.formatMessage({id: 'PROPERTIES.TRUE_VALUE'}):
                          this.props.intl.formatMessage({id: 'PROPERTIES.FALSE_VALUE'})
                      }</p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_HAS_DININGROOMS',
                          })}
                      </label>
                      <p className={this.state.property.diningRooms ? 'text-success':'text-danger'}>
                          {this.state.property.diningRooms ? 
                          this.props.intl.formatMessage({id: 'PROPERTIES.TRUE_VALUE'}):
                          this.props.intl.formatMessage({id: 'PROPERTIES.FALSE_VALUE'})
                      }</p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_HAS_TRASHCANS',
                          })}
                      </label>
                      <p className={this.state.property.trashCans ? 'text-success':'text-danger'}>
                          {this.state.property.trashCans ? 
                          this.props.intl.formatMessage({id: 'PROPERTIES.TRUE_VALUE'}):
                          this.props.intl.formatMessage({id: 'PROPERTIES.FALSE_VALUE'})
                      }</p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_HAS_SIGNS',
                          })}
                      </label>
                      <p className={this.state.property.signs ? 'text-success':'text-danger'}>
                          {this.state.property.signs ? 
                          this.props.intl.formatMessage({id: 'PROPERTIES.TRUE_VALUE'}):
                          this.props.intl.formatMessage({id: 'PROPERTIES.FALSE_VALUE'})
                          }
                      </p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_HAS_RECYCLECANS',
                          })}
                      </label>
                      <p className={this.state.property.recycleCans ? 'text-success':'text-danger'}>
                          {this.state.property.recycleCans ? 
                          this.props.intl.formatMessage({id: 'PROPERTIES.TRUE_VALUE'}):
                          this.props.intl.formatMessage({id: 'PROPERTIES.FALSE_VALUE'})
                          }
                      </p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_HAS_WASTEAREAS',
                          })}
                      </label>
                      <p className={this.state.property.wasteAreas ? 'text-success':'text-danger'}>
                          {this.state.property.wasteAreas ? 
                          this.props.intl.formatMessage({id: 'PROPERTIES.TRUE_VALUE'}):
                          this.props.intl.formatMessage({id: 'PROPERTIES.FALSE_VALUE'})
                          }
                      </p>
                  </Col>
                  <Col md={3}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_HAS_INCOMEPOLICIES',
                          })}
                      </label>
                      <p className={this.state.property.incomePolicies ? 'text-success':'text-danger'}>
                          {this.state.property.incomePolicies ? 
                          this.props.intl.formatMessage({id: 'PROPERTIES.TRUE_VALUE'}):
                          this.props.intl.formatMessage({id: 'PROPERTIES.FALSE_VALUE'})
                          }
                      </p>
                  </Col>
              </Row>
              <Row>
                  <Col md={6}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_NOTANQUES_LABEL',
                          })}
                      </label>
                      <p>{this.state.property.waterTanks}</p>
                  </Col>
                  <Col md={6}>
                      <label className='field-title'>
                          {this.props.intl.formatMessage({
                              id: 'PROPERTIES.PROPERTY_CAPACITY_LABEL',
                          })}
                      </label>
                      <p>{this.state.property.tanksCapacity}</p>
                  </Col>
              </Row>
          </Card.Body>
      </Card>

      <Card className='mb-4'>
        <Card.Header>
          {this.props.intl.formatMessage({
            id: 'PROPERTIES.SECTORS_TITLE',
          })}
        </Card.Header>
        <Card.Body>
          <table className='starter-list table table-bordered table-striped'>
            <thead>
              <tr>
                <th>
                  {this.props.intl.formatMessage({
                    id: 'PROPERTIES.TABLE_SECTORS_NAME_LABEL',
                  })}
                </th>
                <th>
                  {this.props.intl.formatMessage({
                    id: 'PROPERTIES.TABLE_SECTORS_SURFACE_LABEL',
                  })}
                </th>
                <th>
                  {this.props.intl.formatMessage({
                    id: 'PROPERTIES.TABLE_SECTORS_VARIETY_LABEL',
                  })}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.property.sectors.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.alias}</td>
                    <td>{item.surface}</td>
                    <td>{item.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card.Body>
      </Card>

      <div className='d-flex justify-content-between'>
          <div>
              <Can run='PROPERTY_EDIT'>
                  <Button
                      as={NavLink}
                      to={`/properties/${this.props.match.params.id}/edit`}
                      className='btn-warning btn-bold ml-2'
                  >
                      {this.props.intl.formatMessage({
                          id: 'PROPERTIES.EDIT_PROPERTY_BUTTON_TEXT',
                      })}
                  </Button>
              </Can>
          </div>
      </div>
    </>
  );
}
}

export default injectIntl(PropertyDetail);