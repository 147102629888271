import React from 'react';
import {NavLink} from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Nav from 'react-bootstrap/Nav';

import * as FeatherIcons from 'react-icons/fi';
import {AiFillBank, AiFillSafetyCertificate} from 'react-icons/ai';
import {
    FaUsers,
    FaUserFriends,
    FaCodiepie,
    FaBook,
    FaMapMarked,
    FaMapMarkedAlt,
    FaWater,
    FaTruck,
    FaSeedling,
} from 'react-icons/fa';
import {
    GiCottonFlower,
    GiTwirlyFlower,
    GiDustCloud,
    GiPlagueDoctorProfile,
    GiFlowers,
} from 'react-icons/gi';

import {useIntl} from 'react-intl';
import './Menu.scss';

function Menu() {
    const intl = useIntl();

    return (
        <Nav className='menu'>
            <Nav.Link as={NavLink} to='/' exact={true}>
                <FeatherIcons.FiGrid />
                <span>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</span>
            </Nav.Link>
            <Nav.Item as='a' className='menu-separator'>
                <FeatherIcons.FiMoreHorizontal /> <span>Configuración</span>
            </Nav.Item>
            <Nav.Link as={NavLink} to='/users'>
                <FeatherIcons.FiUsers />
                <span>{intl.formatMessage({id: 'MENU.USERS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/roles'>
                <FeatherIcons.FiUserCheck />
                <span>{intl.formatMessage({id: 'MENU.ROLES'})}</span>
            </Nav.Link>
            <Nav.Item as='a' className='menu-separator'>
                <FeatherIcons.FiMoreHorizontal />
                <span>General</span>
            </Nav.Item>
            <Nav.Link as={NavLink} to='/licensees'>
                <FaUserFriends />
                <span>{intl.formatMessage({id: 'MENU.LICENSEE'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/producers'>
                <FaUsers />
                <span>{intl.formatMessage({id: 'MENU.PRODUCERS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/properties'>
                <FaUsers />
                <span>{intl.formatMessage({id: 'MENU.PROPERTIES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/deliveries'>
                <FaTruck />
                <span>{intl.formatMessage({id: 'MENU.DELIVERIES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/productions'>
                <FaSeedling />
                <span>{intl.formatMessage({id: 'MENU.PRODUCTIONS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/reports'>
                <FaCodiepie />
                <span>{intl.formatMessage({id: 'MENU.REPORTS'})}</span>
            </Nav.Link>

            {/* <!-- collapse menu --> */}
            <Accordion>
                <Accordion.Toggle as={Nav.Link} variant='link' eventKey='0'>
                    <FaBook />
                    <span>Catálogos</span>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey='0'>
                    <>
                        <Nav.Link
                            style={{marginLeft: 15}}
                            as={NavLink}
                            to='/plants'
                        >
                            <GiCottonFlower />
                            <span>
                                {intl.formatMessage({id: 'MENU.PLANTS'})}
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            style={{marginLeft: 15}}
                            as={NavLink}
                            to='/varieties'
                        >
                            <GiTwirlyFlower />
                            <span>Variedades</span>
                        </Nav.Link>
                        <Nav.Link
                            style={{marginLeft: 15}}
                            as={NavLink}
                            to='/states'
                        >
                            <FaMapMarked />
                            <span>
                                {intl.formatMessage({id: 'MENU.STATES'})}
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            style={{marginLeft: 15}}
                            as={NavLink}
                            to='/municipalities'
                        >
                            <FaMapMarkedAlt />
                            <span>
                                {intl.formatMessage({
                                    id: 'MENU.MUNICIPALITIES',
                                })}
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            style={{marginLeft: 15}}
                            as={NavLink}
                            to='/water-types'
                        >
                            <FaWater />
                            <span>
                                {intl.formatMessage({id: 'MENU.WATER_TYPES'})}
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            style={{marginLeft: 15}}
                            as={NavLink}
                            to='/soil-types'
                        >
                            <GiDustCloud />
                            <span>
                                {intl.formatMessage({id: 'MENU.SOIL_TYPES'})}
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            style={{marginLeft: 15}}
                            as={NavLink}
                            to='/banks'
                        >
                            <AiFillBank />
                            <span>
                                {intl.formatMessage({id: 'MENU.BANKS'})}
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            style={{marginLeft: 15}}
                            as={NavLink}
                            to='/plagues'
                        >
                            <GiPlagueDoctorProfile />
                            <span>
                                {intl.formatMessage({id: 'MENU.PLAGUES'})}
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            style={{marginLeft: 15}}
                            as={NavLink}
                            to='/certificate-types'
                        >
                            <AiFillSafetyCertificate />
                            <span>
                                {intl.formatMessage({
                                    id: 'MENU.CERTIFICATE_TYPES',
                                })}
                            </span>
                        </Nav.Link>
                        <Nav.Link
                            style={{marginLeft: 15}}
                            as={NavLink}
                            to='/vivariums'
                        >
                            <GiFlowers />
                            <span>
                                {intl.formatMessage({
                                    id: 'MENU.VIVARIUMS',
                                })}
                            </span>
                        </Nav.Link>
                    </>
                </Accordion.Collapse>
            </Accordion>
        </Nav>
    );
}

export default Menu;
