import {API} from '../API';
import PaginatedList from '../PaginatedList';
import CertificateType from 'common/models/CertificateType';
import {store} from 'common/redux/StoreReducers';

const CertificateTypesApiService = {
  get: async (pageNumber = 1, filters) => {
    let url = `/certificateTypes/?page=${pageNumber}`;

    if (filters.keyword) {
      url += `&search=${filters.keyword}`;
    }

    const {data: response} = await API.get(url);

    const list = new PaginatedList();
    list.items = response.data.map((wt) => new CertificateType(wt));
    list.page = response.meta.page;
    list.pageCount = response.meta.lastPage;
    list.pageSize = response.meta.perPage;
    list.total = response.meta.total;
    return list;
  },
  detail: async (id) => {
    const {data: response} = await API.get(`/certificateTypes/${id}`);
    const certificateType = new CertificateType(response.data);

    return certificateType;
  },
  save: async (params) => {
    await API.post('/certificateTypes', params, {
      headers: {'Accept-Language': store.getState().langReducer.lang},
    });

    return;
  },
  update: async (id, input) => {
    const {data: response} = await API.put(`/certificateTypes/${id}`, input);

    return {certificateTypes: new CertificateType(response.data)};
  },
  delete: async (id) => {
    await API.delete(`/certificateTypes/${id}`);

    return {};
  },
};

export default CertificateTypesApiService;
