import React from "react";
import { injectIntl } from 'react-intl';
import { Alert } from "react-bootstrap";
import API from "../../common/utils/API";
import Form from '../../components/form/licensees/LicenseeForm';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import LoadingButton from '../../components/form/LoadingButton';
import { Redirect } from "react-router-dom";
import Licensee from "../../common/models/Licensee";


export class EditLicensee extends React.Component {
    constructor (props) {
        super(props);
        this.state =  {
            loading: false,
            shouldRedirectToDetail: false,
            shouldRedirectToIndex: false,
            licensee: new Licensee(),
            states: [],
            municipalities:[],
            fiscalTypes: [
                {id: 'fisica', name:"Física"},
                {id: 'moral', name:"Moral"},
            ],
            errorMessage: '',
            isLoadErrorModalOpen: false,
            redirectSuccessMessage: '',
        }
    }
    async componentDidMount() {
        await Promise.all([
            this.fetchStates(),
            this.fetchLicensee(), 
        ]);
    }

    fetchLicensee = async() => {
        this.setState({
            loading: true,
        })
        try {
            const licensee = await API.licensees.detail(this.props.match.params.id);
            this.setState({
                licensee,
                loading: false,
                isLoadErrorModalOpen: false,
            })
            await this.fetchMunicipalities(licensee.stateId)
        } catch (error) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    }

    fetchStates = async () => {
        const states = await API.states.all();
        this.setState({
            states: states,
        });
    }

    fetchMunicipalities = async(state) => {
        if (state) {
            const municipalities = await API.municipalities.byState(state);
            this.setState({
                municipalities: municipalities,
            }) 
        }
    }

    onSubmit = async (input) => {
        const send = {
            firstName: input.firstName,
            lastName: input.lastName,
            email: input.email,
            phone: input.phone,
            rfc: input.rfc,
            address: input.address,
            noInt: input.noInt,
            location: input.location,
            zipCode: input.zipCode,
            fiscalType: input.fiscalType,
            fiscalName: input.fiscalName ?? '',
            municipality: input.municipalityId,
            password: input.password ?? '',
        };
        this.setState({loading: true});
        try {
            await API.licensees.update(
                this.props.match.params.id,
                send,
            );

            this.setState({
                shouldRedirectToDetail: true,
                loading: false,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'LICENSEES.LICENSEE_SUCCESSFULLY_UPDATED_MESSAGE',
                }),
            });
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.UPDATING_DATA',
                    }),
                });
            }
        }
    };

    handleDeleteDetail = async() => {
        this.setState({loading: true});

        try {
            await API.licensees.delete(this.props.match.params.id);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'LICENSEES.LICENSEE_SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                loading: false,
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.DELETING_DATA',
                }),
            });
        }
    }

    render() {
        const initialValues =  this.state.licensee;
        return this.state.shouldRedirectToDetail ? (
            <Redirect
                data-test='redirectToDetail'
                to={{
                    pathname: `/licensees/${this.props.match.params.id}`,
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ): this.state.shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/licensees',
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) :
        (<>
            <Alert
                variant='danger'
                className='mb-4'
                show={!!this.state.errorMessage}
            >
                <p className='mb-0'>{this.state.errorMessage}</p>
            </Alert>

            <Form data-test='updateLicenseeComponent'
                initialValues={initialValues}
                backRedirectUrl={`/licensees/${this.props.match.params.id}`}
                loading={this.state.loading}
                isEdit={true}
                handleSubmit={this.onSubmit}
                handleDeleteItem={this.handleDeleteDetail}
                states={this.state.states}
                municipalities={this.state.municipalities}
                stateChange={this.fetchMunicipalities}
                fiscalTypes={this.state.fiscalTypes}
                backText={this.props.intl.formatMessage({
                    id: 'LIST.BACK_TO_DETAIL',
                })}
            >
            </Form>

            <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'USERS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href={`/licensees/${this.props.match.params.id}`}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchUser}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
            </Modal>
        </>);
    }
}

export default injectIntl(EditLicensee);
