import React from 'react';
import { injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Row, Col} from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import LoadingButton from '../../components/form/LoadingButton';
import API from '../../common/utils/API';
import Licensee from '../../common/models/Licensee';
import Can from '../../common/security/Can';

export class LicenseeDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licensee: new Licensee(),
            loading: false,
            isToggleUserStatusModalOpen: false,
            isLoadErrorModalOpen: false,
            isDeleteModalOpen: false,
            error: '',
        }
    }

    async componentDidMount() {
        await this.loadLicensee();
    }

    loadLicensee = async () => {
        this.setState({
            loading: true,
        });
        try {
            const licensee = await API.licensees.detail(this.props.match.params.id);
            this.setState({
                licensee,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (error) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    }

    openModalDelete = () =>{
        this.setState({
            isDeleteModalOpen: true,
        })
    }

    render() {
        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}>

                </Alert>

                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/licensees'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>

                <Card className='mb-4' data-test='licenseeDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'USERS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col md={4}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_NAMEEDIT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.licensee.firstName} {this.state.licensee.lastName}</p>
                                    </Col>
                                    <Col md={4}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_ADDRESS_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.licensee.address} {this.state.licensee.noInt}, {this.state.licensee.location}
                                        <br></br>
                                        {this.props.intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_ZIPCODE_FIELD_LABEL',
                                            })}: {this.state.licensee.zipCode}
                                        </p>
                                    </Col>
                                    <Col md={4}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_STATEEDIT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.licensee.municipality.name}, {this.state.licensee.municipality.state}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>

                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col md={4}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_EMAIL_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            <a href={'mailto:'+this.state.licensee.email.toLowerCase()}>
                                                {this.state.licensee.email}
                                            </a>
                                        </p>
                                    </Col>
                                    <Col md={4}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_PHONE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.licensee.phone}</p>
                                    </Col>
                                    <Col md={4}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_RFC_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.licensee.rfc.toUpperCase()}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col md={4}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_FISCALTYPE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                        {this.props.intl.formatMessage({
                                                id: 'LICENSEES.PERSON_TYPE_'+this.state.licensee.fiscalType,
                                            })}
                                        </p>
                                    </Col>
                                    <Col md={8}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_FISCALNAME_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.licensee.phone}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-between'>
                    <div>
                        <Can run='LICENSEES_EDIT'>
                            <Button
                                as={NavLink}
                                to={`/licensees/${this.props.match.params.id}/edit`}
                                className='btn-warning btn-bold ml-2'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'LICENSEES.EDIT_LICENSEE_BUTTON_TEXT',
                                })}
                            </Button>
                        </Can>
                    </div>
                </div>

                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'LICENSEES.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'LICENSEES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/licensees'
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.loadDetail}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(LicenseeDetail);