import React, {useState, useCallback, Fragment, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {useIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Form from 'components/form/water-type/WaterTypeForm';
import LoadingButton from 'components/form/LoadingButton';

import API from 'common/utils/API';

export const EditWaterType = ({
  match: {
    params: {id},
  },
}) => {
  const [waterType, setWaterType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shouldRedirectToDetail, setShouldRedirectToDetail] = useState(false);
  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);
  const [isLoadErrorModalOpen, setIsLoadErrorModalOpen] = useState(false);
  const [redirectSuccessMessage, setRedirectSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const intl = useIntl();

  const fetchDetail = useCallback(async () => {
    setLoading(true);

    try {
      const detail = await API.waterTypes.detail(id);

      setWaterType(detail);
      setIsLoadErrorModalOpen(false);
    } catch (error) {
      setIsLoadErrorModalOpen(true);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const toggleLoadErrorModal = useCallback(() => {
    setIsLoadErrorModalOpen(!isLoadErrorModalOpen);
  }, [isLoadErrorModalOpen]);

  useEffect(() => {
    fetchDetail();
  }, [fetchDetail]);

  const handleDeleteDetail = useCallback(async () => {
    setLoading(true);

    try {
      await API.waterTypes.delete(id);

      setLoading(false);
      setShouldRedirectToIndex(true);
      setRedirectSuccessMessage(
        intl.formatMessage({
          id: 'WATERTYPES.SUCCESSFULLY_DELETED_MESSAGE',
        }),
      );
    } catch (error) {
      setLoading(false);
      setErrorMessage(
        intl.formatMessage({
          id: 'ERROR_PAGES.DELETING_DATA',
        }),
      );
    }
  }, [id, intl]);

  const onSubmit = useCallback(
    async (input) => {
      setLoading(true);

      try {
        await API.waterTypes.update(id, input);

        setLoading(false);
        setShouldRedirectToDetail(true);
        setRedirectSuccessMessage(
          intl.formatMessage({
            id: 'WATERTYPES.SUCCESSFULLY_UPDATED_MESSAGE',
          }),
        );
      } catch (error) {
        if (error?.response?.status === 422) {
          const errorMessage = Object.values(error.response.data.messages).join(
            '\n',
          );

          setLoading(false);
          setErrorMessage(errorMessage);
        } else {
          setLoading(false);
          setErrorMessage(
            intl.formatMessage({
              id: 'ERROR_PAGES.STORING_DATA',
            }),
          );
        }
      }
    },
    [id, intl],
  );

  if (shouldRedirectToDetail) {
    return (
      <Redirect
        data-test='redirectToDetail'
        to={{
          pathname: `/water-types/${id}`,
          state: {successMessage: redirectSuccessMessage},
        }}
      />
    );
  }

  if (shouldRedirectToIndex) {
    return (
      <Redirect
        data-test='redirectToIndex'
        to={{
          pathname: '/water-types',
          state: {
            successMessage: redirectSuccessMessage,
          },
        }}
      />
    );
  }

  const initialValues = {name: waterType?.name};

  return (
    <Fragment>
      <Alert variant='danger' className='mb-4' show={!!errorMessage}>
        <p className='mb-0'>{errorMessage}</p>
      </Alert>

      {waterType && (
        <Form
          initialValues={initialValues}
          backRedirectUrl={`/water-types/${id}`}
          backText={intl.formatMessage({
            id: 'LIST.BACK_TO_DETAIL',
          })}
          loading={loading}
          handleSubmit={onSubmit}
          handleDeleteWaterType={handleDeleteDetail}
        />
      )}
      <Modal show={isLoadErrorModalOpen} onHide={toggleLoadErrorModal}>
        <Modal.Header>
          <Modal.Title>
            {intl.formatMessage({
              id: 'GENERAL.ERROR_MODAL_TITLE',
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {intl.formatMessage({
            id: 'GENERAL.ERROR_MODAL_TEXT',
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            className='btn-bold btn-light-dark'
            href={`/water-types/${id}`}
          >
            {intl.formatMessage({
              id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
            })}
          </Button>
          <LoadingButton
            variant='primary'
            className='btn-bold'
            loading={loading}
            onClick={fetchDetail}
          >
            {intl.formatMessage({
              id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
            })}
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default EditWaterType;
