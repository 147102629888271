import React, {Component} from 'react';
import styled from 'styled-components';

import Input from 'components/form/Input';

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-top: 10px;
`;

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef(null);
    this.autoComplete = React.createRef(null);
  }

  componentDidMount() {
    const {mapApi, map} = this.props;

    const options = {
      types: ['address'],
      componentRestrictions: {country: ['mx']},
    };

    this.autoComplete.current = new mapApi.places.Autocomplete(
      this.searchInput.current,
      options,
    );

    this.autoComplete.current.addListener('place_changed', this.onPlaceChanged);
    this.autoComplete.current.bindTo('bounds', map);
  }

  componentWillUnmount() {
    const {mapApi} = this.props;

    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  onPlaceChanged = () => {
    const {addplace, map} = this.props;

    const place = this.autoComplete.current.getPlace();

    if (!place.geometry) return;

    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    addplace(place);
    this.searchInput.current.blur();
    this.clearSearchBox();
  };

  clearSearchBox = () => {
    this.searchInput.current.value = '';
  };

  render() {
    return (
      <Wrapper>
        <Input
          className='search-input'
          ref={this.searchInput}
          type='text'
          placeholder='Buscar un lugar'
        />
      </Wrapper>
    );
  }
}

export default AutoComplete;
