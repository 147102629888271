const initialState = {
    lang: 'es',
};

export function langReducer(state = initialState, action) {
    switch (action.type) {
        case 'SWITCH_LANGUAGE':
            return Object.assign({}, state, {
                lang: action.payload.lang,
            });
        default:
            return state;
    }
}
