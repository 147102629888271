import {API} from '../API';
import PaginatedList from '../PaginatedList';
import User from '../../models/User';
import {store} from '../../redux/StoreReducers';

const users = {
    get: async (pageNumber = 1, keyword = '', role = '') => {
        const {data: response} = await API.get(
            `/users?page=${pageNumber}&search=${keyword}&role=${role}`,
        );

        const list = new PaginatedList();

        list.items = response.data.map((user) => new User(user));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/users/${id}`);

        return {
            user: new User(response.data),
        };
    },

    save: async (params, state) => {
        await API.post(`/users`, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept-Language': store.getState().langReducer.lang,
                Accept: 'application/json',
                type: 'formData',
            },
        });

        return {};
    },
    update: async (id, params) => {
        const {data: response} = await API.put(`/users/${id}`, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                type: 'formData',
            },
        });

        return {user: new User(response.data)};
    },
    delete: async (id) => {
        await API.delete(`/users/${id}`);

        return {};
    },
    activate: async (id) => {
        await API.put(`/users/${id}/activate`);

        return {};
    },
    deactivate: async (id) => {
        await API.put(`/users/${id}/deactivate`);

        return {};
    },
};

export default users;
