import React from "react";
import Conventional from "./Conventional";
import Substratum from "./Substratum";
import Hydroponics from "./Hydroponics";

export default function IrrigationSystemType(props) {
    const irrigationSystemType = {
        'CONVENTIONAL': 'conventional',
        'HYDROPONICS': 'hydroponics',
        'SUBSTRATUM': 'substratum'
    };
    switch (props.type) {
        case irrigationSystemType.CONVENTIONAL:
            return (<Conventional data={props.data}/>);
        case irrigationSystemType.SUBSTRATUM:
            return (<Substratum data={props.data}/>);
        case irrigationSystemType.HYDROPONICS:
            return (<Hydroponics data={props.data}/>);
        default:
            return <></>
    }
}