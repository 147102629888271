export const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

export const formatISODate = (date) => {
    try {
        const val = date.toISOString();
        return val.split('T')[0];
    } catch(e) {
        return '2022-01-01';
    }
}