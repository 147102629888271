import React from 'react';
import ColumnContainer from './ColumnContainer';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useIntl} from 'react-intl';

function PasswordResetLinkSentContainer() {
    const intl = useIntl();
    return (
        <ColumnContainer>
            <Form>
                <Form.Group>
                    <h3>
                        {intl.formatMessage({
                            id: 'AUTH.PASSWORD_RESET_SUCCESS_TITLE',
                        })}
                    </h3>
                    <hr />
                    <p>
                        {intl.formatMessage({
                            id: 'AUTH.PASSWORD_RESET_SUCCESS_BODY',
                        })}
                    </p>
                </Form.Group>

                <Button variant='secondary' size='lg' block href='/login'>
                    {intl.formatMessage({id: 'AUTH.BACK_TO_LOGIN_BUTTON'})}
                </Button>
            </Form>
        </ColumnContainer>
    );
}

export default PasswordResetLinkSentContainer;
