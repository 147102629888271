import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Formik} from 'formik';
import BaseForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Input from '../Input';
import LoadingButton from '../LoadingButton';
import Validation from './ProducerValidation';
import Forms from 'react-bootstrap/Form';
import SignatureCanvas from 'react-signature-canvas';

import ConfirmModal from '../ConfirmModal';
import Can from '../../../common/security/Can';

export default function Form(props) {
    const intl = useIntl();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [sigPad, setSigPad] = useState(false);
    const validationSchema = Validation();

    const onSubmit = (input) => {
        if (input.fullName){
            delete(input.fullName);
        }

        if (input.plant){
            delete(input.plant);
        }

        if (input.id){
            delete(input.id);
        }

        if (input.varietyId){
            delete(input.varietyId);
        }
        
        if (input.bankId){
            delete(input.bankId);
        }

        props.handleSubmit(input);
    };

    const handleToggleIsDeleteModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    return (
        <Formik
            data-test='producerFormComponent'
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors, setFieldValue}) => (
                <>
                    <div className='mb-4 d-flex justify-content-end'>
                        <Button
                            data-test='backButton'
                            as={NavLink}
                            to={props.backRedirectUrl}
                            className='btn btn-dark btn-bold btn-light-dark'
                        >
                            {props.backText}
                        </Button>
                    </div>

                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-2'>
                            <Card.Header>
                                {intl.formatMessage({id:'PRODUCERS.FIRST_SECTION_TITLE'})}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md={3}>
                                        <Input md='12'
                                            type='select'
                                            label={intl.formatMessage({id: 'PRODUCERS.PRODUCER_LICENSEE_LABEL'})}
                                            placeholder={intl.formatMessage({id: 'PRODUCERS.SELECT_ONE'})}
                                            name='licensee'
                                            value={values.licensee}
                                            onChange={handleChange}
                                            error={errors.licensee}
                                            isInvalid={errors.licensee}
                                            options={props.licensees.map((lic) => ({ value: lic.id, label: lic.firstName + ' '+ lic.lastName }))}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input md='12'
                                            type='select'
                                            label={intl.formatMessage({id: 'PRODUCERS.PRODUCER_FISCALTYPE_LABEL'})}
                                            placeholder={intl.formatMessage({id: 'PRODUCERS.SELECT_ONE'})}
                                            name='fiscalType'
                                            value={values.fiscalType}
                                            onChange={handleChange}
                                            error={errors.fiscalType}
                                            isInvalid={errors.fiscalType}
                                            options={props.fiscalTypes.map((state) => ({ value: state.id, label: state.name }))}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input md='12'
                                            type='select'
                                            label={intl.formatMessage({id: 'PRODUCERS.PRODUCER_TYPE_LABEL'})}
                                            placeholder={intl.formatMessage({id: 'PRODUCERS.SELECT_ONE'})}
                                            name='type'
                                            value={values.type}
                                            onChange={handleChange}
                                            error={errors.type}
                                            isInvalid={errors.type}
                                            options={props.producerTypes.map((state) => ({ value: state.id, label: state.name }))}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Input md='12'
                                            type='select'
                                            label={intl.formatMessage({id: 'PRODUCERS.PRODUCER_STATUS_TABLE_HEADER'})}
                                            placeholder={intl.formatMessage({id: 'PRODUCERS.SELECT_ONE'})}
                                            name='status'
                                            value={values.status}
                                            onChange={handleChange}
                                            error={errors.status}
                                            isInvalid={errors.status}
                                            options={props.producerStatus.map((stat) => ({ value: stat.id, label: stat.name }))}
                                        />
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                            <Card.Header>
                                {intl.formatMessage({id:'PRODUCERS.SECOND_SECTION_TITLE'})}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md={4}>
                                        <Input
                                            type='text'
                                            label={intl.formatMessage({
                                                id:'PRODUCERS.PRODUCER_FIRSTNAME_LABEL'})}
                                            placeholder={intl.formatMessage(
                                                {id:'PRODUCERS.PRODUCER_FIRSTNAME_PLACEHOLDER'}
                                            )}
                                            name='firstName'
                                            value={values.firstName}
                                            onChange={handleChange}
                                            error={errors.firstName}
                                            isInvalid={errors.firstName}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input
                                            type='text'
                                            label={intl.formatMessage({
                                                id:'PRODUCERS.PRODUCER_LASTNAME_LABEL'})}
                                            placeholder={intl.formatMessage(
                                                {id:'PRODUCERS.PRODUCER_LASTNAME_PLACEHOLDER'}
                                            )}
                                            name='lastName'
                                            value={values.lastName}
                                            onChange={handleChange}
                                            error={errors.lastName}
                                            isInvalid={errors.lastName}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input
                                            type='text'
                                            label={intl.formatMessage({
                                                id:'PRODUCERS.PRODUCER_MIDDLELASTNAME_LABEL'})}
                                            placeholder={intl.formatMessage(
                                                {id:'PRODUCERS.PRODUCER_MIDDLELASTNAME_PLACEHOLDER'}
                                            )}
                                            name='middleLastName'
                                            value={values.middleLastName}
                                            onChange={handleChange}
                                            error={errors.middleLastName}
                                            isInvalid={errors.middleLastName}
                                        />
                                    </Col>
                                </BaseForm.Row>
                                <BaseForm.Row>
                                    <Col md={4}>
                                        <Input
                                            type='text'
                                            label={intl.formatMessage({id:'PRODUCERS.PRODUCER_BUSINESSNAME_LABEL'})}
                                            placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_BUSINESSNAME_PLACEHOLDER'})}
                                            name='businessName'
                                            value={values.businessName}
                                            onChange={handleChange}
                                            error={errors.businessName}
                                            isInvalid={errors.businessName}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input
                                            type='text'
                                            label={intl.formatMessage({id:'PRODUCERS.PRODUCER_EMAIL_LABEL'})}
                                            placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_EMAIL_PLACEHOLDER'})}
                                            name='email'
                                            value={values.email}
                                            onChange={handleChange}
                                            error={errors.email}
                                            isInvalid={errors.email}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input
                                            type='number'
                                            label={intl.formatMessage({id:'PRODUCERS.PRODUCER_PHONE_LABEL'})}
                                            placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_PHONE_PLACEHOLDER'})}
                                            name='phone'
                                            maxLength='10'
                                            value={values.phone}
                                            onChange={(e)=> {setFieldValue('phone',e.target.value.toString())}}
                                            error={errors.phone}
                                            isInvalid={errors.phone}
                                        />
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>

                        <Card className='mb-2'>
                            <Card.Header>
                                {intl.formatMessage({id:'PRODUCERS.THIRD_SECTION_TITLE'})}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md={4}>
                                        <p>{intl.formatMessage({id: 'DELIVERIES.VARIETY_LABEL'})}</p>
                                        <Forms.Control as='select'
                                            name="variety"
                                            value={values.variety}
                                            onChange={handleChange}
                                            error={errors.variety}
                                            isInvalid={errors.variety}
                                        >
                                            <option value=''>
                                                {intl.formatMessage({id:'DELIVERIES.VARIETY_SELECT_PLACEHOLDER'})}
                                            </option>
                                            {props.varieties.map((variety) => (
                                                <option value={variety.id} key={variety.id}>
                                                    {variety.plant} - {variety.name}
                                                </option>
                                            ))}
                                        </Forms.Control>
                                    </Col>
                                    <Col md={4}>
                                        <Input type='number'
                                            label={intl.formatMessage({id:'PRODUCERS.PRODUCER_HECTARES_LABEL'})}
                                            placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_HECTARES_PLACEHOLDER'})}
                                            name='hectares'
                                            value={values.hectares}
                                            onChange={handleChange}
                                            error={errors.hectares}
                                            isInvalid={errors.hectares}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input type='number'
                                            label={intl.formatMessage({id:'PRODUCERS.PRODUCER_PLANTSPERHECTARES_LABEL'})}
                                            placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_PLANTSPERHECTARES_PLACEHOLDER'})}
                                            name='plantsPerHectare'
                                            value={values.plantsPerHectare}
                                            onChange={handleChange}
                                            error={errors.plantsPerHectare}
                                            isInvalid={errors.plantsPerHectare}
                                        />
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>

                        <Card className='mb-2'>
                            <Card.Header>
                                {intl.formatMessage({id:'PRODUCERS.FOURTH_SECTION_TITLE'})}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md={2}>
                                        <Input
                                            type='switch'
                                            id='hasExperience'
                                            placeholder={intl.formatMessage({id: 'PRODUCERS.PRODUCER_HASEXPERIENCE_LABEL'})}
                                            checked={values.hasExperience}
                                            onChange={()=>{ setFieldValue('hasExperience', !values.hasExperience) }}
                                        />
                                    </Col>
                                    {values.hasExperience ? 
                                    <>
                                        <Col md={5}>
                                            <Input type='text'
                                                label={intl.formatMessage({id:'PRODUCERS.PRODUCER_EXPERIENCE_LABEL'})}
                                                placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_EXPERIENCE_PLACEHOLDER'})}
                                                name='experience'
                                                value={values.experience}
                                                onChange={handleChange}
                                                error={errors.experience}
                                                isInvalid={errors.experience}
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <Input type='text'
                                                label={intl.formatMessage({id:'PRODUCERS.PRODUCER_EXPERIENCETIME_LABEL'})}
                                                placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_EXPERIENCETIME_PLACEHOLDER'})}
                                                name='experienceTime'
                                                value={values.experienceTime}
                                                onChange={handleChange}
                                                error={errors.experienceTime}
                                                isInvalid={errors.experienceTime}
                                            />
                                        </Col>
                                    </>
                                    :<></>}
                                </BaseForm.Row>
                                <BaseForm.Row>
                                    <Col md={2}>
                                        <Input
                                            type='switch'
                                            id='hasProductionManager'
                                            placeholder={intl.formatMessage({id: 'PRODUCERS.PRODUCER_HASPRODUCTIONMANAGER_LABEL'})}
                                            checked={values.hasProductionManager}
                                            onChange={()=>{ setFieldValue('hasProductionManager', !values.hasProductionManager) }}
                                        />
                                    </Col>
                                    { values.hasProductionManager ?
                                    <>
                                        <Col md={4}>
                                            <Input type='text'
                                                label={intl.formatMessage({id:'PRODUCERS.PRODUCER_BUSSINESPARTNERS_LABEL'})}
                                                placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_BUSSINESPARTNERS_PLACEHOLDER'})}
                                                name='bussinesPartners'
                                                value={values.bussinesPartners}
                                                onChange={handleChange}
                                                error={errors.bussinesPartners}
                                                isInvalid={errors.bussinesPartners}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input type='textarea'
                                                label={intl.formatMessage({id:'PRODUCERS.PRODUCER_REASONCHANGECULTIVE_LABEL'})}
                                                placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_REASONCHANGECULTIVE_PLACEHOLDER'})}
                                                name='reasonChangeCultive'
                                                value={values.reasonChangeCultive}
                                                onChange={handleChange}
                                                error={errors.reasonChangeCultive}
                                                isInvalid={errors.reasonChangeCultive}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input type='text'
                                                label={intl.formatMessage({id:'PRODUCERS.PRODUCER_PRODUCERCODEOWN_LABEL'})}
                                                placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_PRODUCERCODEOWN_PLACEHOLDER'})}
                                                name='producerCodeOwn'
                                                value={values.producerCodeOwn}
                                                onChange={handleChange}
                                                error={errors.producerCodeOwn}
                                                isInvalid={errors.producerCodeOwn}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Input type='text'
                                                label={intl.formatMessage({id:'PRODUCERS.PRODUCER_SEASONSWORKING_LABEL'})}
                                                placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_SEASONSWORKING_PLACEHOLDER'})}
                                                name='seasonsWorking'
                                                value={values.seasonsWorking}
                                                onChange={handleChange}
                                                error={errors.seasonsWorking}
                                                isInvalid={errors.seasonsWorking}
                                            />
                                        </Col>
                                    </>
                                    :
                                    <>
                                    <Col md={10}>
                                        <Input type='text'
                                            label={intl.formatMessage({id:'PRODUCERS.PRODUCER_LASTLINEBUSSINES_LABEL'})}
                                            placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_LASTLINEBUSSINES_PLACEHOLDER'})}
                                            name='lastLineBusiness'
                                            value={values.lastLineBusiness}
                                            onChange={handleChange}
                                            error={errors.lastLineBusiness}
                                            isInvalid={errors.lastLineBusiness}
                                        />
                                    </Col>
                                    </>}
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>

                        <Card className='mb-2'>
                            <Card.Header>
                                {intl.formatMessage({id:'PRODUCERS.FIFTH_SECTION_TITLE'})}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row className='mb-4'>
                                    <Col md={4}>
                                        <Input type='number'
                                            min={1}
                                            label={intl.formatMessage({id:'PRODUCERS.PRODUCER_EMPLOYEES_LABEL'})}
                                            placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_EMPLOYEES_PLACEHOLDER'})}
                                            name='employees'
                                            value={values.employees}
                                            onChange={handleChange}
                                            error={errors.employees}
                                            isInvalid={errors.employees}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input type='text'
                                            label={intl.formatMessage({id:'PRODUCERS.PRODUCER_RECRUITSOURCE_LABEL'})}
                                            placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_RECRUITSOURCE_PLACEHOLDER'})}
                                            name='recruitSource'
                                            value={values.recruitSource}
                                            onChange={handleChange}
                                            error={errors.recruitSource}
                                            isInvalid={errors.recruitSource}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input type='number'
                                            step='.01'
                                            min='0'
                                            label={intl.formatMessage({id:'PRODUCERS.PRODUCER_AVERAGESALARY_LABEL'})}
                                            placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_AVERAGESALARY_PLACEHOLDER'})}
                                            name='averageSalary'
                                            value={values.averageSalary}
                                            onChange={handleChange}
                                            error={errors.averageSalary}
                                            isInvalid={errors.averageSalary}
                                        />
                                    </Col>
                                </BaseForm.Row>
                                <BaseForm.Row className='mb-4'>
                                    <Col md={4} className='pt-4'>
                                        <Input type='switch'
                                            id='hasOffices'
                                            placeholder={intl.formatMessage({id: 'PRODUCERS.PRODUCER_HASOFFICES_LABEL'})}
                                            checked={values.hasOffices}
                                            onChange={()=>{ setFieldValue('hasOffices', !values.hasOffices) }}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input type='text'
                                            label={intl.formatMessage({id:'PRODUCERS.PRODUCER_RESOURCESORIGIN_LABEL'})}
                                            placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_RESOURCESORIGIN_PLACEHOLDER'})}
                                            name='resourcesOrigin'
                                            value={values.resourcesOrigin}
                                            onChange={handleChange}
                                            error={errors.resourcesOrigin}
                                            isInvalid={errors.resourcesOrigin}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input type='number'
                                            step='.01'
                                            min='1'
                                            label={intl.formatMessage({id:'PRODUCERS.PRODUCER_BUDGETAVAILABLE_LABEL'})}
                                            placeholder={intl.formatMessage({id:'PRODUCERS.PRODUCER_BUDGETAVAILABLE_PLACEHOLDER'})}
                                            name='budgetAvailable'
                                            value={values.budgetAvailable}
                                            onChange={handleChange}
                                            error={errors.budgetAvailable}
                                            isInvalid={errors.budgetAvailable}
                                        />
                                    </Col>
                                </BaseForm.Row>
                                <BaseForm.Row>
                                    <Col md={4}>
                                        <p>{intl.formatMessage({id: 'PRODUCERS.PRODUCER_BANK_LABEL'})}</p>
                                            <Forms.Control as='select'
                                                name="bank"
                                                value={values.bank}
                                                onChange={handleChange}
                                                error={errors.bank}
                                                isInvalid={errors.bank}
                                            >
                                                <option value=''>
                                                    {intl.formatMessage({id:'PRODUCERS.SELECT_ONE'})}
                                                </option>
                                                {props.banks.map((bank) => (
                                                    <option value={bank.id} key={bank.id}>
                                                        {bank.name}
                                                    </option>
                                                ))}
                                            </Forms.Control>
                                    </Col>
                                    <Col md={4} className='pt-4'>
                                        <Input type='switch'
                                            id='hasColdRoom'
                                            placeholder={intl.formatMessage({id: 'PRODUCERS.PRODUCER_HASCOLDROOM_LABEL'})}
                                            checked={values.hasColdRoom}
                                            onChange={()=>{ setFieldValue('hasColdRoom', !values.hasColdRoom) }}
                                        />
                                    </Col>
                                    <Col md={4} className='pt-4'>
                                        <Input type='switch'
                                            id='hasRefrigeratorTruck'
                                            placeholder={intl.formatMessage({id: 'PRODUCERS.PRODUCER_HASREFRIGERATORTRUCK_LABEL'})}
                                            checked={values.hasRefrigeratorTruck}
                                            onChange={()=>{ setFieldValue('hasRefrigeratorTruck', !values.hasRefrigeratorTruck) }}
                                        />
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>

                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({id:'PRODUCERS.SIGNATURE_TITLE'})}
                                <small show={errors.signature} className="text-danger form-text">
                                    {errors.signature}
                                </small>
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md={6}>
                                        <SignatureCanvas
                                            onEnd={() =>{
                                                let sig = sigPad.getTrimmedCanvas().toDataURL('image/png');
                                                setFieldValue('signature', sig);
                                            }}
                                            ref={(ref) => { 
                                                setSigPad(ref);
                                            }}
                                            penColor='black'
                                            canvasProps={{width: 500, height: 200, className: 'sigCanvas', style:{border:'1px solid #000'}}}
                                        >
                                        </SignatureCanvas>
                                    </Col>
                                    <Col md={4}>
                                        <button className='btn btn-light' 
                                            onClick={() =>{
                                                setFieldValue('signature', '');
                                                sigPad.clear()
                                            }}
                                        type='button'>Borrar</button>
                                    </Col>
                                    <Col md={12}>
                                        {intl.formatMessage({id:'PRODUCERS.SIGNATURE_RESULT'})}
                                        <img src={values.signature} style={{border: '1px solid #999'}} 
                                            alt={intl.formatMessage({id:'PRODUCERS.SIGNATURE_RESULT'})}
                                        />
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>
                    </BaseForm>

                    <div className='d-flex justify-content-between'>
                        {props.isEdit ? (
                            <div>
                                <div>
                                    <Can run='PRODUCERS_DELETE' cantRun={<div />}>
                                        <ConfirmModal
                                            titleModal={intl.formatMessage({
                                                id:
                                                    'PRODUCERS.DELETE_PRODUCER_MODAL_TITLE',
                                            })}

                                            handleConfirm={() => {
                                                props.handleDeleteItem();
                                                handleToggleIsDeleteModal();
                                            }}
                                            handleShow={
                                                handleToggleIsDeleteModal
                                            }
                                            handleClose={
                                                handleToggleIsDeleteModal
                                            }
                                            show={isDeleteModalOpen}
                                            variant='danger'
                                            buttonClassName='btn-bold'
                                            buttonLabel={intl.formatMessage({
                                                id: 'PRODUCERS.DELETE_PRODUCER_BUTTON_LABEL'
                                            })}
                                            buttonAcceptLabel={intl.formatMessage(
                                                { id: 'PRODUCERS.CONFIRM_DELETE_BUTTON_LABEL'},
                                            )}
                                            buttonCloseLabel={intl.formatMessage(
                                                { id:'USERS.CANCEL_DELETE_BUTTON_LABEL'},
                                            )}
                                        >
                                            <p>
                                                {intl.formatMessage({
                                                    id:
                                                        'PRODUCERS.DELETE_PRODUCER_CONFIRMATION_MESSAGE',
                                                })}
                                            </p>
                                        </ConfirmModal>
                                    </Can>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'GENERAL.SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
