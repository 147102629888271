import React, {useState, useCallback, Fragment} from 'react';
import {useIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import API from 'common/utils/API';
import Form from 'components/form/water-type/WaterTypeForm';

const initialValues = {
  name: '',
};

export const CreateWaterType = () => {
  const [loading, setLoading] = useState(false);
  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const intl = useIntl();

  const onSubmit = useCallback(
    async (input) => {
      setLoading(true);

      try {
        await API.waterTypes.save(input);

        setLoading(false);
        setShouldRedirectToIndex(true);
      } catch (error) {
        if (error?.response?.status === 422) {
          const errorMessage = Object.values(error.response.data.messages).join(
            '\n',
          );

          setLoading(false);
          setErrorMessage(errorMessage);
        } else {
          setLoading(false);
          setErrorMessage(
            intl.formatMessage({
              id: 'ERROR_PAGES.STORING_DATA',
            }),
          );
        }
      }
    },
    [intl],
  );

  if (shouldRedirectToIndex) {
    return (
      <Redirect
        data-test='redirectToIndex'
        to={{
          pathname: '/water-types',
          state: {
            successMessage: intl.formatMessage({
              id: 'WATERTYPES.SUCCESSFULLY_CREATED_MESSAGE',
            }),
          },
        }}
      />
    );
  }

  return (
    <Fragment>
      <Alert variant='danger' className='mb-4' show={!!errorMessage}>
        <p className='mb-0'>{errorMessage}</p>
      </Alert>
      <Form
        data-test='createWaterTypeComponent'
        initialValues={initialValues}
        backRedirectUrl='/water-types'
        backText={intl.formatMessage({
          id: 'LIST.BACK_TO_LIST',
        })}
        loading={loading}
        handleSubmit={onSubmit}
      />
    </Fragment>
  );
};

export default CreateWaterType;
