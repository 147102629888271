import React from 'react';
import { injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Row, Col} from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import LoadingButton from '../../components/form/LoadingButton';

import API from '../../common/utils/API';
import Vivarium from '../../common/models/Vivarium';
import Can from '../../common/security/Can';


export class VivariunDetil extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: new Vivarium(),
            loading: false,
            isToggleUserStatusModalOpen: false,
            isLoadErrorModalOpen: false,
            isDeleteModalOpen: false,
            error: '',
        }
    }

    async componentDidMount() {
        await this.loadDetail();
    }

    loadDetail = async () => {
        this.setState({
            loading: true,
        });
        try {
            const item = await API.vivariums.detail(this.props.match.params.id);
            this.setState({
                item,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (error) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    }

    openModalDelete = () =>{
        this.setState({
            isDeleteModalOpen: true,
        })
    }

    render() {
        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}>
                </Alert>

                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/VIVARIUMs'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>

                <Card className='mb-4' data-test='userDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'USERS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'VIVARIUMS.VIVARIUM_NAME_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.item.name}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-between'>
                    <div>
                        <Can run='VIVARIUMS_EDIT'>
                            <Button
                                as={NavLink}
                                to={`/vivariums/${this.props.match.params.id}/edit`}
                                className='btn-warning btn-bold ml-2'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'VIVARIUMS.EDIT_VIVARIUM_BUTTON_TEXT',
                                })}
                            </Button>
                        </Can>
                    </div>
                </div>

                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'VIVARIUMS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/vivariums'
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.loadDetail}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(VivariunDetil);