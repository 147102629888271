import React from 'react';
import { injectIntl } from 'react-intl';
import Alert  from 'react-bootstrap/Alert';
import StarterList from '../../components/starter/StarterList';
import API from '../../common/utils/API'

export class VarietyList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            varieties: [],
            plants: [],

            page: 1,
            pageSize: 12,
            pageCount: 2,
            total: 0,

            searchTimeout: 0,
            search: '',
            plant: '',
            error: false,
        }
    }

    async componentDidMount() {
        await Promise.all([this.fetchData(), this.fetchPlants()]);
    }

    fetchData = async (pageNumber = 1) => {
        try {
            const result = await API.varieties.get(
                pageNumber,
                this.state.search,
                this.state.plant,
            );
            this.setState({
                varieties: result.items,
                page: result.page,
                pageSize: result.pageSize,
                pageCount: result.pageCount,
                total: result.total,
                error: false,
            });
        } catch (error) {
            this.setState({ error: true },
                this.clearErroMessage,
            );
        }
    }

    fetchPlants = async () => {
        try {
            const plants = await API.varieties.allPlants();
            this.setState({
                plants: plants,
                error: false,
            });
        } catch (error) {
            this.setState({ error: true,},
                this.clearErroMessage,
            );
        }
    }

    handleSearchKeywordChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchData(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchData(1);
            },
        );
    };

    handlePageChange = (pageNumber) => {
        return this.fetchData(pageNumber);
    };

    render() {
        const plantsOptions = this.state.plants.map((plant) => ({
            value: plant.id,
            label: plant.name,
        }));
        return (
            <div data-test='varietiesListComponent'>
                <Alert data-test='errorAlert'
                    variant='danger'
                    className="mb-4"
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>    
                </Alert>

                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    addNewPermission={'USERS_CREATE'}
                    addNewLink={`varieties/create`}
                    data={{
                        headerTitles: [
                            "#",
                            this.props.intl.formatMessage({id:'VARIETIES.VARIETY_PLANT_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'VARIETIES.VARIETY_NAME_TABLE_HEADER'}),
                        ],
                        rows: this.state.varieties.map((variety) =>({
                            content: [
                                variety.id,
                                variety.plant,
                                variety.name
                            ],
                            link: `/varieties/${variety.id}`,
                        }))
                    }}
                    searchFields={[
                        {
                            options: plantsOptions,
                            name: 'plant',
                            placeholder: this.props.intl.formatMessage({id: 'VARIETIES.FILTER_SELECT_A_PLANT_PLACEHOLDER'}),
                            value: this.state.plant,
                        },
                    ]}
                >
                </StarterList>

            </div>
        );
    }
}

export default injectIntl(VarietyList);