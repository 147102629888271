import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import WaterTypesList from './WaterTypesList';
import WaterTypeDetail from './WaterTypeDetail';
import CreateWaterType from './CreateWaterType';
import EditWaterType from './EditWaterType';

function WaterTypesRoutes() {
  const intl = useIntl();

  return (
    <Switch data-test='waterTypeComponent'>
      <PrivateRoute
        permission='WATERTYPES_INDEX'
        exact
        path='/water-types'
        component={withLayout(
          WaterTypesList,
          intl.formatMessage({id: 'ROUTES.WATERTYPES.INDEX'}),
        )}
      />
      <PrivateRoute
        permission='WATERTYPES_CREATE'
        exact
        path='/water-types/create'
        component={withLayout(
          CreateWaterType,
          intl.formatMessage({id: 'ROUTES.WATERTYPES.CREATE'}),
        )}
      />
      <PrivateRoute
        permission='WATERTYPES_SHOW'
        exact
        path='/water-types/:id'
        component={withLayout(
          WaterTypeDetail,
          intl.formatMessage({id: 'ROUTES.WATERTYPES.SHOW'}),
        )}
      />
      <PrivateRoute
        permission='WATERTYPES_EDIT'
        exact
        path='/water-types/:id/edit'
        component={withLayout(
          EditWaterType,
          intl.formatMessage({id: 'ROUTES.WATERTYPES.EDIT'}),
        )}
      />
    </Switch>
  );
}

export default WaterTypesRoutes;
