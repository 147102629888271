import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import BaseForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import Input from '../Input';
import LoadingButton from '../LoadingButton';
import ConfirmModal from '../ConfirmModal';
import Can from '../../../common/security/Can';

export default function Form(props) {
    const intl = useIntl();
    const [isDeleteRoleModalOpen, setIsDeleteRoleModalOpen] = useState(false);

    const validationSchema = Yup.object({
        name: Yup.string().required(
            intl.formatMessage({id: 'ROLES.VALIDATION.ROLE_NAME_MISSING'}),
        ),
        permissions: Yup.array().of(Yup.string()).min(0).required(),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const handleToggleIsDeleteRoleModal = () => {
        setIsDeleteRoleModalOpen(!isDeleteRoleModalOpen);
    };

    const unSelectAllPermissions = (setFieldValue) => {
        setFieldValue('permissions', []);
    };

    const selectAllPermissions = (setFieldValue) => {
        setFieldValue(
            'permissions',
            props.permissions.map((permission) => permission.code),
        );
    };

    return (
        <Formik
            data-test='roleFormComponent'
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors, setFieldValue}) => (
                <>
                    <div className='mb-4 d-flex justify-content-end'>
                        <Button
                            data-test='backButton'
                            as={NavLink}
                            to={props.backRedirectUrl}
                            className='btn btn-dark btn-bold btn-light-dark'
                        >
                            {props.backText}
                        </Button>
                    </div>

                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'ROLES.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                                })}
                            </Card.Header>

                            <Card.Body>
                                <BaseForm.Row>
                                    <Input
                                        md={12}
                                        type='text'
                                        name='name'
                                        label={intl.formatMessage({
                                            id: 'ROLES.ROLE_NAME_FIELD_LABEL',
                                        })}
                                        placeholder={intl.formatMessage({
                                            id:
                                                'ROLES.ROLE_NAME_FIELD_PLACEHOLDER',
                                        })}
                                        value={values.name}
                                        onChange={handleChange}
                                        error={errors.name}
                                        isInvalid={errors.name}
                                    />
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'ROLES.PRIVILEGE_PROFILE_FORM_SECTION_TITLE',
                                })}
                            </Card.Header>

                            <Card.Body className='p-0'>
                                <div className='p-3 text-right'>
                                    <Button
                                        className='mr-3'
                                        variant='secondary'
                                        onClick={() => {
                                            unSelectAllPermissions(
                                                setFieldValue,
                                            );
                                        }}
                                    >
                                        {intl.formatMessage({
                                            id: 'ROLES.UNSELECT_ALL_ROLES',
                                        })}
                                    </Button>
                                    <Button
                                        variant='primary'
                                        onClick={() => {
                                            selectAllPermissions(setFieldValue);
                                        }}
                                    >
                                        {intl.formatMessage({
                                            id: 'ROLES.SELECT_ALL_ROLES',
                                        })}
                                    </Button>
                                </div>
                                <Table className='mb-0'>
                                    <tbody>
                                        {props.permissionGroups.map(
                                            (group, i) => (
                                                <tr key={`${group}-${i}`}>
                                                    <td>{group}</td>
                                                    <td>
                                                        {props.permissions
                                                            .filter(
                                                                (permission) =>
                                                                    permission.group ===
                                                                    group,
                                                            )
                                                            .map(
                                                                (
                                                                    permission,
                                                                    iP,
                                                                ) => (
                                                                    <BaseForm.Row
                                                                        key={iP}
                                                                        className='text-left'
                                                                    >
                                                                        <Input
                                                                            md={
                                                                                2
                                                                            }
                                                                            id={`#${permission.code}${iP}`}
                                                                            name='permissions'
                                                                            type='switch'
                                                                            value={
                                                                                permission.code
                                                                            }
                                                                            placeholder={
                                                                                permission.description
                                                                            }
                                                                            onChange={
                                                                                handleChange
                                                                            }
                                                                            checked={values.permissions.includes(
                                                                                permission.code,
                                                                            )}
                                                                        />
                                                                    </BaseForm.Row>
                                                                ),
                                                            )}
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </BaseForm>

                    <div className='d-flex justify-content-between'>
                        {props.isEdit ? (
                            <Can run='USERS_DELETE' cantRun={<div />}>
                                {props.role?.userCount > 0 ? (
                                    <Button
                                        variant='danger'
                                        className='btn-bold'
                                        disabled='disabled'
                                    >
                                        {intl.formatMessage({
                                            id:
                                                'ROLES.DELETE_ROLE_BUTTON_LABEL',
                                        })}
                                    </Button>
                                ) : (
                                    <div>
                                        <div>
                                            <ConfirmModal
                                                titleModal={intl.formatMessage({
                                                    id:
                                                        'ROLES.DELETE_ROLE_MODAL_TITLE',
                                                })}
                                                handleConfirm={
                                                    props.handleDeleteRole
                                                }
                                                handleShow={
                                                    handleToggleIsDeleteRoleModal
                                                }
                                                handleClose={
                                                    handleToggleIsDeleteRoleModal
                                                }
                                                show={isDeleteRoleModalOpen}
                                                variant='danger'
                                                buttonClassName='btn-bold'
                                                buttonLabel={intl.formatMessage(
                                                    {
                                                        id:
                                                            'ROLES.DELETE_ROLE_BUTTON_LABEL',
                                                    },
                                                )}
                                                buttonAcceptLabel={intl.formatMessage(
                                                    {
                                                        id:
                                                            'ROLES.CONFIRM_DELETE_BUTTON_LABEL',
                                                    },
                                                )}
                                                buttonCloseLabel={intl.formatMessage(
                                                    {
                                                        id:
                                                            'ROLES.CANCEL_DELETE_BUTTON_LABEL',
                                                    },
                                                )}
                                            >
                                                <p>
                                                    {intl.formatMessage({
                                                        id:
                                                            'ROLES.DELETE_ROLE_CONFIRMATION_MESSAGE',
                                                    })}
                                                </p>
                                            </ConfirmModal>
                                        </div>
                                    </div>
                                )}
                            </Can>
                        ) : (
                            <div />
                        )}
                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'GENERAL.SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
