import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import BanksList from './BanksList';
import withLayout from '../../components/ui/Layout';


function BanksRoutes() {
    const intl = useIntl();

    return (
        <Switch data-test='stateComponent'>
            <PrivateRoute  permission='BANKS_INDEX'
                exact
                path='/banks'
                component={withLayout(
                    BanksList,
                    intl.formatMessage({id: 'ROUTES.BANKS.INDEX'}),
                )}
            />

            {/* <PrivateRoute  permission='STATES_CREATE'
                exact
                path='/states/create'
                component={withLayout(
                    CreateState,
                    intl.formatMessage({id: 'ROUTES.STATES.CREATE'}),
                    )}
            />

            <PrivateRoute permission='STATES_SHOW'
                exact
                path='/states/:id'
                component={withLayout(
                    StateDetail,
                    intl.formatMessage({id: 'ROUTES.STATES.SHOW'}),
                )}
            />

            <PrivateRoute  permission='STATES_EDIT'
                exact
                path='/states/:id/edit'
                component={withLayout(
                    EditState,
                    intl.formatMessage({id: 'ROUTES.STATES.EDIT'}),
                )}
            /> */}

        </Switch>
    );
}

export default BanksRoutes;
