import React, {useEffect, useState} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {useIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';

import Sidebar from './Sidebar';
import DummyImage from '../../common/img/no-avatar.svg';
import {store} from '../../common/redux/StoreReducers';
import LanguageSelector from '../LanguageSelector';

import './Layout.scss';
import './TopNavbar.scss';

export default function withLayout(WrappedComponent, title) {
  function HOC(props) {
    const intl = useIntl();
    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
      if (!!props.location?.state?.successMessage) {
        setDisplaySuccessMessage(true);

        setTimeout(() => {
          setDisplaySuccessMessage(false);
          props.history.replace();
        }, 3000);
      }
    }, [props.history, props.location]);

    return (
      <div id='sidebar-container'>
        <Sidebar
          onToggleTopMenu={(e) => {
            setIsSidebarOpen(!isSidebarOpen);
          }}
        />
        <div id='content-container'>
          <Navbar
            expand='md'
            expanded={isSidebarOpen}
            id='top-navbar'
            bg='light'
            className='border-bottom'
          >
            <Navbar.Text href='#home'>{title}</Navbar.Text>

            <Navbar.Collapse id='top-navbar-nav'>
              <Nav className='ml-auto'>
                <Nav.Link as={NavLink} to='/'>
                  Dashboard
                </Nav.Link>
                <LanguageSelector />
                <NavDropdown
                  className='top-navbar-user-dropdown'
                  title={
                    <>
                      <Image
                        src={props.user?.pictureUrl || DummyImage}
                        width='31'
                        height='31'
                        className='mr-2'
                        style={{
                          borderRadius: '50%',
                        }}
                      />{' '}
                      {props.user.firstName}
                    </>
                  }
                  alignRight
                >
                  <NavDropdown.Item
                    onClick={() => {
                      store.dispatch(props.logout());
                    }}
                  >
                    {intl.formatMessage({
                      id: 'AUTH.LOGOUT.BUTTON',
                    })}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div className='container-fluid' id='main-container'>
            <Alert
              variant='success'
              className='mb-4'
              show={displaySuccessMessage}
            >
              <p className='mb-0'>
                {props.location.state && props.location.state.successMessage
                  ? props.location.state.successMessage
                  : ''}
              </p>
            </Alert>
            <WrappedComponent {...props} />
          </div>
        </div>
      </div>
    );
  }

  const logout = function () {
    return {
      type: 'LOGOUT',
    };
  };

  const mapStateToProps = function (state) {
    return {
      user: state.sessionReducer.user,
      title: state.testReducer.title,
      content: state.testReducer.content,
    };
  };

  const mapDispatchToProps = {
    logout: logout,
  };

  return connect(mapStateToProps, mapDispatchToProps)(HOC);
}
