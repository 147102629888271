import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Municipality from '../../models/Municipality';
import {store} from '../../redux/StoreReducers';

const MunicipalitiesApiService =  {
    get: async (pageNumber = 1, keyword = '', state='', pageSize=12) => {
        const {data: response} = await API.get(
            `/municipalities/?page=${pageNumber}&search=${keyword}&perPage=${pageSize}&state=${state}`,
        );
        const list = new PaginatedList();
        list.items = response.data.map((municipality) => new Municipality(municipality));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;
        return list;    
    },

    detail: async(id) => {
        const {data: response} = await API.get(`/municipalities/${id}`);
        const municipality = new Municipality(response.data);
        return municipality;
    },

    save: async (input) => {
        await API.post(`/municipalities/`, input, {
            headers: {'Accept-Language': store.getState().langReducer.lang},
        });

        return {};
    },

    update: async (id, params) => {
        const {data: response} = await API.put(`/municipalities/${id}`, params);
        return {municipality: new Municipality(response.data)};
    },

    delete: async (id) => {
        await API.delete(`/municipalities/${id}`);
        return {};
    },

    byState: async(stateId) => {
        const response = await API.get(`/municipalities/byState?state=${stateId}`);
        const municipalities = response.data.map((mun) => new Municipality(mun));
        return municipalities;
    }
}

export default MunicipalitiesApiService;