import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Vivarium from '../../models/Vivarium';
import {store} from '../../redux/StoreReducers';

const VivariumsApiService =  {
    get: async (pageNumber = 1, keyword = '') => {
        const {data: response} = await API.get(
            `/vivariums/?page=${pageNumber}&search=${keyword}`,
        );
        const list = new PaginatedList();
        list.items = response.data.map((vivarium) => new Vivarium(vivarium));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;
        return list;    
    },

    detail: async(id) => {
        const {data: response} = await API.get(`/vivariums/${id}`);
        const vivarium = new Vivarium(response.data);
        return vivarium;
    },

    all: async() => {
        const {data: response} = await API.get(`/vivariums/all`);
        return response.data;
    },

    save: async (input) => {
        await API.post(`/vivariums/`, input, {
            headers: {'Accept-Language': store.getState().langReducer.lang},
        });

        return {};
    },

    update: async (id, params) => {
        const {data: response} = await API.put(`/vivariums/${id}`, params);
        return {vivarium: new Vivarium(response.data)};
    },

    delete: async (id) => {
        await API.delete(`/vivariums/${id}`);
        return {};
    },
}

export default VivariumsApiService;