import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Delivery from '../../models/Delivery';
import {store} from '../../redux/StoreReducers';

const DeliverysApiService =  {
    get: async (pageNumber = 1, 
        keyword = '',
        status = '',
        from='',
        to = '',
        licensee = '',
        vivarium = ''
    ) => {
        let params = `?page=${pageNumber}`;
        
        if (keyword) {
            params +=`&search=${keyword}`;
        }

        if (status) {
            params +=`&status=${status}`;
        }

        if (from) {
            params +=`&from=${from}`;
        }

        if (to) {
            params +=`&from=${to}`;
        }
        if (licensee) {
            params +=`&licensee=${licensee}`;
        }
        if (vivarium) {
            params +=`&vivarium=${vivarium}`;
        }


        const {data: response} = await API.get(
            `/deliveries/paginated${params}`,
        );
        const list = new PaginatedList();
        list.items = response.data.map((delivery) => new Delivery(delivery));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;
        return list;    
    },

    detail: async(id) => {
        const {data: response} = await API.get(`/deliveries/${id}`);
        const delivery = new Delivery(response.data);
        return delivery;
    },

    save: async (input) => {
        await API.post(`/deliveries/`, input, {
            headers: {'Accept-Language': store.getState().langReducer.lang},
        });
        return {};
    },

    update: async (id, params) => {
        const response = await API.put(`/deliveries/${id}`, params);
        return {delivery: new Delivery(response.data)};
    },

    deliver: async (id) => {
        const response = await API.put(`/deliveries/${id}/deliver`);
        return {delivery: new Delivery(response.data)};
    },

    partiallySeeded: async (id) => {
        const response = await API.put(`/deliveries/${id}/partiallySeeded`);
        return {delivery: new Delivery(response.data)};
    },

    fullySeeded: async (id) => {
        const response = await API.put(`/deliveries/${id}/fullySeeded`);
        return {delivery: new Delivery(response.data)};
    },
    
    cancel: async (id) => {
        const response = await API.put(`/deliveries/${id}/cancel`);
        return {delivery: new Delivery(response.data)};
    },

    delete: async (id) => {
        await API.delete(`/deliveries/${id}`);
        return {};
    },
    
    report: async (input) => {
        await API.post(`/deliveries/excel`, input, {
            headers: {'Accept-Language': store.getState().langReducer.lang},
        }).then(response => {
            let file = new Blob([new Uint8Array(response.data.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file,'report.xlsx');
            } else {
                const url = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'reporte.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        });
    }
}

export default DeliverysApiService;