import general from './general/en';
import starterList from './starter_list/en';
import roles from './roles/en';
import users from './users/en';
import routes from './routes/en';
import plants from './plants/en';
import varieties from './varieties/en';
import states from './states/en.json';
import municipalities from './municipalities/en.json';
import plagues from './plagues/en.json';
import vivariums from './vivariums/en.json';
import banks from './banks/en.json';
import licensees from './licensees/en.json';
import deliveries from './deliveries/en.json';
import productions from './productions/en.json';
import properties from './properties/en.json';
import producers from './producers/en.json';
import wt from './waterTypes/en.json';
import st from './soilTypes/en.json';
import ct from './certificateTypes/en.json';

import reports from './reports/en.json';

export default {
  ...general,
  ...starterList,
  ...roles,
  ...users,
  ...routes,
  ...plants,
  ...varieties,
  ...states,
  ...municipalities,
  ...plagues,
  ...vivariums,
  ...banks,
  ...licensees,
  ...deliveries,
  ...productions,
  ...properties,
  ...producers,
  ...reports,
  ...wt,
  ...st,
  ...ct,
};
