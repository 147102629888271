import React, { Component } from 'react';

import GoogleMapReact from 'google-map-react';

import styled from 'styled-components';

import AutoComplete from './Autocomplete';
import Marker from './Marker';

const Wrapper = styled.main`
  width: 100%;
  height: 500px;
`;

class MyGoogleMap extends Component {
  constructor(props) {
    super(props);

    this.mapInstance = React.createRef(null);
    this.mapApi = React.createRef(null);

    this.state = {
      mapApiLoaded: false,
      geoCoder: null,
      places: [],
      center: [],
      zoom: 17,
      address: '',
      draggable: true,
      lat: null,
      lng: null,
    };
  }

  componentDidMount() {
    this.setCurrentLocation();
  }

  onMarkerInteraction = (childKey, childProps, mouse) => {
    console.log("onMarkerInteraction", mouse);
    this.setState({
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });
  };

  onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
    this.setState({ draggable: true });
    this._generateAddress();
  };

  _onChange = ({ center, zoom }) => {
    this.setState({
      center: center,
      zoom: zoom,
    });
  };

  _onClick = (value) => {
    this.setState({
      lat: value.lat,
      lng: value.lng,
    });
    this.props.mapClick({ lat: value.lat, lng: value.lng, address: this.state.address });
  };

  apiHasLoaded = (map, maps) => {
    this.mapInstance.current = map;
    this.mapApi.current = maps;

    this.setState({
      mapApiLoaded: true,
    });

    this._generateAddress();
  };

  addPlace = (place) => {
    this.setState({
      places: [place],
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    this._generateAddress();
  };

  _generateAddress() {
    const {
      mapApi: { current: mapApi },
    } = this;

    const geocoder = new mapApi.Geocoder();

    geocoder.geocode(
      { location: { lat: this.state.lat, lng: this.state.lng } },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 17;
            this.setState({ address: results[0].formatted_address });
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      },
    );
  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    const defaultPos = {
      center: { lat: this.props.center.lat, lng: this.props.center.lng },
      lat: this.props.center.lat,
      lng: this.props.center.lng,
    };

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            center: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          this.setState(defaultPos);
        },
      );
    } else {
      this.setState(defaultPos);
    }
  }

  render() {
    const { center, mapApiLoaded } = this.state;

    return (
      <Wrapper>
        {mapApiLoaded && (
          <div>
            <AutoComplete
              map={this.mapInstance.current}
              mapApi={this.mapApi.current}
              addplace={this.addPlace}
            />
          </div>
        )}
        {center.lat && center.lng && (
          <React.Fragment>
            <GoogleMapReact
              center={center}
              zoom={this.state.zoom}
              draggable={this.state.draggable}
              onChange={this._onChange}
              onChildMouseDown={this.onMarkerInteraction}
              onChildMouseUp={this.onMarkerInteractionMouseUp}
              onChildMouseMove={this.onMarkerInteraction}
              onChildClick={() => console.log('child click')}
              onClick={this._onClick}
              bootstrapURLKeys={{
                key: this.props.API_KEY,
                libraries: ['places', 'geometry'],
              }}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => {
                this.apiHasLoaded(map, maps);
              }}
            >
              <Marker
                text={this.state.address}
                lat={this.state.lat}
                lng={this.state.lng}
              />
            </GoogleMapReact>
          </React.Fragment>
        )}
      </Wrapper>
    );
  }
}

export default MyGoogleMap;
