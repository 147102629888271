import React from "react";
import { Row, Col, } from "react-bootstrap";
import { useIntl } from "react-intl";

export default function Hydroponics(props) {
    const intl = useIntl();
    return (
        <Row>
            <Col md="4">
                <p className="badge-info badge" style={{fontSize: "16px"}}>
                    {intl.formatMessage({
                        id: 'PROPERTIES.IRRIGATION_SYSTEM_HYDROPONICS',
                    })}
                </p>
            </Col>
            <Col md="4">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_HYDROPONICS_FLOWERPOT',
                    })}
                </label>
                <p>
                    {props.data.flowerpot}
                </p>
            </Col>
            <Col md="4">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_HYDROPONICS_PICKETSPERPLANT',
                    })}
                </label>
                <p>
                    {props.data.picketsPerPlant}
                </p>
            </Col>
        </Row>
    );
}