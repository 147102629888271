import React from "react";
import { Row, Col} from "react-bootstrap";
import { useIntl } from "react-intl";

export default function Conventional(props) {
    const intl = useIntl();
    return (
        <Row>
            <Col md="3">
                <p className="badge-info badge" style={{fontSize: "16px"}}>
                    {intl.formatMessage({
                        id: 'PROPERTIES.IRRIGATION_SYSTEM_CONVENTIONAL',
                    })}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_HOSE_LABEL',
                    })}
                </label>
                <p>
                    {props.data.convHose}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_CONTROLLER_LABEL',
                    })}
                </label>
                <p>
                    {props.data.convController}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_WATERPUMP_LABEL',
                    })}
                </label>
                <p>
                    {props.data.convWaterPump}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_IRRIGATIONHEAD_LABEL',
                    })}
                </label>
                <p>
                    {props.data.convIrrigationHead}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_TANKS_LABEL',
                    })}
                </label>
                <p>
                    {props.data.convTanks}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_FILTERS_LABEL',
                    })}
                </label>
                <p>
                    {props.data.convFilters}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_VALVES_LABEL',
                    })}
                </label>
                <p>
                    {props.data.convValves}
                </p>
            </Col>
        </Row>
    );
}