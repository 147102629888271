import React from "react";
import { injectIntl } from 'react-intl';
import { Alert } from "react-bootstrap";
import API from "../../common/utils/API";
import Form from '../../components/form/producers/ProducerForm';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import LoadingButton from '../../components/form/LoadingButton';
import { Redirect } from "react-router-dom";
import Producer from "../../common/models/Producer";

export class EditProducer extends React.Component {
    constructor (props) {
        super(props);
        this.state =  {
            loading: false,
            shouldRedirectToDetail: false,
            shouldRedirectToIndex: false,
            detail: new Producer(),
            errorMessage: '',
            isLoadErrorModalOpen: false,
            redirectSuccessMessage: '',
            varieties: [],
            banks: [],
            producerTypes: [
                {id: 'new', name: 'NUEVO'},
                {id: 'settled_down', name: 'ESTABLECIDO'}
            ],
            fiscalTypes: [
                {id: 'fisica', name: 'FÍSICA'},
                {id: 'moral', name: 'MORAL'}
            ],
            producerStatus: [
                {id: 'pending', name: this.props.intl.formatMessage({id: 'PRODUCERS.PRODUCER_STATUS_PENDING'})},
                {id: 'approved', name: this.props.intl.formatMessage({id: 'PRODUCERS.PRODUCER_STATUS_APPROVED'})},
                {id: 'conditioned_approved', name: this.props.intl.formatMessage({id: 'PRODUCERS.PRODUCER_STATUS_CONDITIONED'})},
                {id: 'rejected', name: this.props.intl.formatMessage({id: 'PRODUCERS.PRODUCER_STATUS_REJECTED'})},
            ],
            licensees: []
        }
    }
    async componentDidMount() {
        await Promise.all([
            this.fetchDetail(),
            this.fetchBanks(),
            this.fetchLicensees(),
            this.fetchVarieties(),
        ]);
        window.scroll({top: 0})
    }

    fetchDetail = async() => {
        this.setState({
            loading: true,
        })
        try {
            const detail = await API.producers.detail(this.props.match.params.id);
            this.setState({
                detail: detail,
                loading: false,
                isLoadErrorModalOpen: false,
            })
        } catch (error) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    }

    fetchVarieties = async() => {
        try {
            const varieties = await API.varieties.all();
            this.setState({
                varieties: varieties
            });
        } catch (error) {
            console.log(error)
            this.setState({
                error: false
            });
        }
    };

    fetchBanks = async() => {
        try {
            const banks = await API.banks.all();
            this.setState({
                banks: banks
            });
        } catch (error) {
            console.log(error)
            this.setState({
                error: false
            });
        }
    };

    fetchLicensees = async() => {
        try {
            const licensees = await API.licensees.all();
            this.setState({
                licensees
            });
        } catch (error) {
            console.log(error)
            this.setState({
                error: false
            });
        }
    }

    onSubmit = async (input) => {
        this.setState({loading: true});
            try {
            await API.producers.update(
                this.props.match.params.id,
                input,
            );

            this.setState({
                shouldRedirectToDetail: true,
                loading: false,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'PRODUCERS.PRODUCER_SUCCESSFULLY_UPDATED',
                }),
            });
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.UPDATING_DATA',
                    }),
                });
            }
        }
    };

    handleDeleteDetail = async() => {
        this.setState({loading: true});
        try {
            const res = await API.producers.delete(this.props.match.params.id);
            console.log(res)
            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'PRODUCERS.PRODUCER_SUCCESSFULLY_DELETED',
                }),
            });
        } catch (e) {
            const mes = e.response?.data?.messages?.error;

            this.setState({
                loading: false,
                errorMessage: mes || this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.DELETING_DATA',
                }),
            });
        }
    }

    render() {
        const initialValues =  {
            ...this.state.detail,
            variety: this.state.detail.varietyId,
            bank: this.state.detail.bank.id,
        }
        return this.state.shouldRedirectToDetail ? (
            <Redirect
                data-test='redirectToDetail'
                to={{
                    pathname: `/producers/${this.props.match.params.id}`,
                    state: { successMessage: this.state.redirectSuccessMessage,},
                }}
            />
        ): this.state.shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/producers',
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) :
        (<>
            <Alert
                variant='danger'
                className='mb-4'
                style={{position:'fixed', zIndex: '1001'}}
                show={!!this.state.errorMessage}
            >
                <p className='mb-0'>{this.state.errorMessage}</p>
            </Alert>

            <Form data-test='updateProducerComponent'
                initialValues={initialValues}
                backRedirectUrl={`/producers/${this.props.match.params.id}`}
                loading={this.state.loading}
                isEdit={true}
                handleSubmit={this.onSubmit}
                handleDeleteItem={this.handleDeleteDetail}
                backText={this.props.intl.formatMessage({
                    id: 'LIST.BACK_TO_DETAIL',
                })}
                varieties={this.state.varieties}
                banks={this.state.banks}
                fiscalTypes={this.state.fiscalTypes}
                producerTypes={this.state.producerTypes}
                producerStatus={this.state.producerStatus}
                licensees={this.state.licensees}
            >
            </Form>

            <Modal
                show={this.state.isLoadErrorModalOpen}
                onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'USERS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href={`/plagues/${this.props.match.params.id}`}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchUser}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
            </Modal>
        </>);
    }
}

export default injectIntl(EditProducer);
