import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import VarietiesList from './VarietiesList';
import CreateVariety from './CreateVariety';
import EditVariety from './EditVariety';
import VarietyDetail from './VarietyDetail';
import withLayout from '../../components/ui/Layout';

function VarietiesRoutes() {
    const intl = useIntl();

    return (
        <Switch data-test='plantsComponent'>
            <PrivateRoute  permission='VARIETIES_INDEX'
                exact
                path='/varieties'
                component={withLayout(
                    VarietiesList,
                    intl.formatMessage({id: 'ROUTES.VARIETIES.INDEX'}),
                )}
            />

            <PrivateRoute  permission='VARIETIES_CREATE'
                exact
                path='/varieties/create'
                component={withLayout(
                    CreateVariety,
                    intl.formatMessage({id: 'ROUTES.VARIETIES.CREATE'}),
                )}
            />

            <PrivateRoute permission='VARIETIES_SHOW'
                exact
                path='/varieties/:id'
                component={withLayout(
                    VarietyDetail,
                    intl.formatMessage({id: 'ROUTES.VARIETIES.SHOW'}),
                )}
            />

            <PrivateRoute  permission='VARIETIES_EDIT'
                exact
                path='/varieties/:id/edit'
                component={withLayout(
                    EditVariety,
                    intl.formatMessage({id: 'ROUTES.VARIETIES.EDIT'}),
                )}
            />

        </Switch>
    );
}

export default VarietiesRoutes;
