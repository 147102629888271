import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Variety from '../../models/Variety';
import {store} from '../../redux/StoreReducers';

const VarietiesApiService = {
    get: async (pageNumber = 1, keyword = '', plant = '') => {
        const {data: response} = await API.get(
            `/varieties/paginatedVarieties?page=${pageNumber}&search=${keyword}&plant=${plant}`,
        );
        console.log(response);
        const list = new PaginatedList();
        list.items = response.data.map((variety) => new Variety(variety));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;
        return list;
    },

    detail: async (id) => {
        const {data: response} = await API.get(`/varieties/${id}`);
        const variety = new Variety(response.data);
        return variety;
    },

    allPlants: async () => {
        const {data: response} = await API.get(`/varieties/plants`);
        return response.data;
    },

    save: async (input) => {
        await API.post(`/varieties/`, input, {
            headers: {'Accept-Language': store.getState().langReducer.lang},
        });

        return {};
    },

    update: async (id, params) => {
        const {data: response} = await API.put(`/varieties/${id}`, params);
        return {variety: new Variety(response.data)};
    },

    delete: async (id) => {
        await API.delete(`/varieties/${id}`);
        return {};
    },
    all: async () => {
        const {data: response} = await API.get(`/varieties/all`);
        return response.data;
    },
};

export default VarietiesApiService;
