import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import API from '../../common/utils/API';
import Form from '../../components/form/deliveries/DeliveryForm';
import Delivery from '../../common/models/Delivery';

export class CreateDelivery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            delivery: new Delivery(),
            loading: false,
            shouldRedirectToIndex: false,
            vivariums: [],
            licensees: [],
            varieties: [],
            errorMessage: '',
            deliveryVarieties:[],
            selectedQuantity:0,
            selectedVariety:0,
        };
    };

    async componentDidMount() {
        await this.fetchLicensees();
        await this.fetchVarieties();
        await this.fetchVivariums();
    };

    onSubmit = async (input) => {
        if (this.state.deliveryVarieties.length === 0) {
            this.setState({
                loading: false,
                errorMessage: this.props.intl.formatMessage({
                    id: 'DELIVERIES.VALIDATION.NO_DETAILS',
                }),
            });
            return;
        }
        try {
            const varieties = this.state.deliveryVarieties.map((item) => {
                return {
                    variety: item.id,
                    quantity: item.quantity
                }
            })
            const send = {
                varieties,
                licensee: input.licensee,
                vivarium: input.vivarium,
                estimatedDeliveryDate: input.estimatedDeliveryDate
            }
            await API.deliveries.save(send);
            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
            });
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.STORING_DATA',
                    }),
                });
            }
        }
    };

    fetchLicensees = async() => {
        try {
            const licensees = await API.licensees.all();
            this.setState({
                licensees
            });
        } catch (error) {
            this.setState({
                error: false
            });
        }
    };

    fetchVivariums = async() => {
        try {
            const vivariums = await API.vivariums.all();
            this.setState({
                vivariums
            });
        } catch (error) {
            this.setState({
                error: false
            });
        }
    };

    fetchVarieties = async() => {
        try {
            const varieties = await API.varieties.all();
            this.setState({
                varieties: varieties
            });
        } catch (error) {
            console.log(error)
            this.setState({
                error: false
            });
        }
    };

    addDetail = () => {
        const details = this.state.deliveryVarieties;
        const variety = this.state.selectedVariety;
        const quantity = this.state.selectedQuantity;
        const detail = this.state.varieties.find((item) => {
            if (item.id === variety) {
                return item;
            } else {
                return null;
            }
        });

        const exist = details.find(item => {
            if (item.id === variety) {
                return true;
            } else {
                return false
            }
        });

        if (detail && !exist) {
            detail.quantity = quantity;
            details.push(detail);
            this.setState({
                deliveryVarieties: details,
                selectedQuantity: 0,
                selectedVariety: 0
            })
        }
    }

    deleteDetail = async(id) => {
        const variety = parseInt(id);
        const details = this.state.deliveryVarieties;
        details.map((item,index) => {
            if (item.id === variety) {
                details.splice(index,1);
            }
            return false;
        });
        this.setState({
            deliveryVarieties: details,
        })
    }
    
    changeDetail = async(detail, type) => {
        if (type === 'variety') {
            this.setState({
                selectedVariety: parseInt(detail),
            });
        } else {
            this.setState({
                selectedQuantity: parseInt(detail),
            });
        }
    }
    
    render() {
        const initialValues = {
        };
        return this.state.shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/deliveries',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id: 'DELIVERIES.DELIVERY_CREATED_SUCCESSFULLY',
                        }),
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form data-test='createVarietyComponent'
                    initialValues={initialValues}
                    backRedirectUrl='/deliveries'
                    backText={this.props.intl.formatMessage({id: 'LIST.BACK_TO_LIST'})}
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                    varieties={this.state.varieties}
                    vivariums={this.state.vivariums}
                    licensees={this.state.licensees}
                    deliveryVarieties={this.state.deliveryVarieties}
                    addDetail={this.addDetail}
                    changeDetail={this.changeDetail}
                    selectedQuantity={this.state.selectedQuantity}
                    selectedVariety={this.state.selectedVariety}
                    deleteDetail={this.deleteDetail}
                />
            </>
        );
    };
}

export default injectIntl(CreateDelivery);
