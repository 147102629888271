import {API} from '../API';
import PaginatedList from '../PaginatedList';
import WaterType from 'common/models/WaterType';
import {store} from 'common/redux/StoreReducers';

const WaterTypeApiService = {
  get: async (pageNumber = 1, keyword = '') => {
    const {data: response} = await API.get(
      `/waterTypes/?page=${pageNumber}&search=${keyword}`,
    );
    const list = new PaginatedList();
    list.items = response.data.map((wt) => new WaterType(wt));
    list.page = response.meta.page;
    list.pageCount = response.meta.lastPage;
    list.pageSize = response.meta.perPage;
    list.total = response.meta.total;
    return list;
  },
  detail: async (id) => {
    const {data: response} = await API.get(`/waterTypes/${id}`);
    const waterType = new WaterType(response.data);

    return waterType;
  },
  save: async (params) => {
    await API.post('/waterTypes', params, {
      headers: {'Accept-Language': store.getState().langReducer.lang},
    });

    return;
  },
  update: async (id, input) => {
    const {data: response} = await API.put(`/waterTypes/${id}`, input);

    return {waterType: new WaterType(response.data)};
  },
  delete: async (id) => {
    await API.delete(`/waterTypes/${id}`);

    return {};
  },
};

export default WaterTypeApiService;
