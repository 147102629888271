export default class State {
    constructor({
        id = 0,
        name = '',
        municipalties= 0
    } = {}) {
        this.id = id;
        this.name = name;
        this.municipalties = municipalties;
    }
}