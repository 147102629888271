import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import DeliveriesList from './DeliveriesList';
import withLayout from '../../components/ui/Layout';
import DeliveryDetail from './DeliveryDetail';
import CreateDelivery from './CreateDelivery';
import EditDelivery from './EditDelivery'

function DeliveriesRoutes() {
    const intl = useIntl();

    return (
        <Switch data-test='stateComponent'>
            <PrivateRoute  permission='DELIVERIES_INDEX'
                exact
                path='/deliveries'
                component={withLayout(
                    DeliveriesList,
                    intl.formatMessage({id: 'ROUTES.DELIVERIES.INDEX'}),
                )}
            />
            <PrivateRoute  permission='DELIVERIES_CREATE'
                exact
                path='/deliveries/create'
                component={withLayout(
                    CreateDelivery,
                    intl.formatMessage({id: 'ROUTES.DELIVERIES.CREATE'}),
                )}
            />
            <PrivateRoute  permission='DELIVERIES_SHOW'
                exact
                path='/deliveries/:id'
                component={withLayout(
                    DeliveryDetail,
                    intl.formatMessage({id: 'ROUTES.DELIVERIES.SHOW'}),
                )}
            />
            <PrivateRoute  permission='DELIVERIES_EDIT'
                exact
                path='/deliveries/:id/edit'
                component={withLayout(
                    EditDelivery,
                    intl.formatMessage({id: 'ROUTES.DELIVERIES.EDIT'}),
                )}
            />
        </Switch>
    );
}

export default DeliveriesRoutes;
