import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import PlaguesList from './PlaguesList';
import withLayout from '../../components/ui/Layout';
import PlagueDetail from './PlagueDetail';
import CreatePlague from './CreatePlague';
import EditPlague from './EditPlague';


function PlagueRoutes() {
    const intl = useIntl();

    return (
        <Switch data-test='stateComponent'>
            <PrivateRoute  permission='PLAGUES_INDEX'
                exact
                path='/plagues'
                component={withLayout(
                    PlaguesList,
                    intl.formatMessage({id: 'ROUTES.PLAGUES.INDEX'}),
                )}
            />
            <PrivateRoute  permission='PLAGUES_CREATE'
                exact
                path='/plagues/create'
                component={withLayout(
                    CreatePlague,
                    intl.formatMessage({id: 'ROUTES.PLAGUES.CREATE'}),
                )}
            />
            <PrivateRoute  permission='PLAGUES_SHOW'
                exact
                path='/plagues/:id'
                component={withLayout(
                    PlagueDetail,
                    intl.formatMessage({id: 'ROUTES.PLAGUES.SHOW'}),
                )}
            />

            <PrivateRoute  permission='PLAGUES_EDIT'
                exact
                path='/plagues/:id/edit'
                component={withLayout(
                    EditPlague,
                    intl.formatMessage({id: 'ROUTES.PLAGUES.EDIT'}),
                )}
            />
        </Switch>
    );
}

export default PlagueRoutes;
