import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Production from '../../models/Production';

const ProductionsApiService =  {
    get: async (pageNumber = 1, keyword = '') => {
        const {data: response} = await API.get(
            `/productions/paginated?page=${pageNumber}&search=${keyword}`,
        );

        const list = new PaginatedList();
        list.items = response.data.map((prod) => new Production(prod));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;
        return list;    
    },

    detail: async (id) => {
        const {data: response} = await API.get(`/productions/${id}`);
        const production = new Production(response.data);
        return production;
    },
}

export default ProductionsApiService;