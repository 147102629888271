import {API} from '../API';
import PaginatedList from '../PaginatedList';
import State from '../../models/State';
import {store} from '../../redux/StoreReducers';

const StatesApiService =  {
    get: async (pageNumber = 1, keyword = '') => {
        const {data: response} = await API.get(
            `/states/?page=${pageNumber}&search=${keyword}`,
        );
        const list = new PaginatedList();
        list.items = response.data.map((state) => new State(state));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;
        return list;    
    },

    detail: async(id) => {
        const {data: response} = await API.get(`/states/${id}`);
        const state = new State(response.data);
        return state;
    },

    save: async (input) => {
        await API.post(`/states/`, input, {
            headers: {'Accept-Language': store.getState().langReducer.lang},
        });

        return {};
    },

    update: async (id, params) => {
        const {data: response} = await API.put(`/states/${id}`, params);
        return {variety: new State(response.data)};
    },

    delete: async (id) => {
        await API.delete(`/states/${id}`);
        return {};
    },

    all: async() => {
        const {data: response} = await API.get(
            `/states/all`,
        );
        return response.data; 
    }
}

export default StatesApiService;