import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Plague from '../../models/Plague';
import {store} from '../../redux/StoreReducers';

const PlantsApiService =  {
    get: async (pageNumber = 1, keyword = '') => {
        const {data: response} = await API.get(
            `/plagues/?page=${pageNumber}&search=${keyword}`,
        );

        const list = new PaginatedList();
        list.items = response.data.map((item) => new Plague(item));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;
        return list;    
    },

    detail: async (id) => {
        const {data: response} = await API.get(`/plagues/${id}`);
        const plague = new Plague(response.data);
        return plague;
    },

    save: async (params, state) => {
        await API.post(`/plagues/`, params, {
            headers: {'Accept-Language': store.getState().langReducer.lang},
        });

        return {};
    },

    update: async (id, params) => {
        const {data: response} = await API.put(`/plagues/${id}`, params);
        return {plague: new Plague(response.data)};
    },

    delete: async (id) => {
        await API.delete(`/plagues/${id}`);
        return {};
    },
}

export default PlantsApiService;