import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import API from '../../common/utils/API';
import Form from '../../components/form/licensees/LicenseeForm';

export class CreateLicensee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            states: [],
            municipalities:[],
            fiscalTypes: [
                {id: 'fisica', name:"Física"},
                {id: 'moral', name:"Moral"},
            ],
            loading: false,
            shouldRedirectToIndex: false,
            errorMessage: '',
        };
    }
    async componentDidMount() {
        this.fetchStates();
    }

    onSubmit = async (input) => {

        const send = {
            firstName: input.firstName,
            lastName: input.lastName,
            email: input.email,
            phone: input.phone,
            rfc: input.rfc,
            address: input.address,
            noInt: input.noInt,
            location: input.location,
            zipCode: input.zipCode,
            fiscalType: input.fiscalType,
            fiscalName: input.fiscalName ?? '',
            municipality: input.municipalityId,
            password: input.password,
        };
        
        try {
            await API.licensees.save(send);
            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
            });
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.STORING_DATA',
                    }),
                });
            }
        }
    };

    fetchStates = async () => {
        const states = await API.states.all();
        this.setState({
            states: states,
        });
    }

    fetchMunicipalities = async(state) => {

        if (state) {
            const municipalities = await API.municipalities.byState(state);
            this.setState({
                municipalities: municipalities,
            }) 
        }
    }

    render() {
        const initialValues = {
            name: '',
        };
        return this.state.shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/licensees',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id: 'LICENSEES.LICENSEE_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <>
                <Alert variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form data-test='createLicenseeComponent'
                    initialValues={initialValues}
                    backRedirectUrl='/licensees'
                    backText={this.props.intl.formatMessage({
                        id: 'LIST.BACK_TO_LIST',
                    })}
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                    states={this.state.states}
                    municipalities={this.state.municipalities}
                    stateChange={this.fetchMunicipalities}
                    fiscalTypes={this.state.fiscalTypes}
                />
            </>
        );
    }
}

export default injectIntl(CreateLicensee);
