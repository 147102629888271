  import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Producer from '../../models/Producer';
import {store} from '../../redux/StoreReducers';

const ProducersApiService =  {
    get: async (pageNumber = 1, keyword = '', licensee = 0) => {
        const {data: response} = await API.get(
            `/producers/?page=${pageNumber}&search=${keyword}&licensee=${licensee}`,
        );

        const list = new PaginatedList();
        list.items = response.data.map((prod) => new Producer(prod));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;
        return list;
    },

    all: async () => {
        const {data: response} = await API.get(`/producers/all`);
        return response.data.map((prod) => new Producer(prod));
    },

    save: async(input) => {
        await API.post(`/producers/`, input, {
            headers: {'Accept-Language': store.getState().langReducer.lang},
        });
        return {};
    },

    detail: async (id) => {
        const {data: response} = await API.get(`/producers/${id}`);
        const producer = new Producer(response.data);
        return producer;
    },

    update: async (id, params) => {
        const {data: response} = await API.put(`/producers/${id}`, params);
        return {producer: new Producer(response.data)};
    },

    delete: async (id) => {
        const {data: response} = await API.delete(`/producers/${id}`, {
            headers: {'Accept-Language': store.getState().langReducer.lang},
        });
        return response.data;
    }
}

export default ProducersApiService;