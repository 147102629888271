import React from "react";
import { Row, Col, } from "react-bootstrap";
import { useIntl } from "react-intl";

export default function Substratum(props) {
    const intl = useIntl();
    return (
        <Row>
            <Col md="3">
                <p className="badge-info badge" style={{fontSize: "16px"}}>
                    {intl.formatMessage({
                        id: 'PROPERTIES.IRRIGATION_SYSTEM_SUBSTRATUM',
                    })}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_SUBSTRATUM_BRAND_LABEL',
                    })}
                </label>
                <p>
                    {props.data.subBrand}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_SUBSTRATUM_COMPOSITION_LABEL',
                    })}
                </label>
                <p>
                    {props.data.subComposition}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_SUBSTRATUM_MIX_LABEL',
                    })}
                </label>
                <p>
                    {props.data.subMix}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_SUBSTRATUM_IRRIGATION_SECTORS_LABEL',
                    })}
                </label>
                <p>
                    {props.data.subIrrigationSectors}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_SUBSTRATUM_ANNUAL_CONSUMPTION_LABEL',
                    })}
                </label>
                <p>
                    {props.data.subAnnualConsumption}
                </p>
            </Col>
            <Col md="3">
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_SUBSTRATUM_TOTALREQUIREMENT_LABEL',
                    })}
                </label>
                <p>
                    {props.data.subTotalRequirement}
                </p>
            </Col>
        </Row>
    );

}