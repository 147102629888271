import React from "react";
import { injectIntl } from 'react-intl';
import { Alert } from "react-bootstrap";
import Delivery from "../../common/models/Delivery";
import API from "../../common/utils/API";
import Form from '../../components/form/deliveries/DeliveryForm';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import LoadingButton from '../../components/form/LoadingButton';
import { Redirect } from "react-router-dom";


export class EditDelivery extends React.Component {
    constructor (props) {
        super(props);
        this.state =  {
            loading: false,
            shouldRedirectToDetail: false,
            shouldRedirectToIndex: false,
            delivery: new Delivery(),
            errorMessage: '',
            isLoadErrorModalOpen: false,
            redirectSuccessMessage: '',
            vivariums: [],
            licensees: [],
            varieties: [],
            deliveryVarieties:[],
        }
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchDetail(),
            this.fetchLicensees(),
            this.fetchVivariums(),
            this.fetchVarieties(),
        ]);
    }

    fetchDetail = async() => {
        this.setState({
            loading: true,
        })
        try {
            const detail = await API.deliveries.detail(this.props.match.params.id);
            this.setState({
                delivery: detail,
                deliveryVarieties: detail.varieties,
                loading: false,
                isLoadErrorModalOpen: false,
            })
        } catch (error) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    }

    onSubmit = async (input) => {
        this.setState({loading: true});
        
        if (this.state.deliveryVarieties.length === 0) {
            this.setState({
                loading: false,
                errorMessage: this.props.intl.formatMessage({
                    id: 'DELIVERIES.VALIDATION.NO_DETAILS',
                }),
            });
            return;
        }
        try {
            const varieties = this.state.deliveryVarieties.map((item) => {
                return {
                    variety: item.id,
                    quantity: item.quantity
                }
            })
            const send = {
                varieties,
                licensee: input.licensee,
                vivarium: input.vivarium,
                estimatedDeliveryDate: input.estimatedDeliveryDate
            }
            await API.deliveries.update(
                this.props.match.params.id,
                send);
                this.setState({
                shouldRedirectToDetail: true,
                    loading: false,
                    redirectSuccessMessage: this.props.intl.formatMessage({
                        id: 'DELIVERIES.DELIVERY_SUCCESSFULLY_UPDATED_MESSAGE',
                    }),
                });
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.STORING_DATA',
                    }),
                });
            }
        }
    };

    handleDeleteDetail = async() => {
        this.setState({loading: true});

        try {
            await API.deliveries.delete(this.props.match.params.id);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'DELIVERIES.DELIVERY_SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                loading: false,
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.DELETING_DATA',
                }),
            });
        }
    };

    fetchLicensees = async() => {
        try {
            const licensees = await API.licensees.all();
            this.setState({
                licensees
            });
        } catch (error) {
            this.setState({
                error: false
            });
        }
    };

    fetchVivariums = async() => {
        try {
            const vivariums = await API.vivariums.all();
            this.setState({
                vivariums
            });
        } catch (error) {
            this.setState({
                error: false
            });
        }
    };

    fetchVarieties = async() => {
        try {
            const varieties = await API.varieties.all();
            this.setState({
                varieties: varieties
            });
        } catch (error) {
            this.setState({
                error: false
            });
        }
    };

    addDetail = () => {
        const details = this.state.deliveryVarieties;
        const variety = this.state.selectedVariety;
        const quantity = this.state.selectedQuantity;
        const detail = this.state.varieties.find((item) => {
            if (item.id === variety) {
                return item;
            } else {
                return null;
            }
        });

        const exist = details.find(item => {
            if (item.id === variety) {
                return true;
            } else {
                return false
            }
        });

        if (detail && !exist) {
            detail.quantity = quantity;
            details.push(detail);
            this.setState({
                deliveryVarieties: details,
                selectedQuantity: 0,
                selectedVariety: 0
            })
        }
    };

    deleteDetail = async(id) => {
        const variety = parseInt(id);
        const details = this.state.deliveryVarieties;
        details.map((item,index) => {
            if (item.id === variety) {
                details.splice(index,1);
            }
            return false;
        });
        this.setState({
            deliveryVarieties: details,
        })
    };
    
    changeDetail = async(detail, type) => {
        if (type === 'variety') {
            this.setState({
                selectedVariety: parseInt(detail),
            });
        } else {
            this.setState({
                selectedQuantity: parseInt(detail),
            });
        }
    };

    render() {
        const initialValues = {
            licensee: this.state.delivery.licensee,
            vivarium: this.state.delivery.vivarium,
            estimatedDeliveryDate: this.state.delivery.estimatedDeliveryDateEdit
        }
        return this.state.shouldRedirectToDetail ? (
            <Redirect
                data-test='redirectToDetail'
                to={{
                    pathname: `/deliveries/${this.props.match.params.id}`,
                    state: { successMessage: this.state.redirectSuccessMessage,},
                }}
            />
        ): this.state.shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/deliveries',
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) :
        (<>
            <Alert
                variant='danger'
                className='mb-4'
                show={!!this.state.errorMessage}
            >
                <p className='mb-0'>{this.state.errorMessage}</p>
            </Alert>

            <Form data-test='updateStateComponent'
                initialValues={initialValues}
                backRedirectUrl={`/deliveries/${this.props.match.params.id}`}
                loading={this.state.loading}
                isEdit={true}
                handleSubmit={this.onSubmit}
                handleDeleteItem={this.handleDeleteDetail}
                backText={this.props.intl.formatMessage({
                    id: 'LIST.BACK_TO_DETAIL',
                })}

                varieties={this.state.varieties}
                vivariums={this.state.vivariums}
                licensees={this.state.licensees}
                deliveryVarieties={this.state.deliveryVarieties}
                addDetail={this.addDetail}
                changeDetail={this.changeDetail}
                selectedQuantity={this.state.selectedQuantity}
                selectedVariety={this.state.selectedVariety}
                deleteDetail={this.deleteDetail}
            >
            </Form>

            <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'USERS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href={`/deliveries/${this.props.match.params.id}`}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchUser}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
            </Modal>
        </>);
    };
}

export default injectIntl(EditDelivery);
