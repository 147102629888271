import * as Yup from 'yup';
import {fiscalType} from './Types';
import { useIntl } from 'react-intl';
export default function () {
    const intl = useIntl();
    return Yup.object({
        licensee: Yup.number().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_LICENSEE_MISSING'})
        ),
        firstName: Yup.string().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_FIRSTNAME_MISSING'})
        ),
        lastName: Yup.string().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_LASTNAME_MISSING'})
        ),
        middleLastName: Yup.string().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_MIDDLELASTNAME_MISSING'})
        ),
        fiscalType: Yup.string().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_FISCALTYPE_MISSING'})
        ),
        email: Yup.string().email().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_EMAIL_MISSING'})
        ),
        phone: Yup.string().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_PHONE_MISSING'})
        ),
        hectares: Yup.number().min(0).required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_HECTARES_MISSING'})
        ),
        plantsPerHectare: Yup.number().min(0).required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_PLANTSPERHECTARES_MISSING'})
        ),
        type: Yup.string().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_TYPE_MISSING'})
        ),
        businessName: Yup.string().when('fiscalType',{
            is: fiscalType.MORAL,
            then: Yup.string().required(
                intl.formatMessage({id:'PRODUCERS.PRODUCER_BUSINESSNAME_MISSING'})
            ),
            otherwise: Yup.string()
        }),
        employees: Yup.number().min(1,
            intl.formatMessage({id:'PRODUCERS.PRODUCER_GREATER_THAN1'})
            ).required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_EMPLOYEES_MISSING'})
        ),
        recruitSource: Yup.string().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_RECRUITSOURCE_MISSING'})
        ),
        averageSalary: Yup.number().min(1,
            intl.formatMessage({id:'PRODUCERS.PRODUCER_GREATER_THAN1'})).required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_AVERAGESALARY_MISSING'})
        ),
        hasOffices: Yup.boolean(),
        resourcesOrigin: Yup.string().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_RESOURCESORIGIN_MISSING'})
        ),
        budgetAvailable: Yup.number().positive().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_BUDGETAVAILABLE_MISSING'})
        ),
        bank: Yup.number().required(),
        hasColdRoom: Yup.boolean(),
        hasRefrigeratorTruck: Yup.boolean(),
        requiresFinancing: Yup.boolean(),
        hasExperience: Yup.boolean().default(false),
        experience: Yup.string()
            .when('hasExperience', {
                is: true,
                then: Yup.string().required(
                    intl.formatMessage({id:'PRODUCERS.PRODUCER_EXPERIENCE_MISSING'})
                ),
                otherwise: Yup.string().nullable()
            }),
        experienceTime: Yup.string()
            .when('hasExperience', {
                is: true,
                then: Yup.string().required(
                    intl.formatMessage({id:'PRODUCERS.PRODUCER_EXPERIENCETIME_MISSING'})
                ),
                otherwise: Yup.string()
            }),
        hasProductionManager: Yup.boolean().default(false),
        bussinesPartners: Yup.string().when('hasProductionManager', {
            is: true,
            then: Yup.string().required(
                intl.formatMessage({id:'PRODUCERS.PRODUCER_BUSSINESPARTNERS_MISSING'})
            ),
            otherwise: Yup.string(),
        }),
        reasonChangeCultive: Yup.string().when('hasProductionManager',{
            is: true,
            then: Yup.string().required(
                intl.formatMessage({id:'PRODUCERS.PRODUCER_REASONCHANGECULTIVE_MISSING'})
            ),
            otherwise: Yup.string()
        }),
        producerCodeOwn: Yup.string().when('hasProductionManager', {
            is: true,
            then: Yup.string().required(
                intl.formatMessage({id:'PRODUCERS.PRODUCER_PRODUCERCODEOWN_MISSING'})
            ),
            otherwise: Yup.string()
        }),
        seasonsWorking: Yup.string().when('hasProductionManager',{
            is: true,
            then: Yup.string().required(
                intl.formatMessage({id:'PRODUCERS.PRODUCER_SEASONSWORKING_MISSING'})
            ),
            otherwise: Yup.string()
        }),
        lastLineBusiness: Yup.string().when('hasProductionManager',{
            is: false,
            then: Yup.string().required(
                intl.formatMessage({id:'PRODUCERS.PRODUCER_LASTLINEBUSSINES_MISSING'})
            ),
            otherwise: Yup.string()
        }),
        variety: Yup.number().positive().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_VARIETY_MISSING'})
        ),
        status: Yup.string().required(
            intl.formatMessage({id:'PRODUCERS.PRODUCER_STATUS_MISSING'})
        ),
        signature: Yup.string().required(
            intl.formatMessage({id: 'PRODUCERS.SIGNATURE_MISSING'})
        ),
    });
}