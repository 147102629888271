import React from "react";
import { injectIntl } from 'react-intl';
import { Alert } from "react-bootstrap";
import Property from "common/models/Property";
import API from "../../common/utils/API";
import Form from '../../components/form/properties/PropertyForm';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import LoadingButton from '../../components/form/LoadingButton';
import { Redirect } from "react-router-dom";

export class EditProperty extends React.Component {
  constructor (props) {
    super(props);
    this.state =  {
        loading: false,
        shouldRedirectToDetail: false,
        shouldRedirectToIndex: false,
        detail: new Property(),
        errorMessage: '',
        isLoadErrorModalOpen: false,
        redirectSuccessMessage: '',
        producers: [],
        soilTypes: [],
        waterTypes: [],
        propertyTypes: [
            {id: 'owned', name: 'OWNED'},
            {id: 'rented', name: 'RENTED'},
        ],
        varieties: [],
        certificateTypes: [],
        sectors: [],
        selectedVariety: 0,
        surface: 0,
        alias: ''
    }
  }

  async componentDidMount() {
      await Promise.all([
          this.fetchDetail(),
          this.loadProducers(),
          this.loadSoilTypes(),
          this.loadWaterTypes(),
          this.loadCertificateTypes(),
          this.loadVarieties(),
      ]);
  }

  fetchDetail = async() => {
      this.setState({
          loading: true,
      })
      try {
          const detail = await API.properties.detail(this.props.match.params.id);
          this.setState({
              detail: detail,
              loading: false,
              isLoadErrorModalOpen: false,
              sectors: detail.sectors
          })
      } catch (error) {
          this.setState({
              isLoadErrorModalOpen: true,
              loading: false,
          });
      }
  }

  onSubmit = async (input) => {
      this.setState({loading: true});
      try {
        const send = {
          ...input,
          sectors: this.state.sectors.map(item => ({
            variety: item.id,
            name: item.alias,
            surface: item.surface,
          })),
        };
        delete send.certificateValidityFormatted;
        console.log("me lleva el carajo", send);
        await API.properties.update(
          this.props.match.params.id,
          send);
          this.setState({
            shouldRedirectToDetail: true,
              loading: false,
              redirectSuccessMessage: this.props.intl.formatMessage({
                  id: 'PROPERTIES.PROPERTY_SUCCESSFULLY_UPDATED_MESSAGE',
              }),
          });
      } catch (e) {
          if (e.response && e.response.status === 422) {
              const errorMessage = Object.values(
                  e.response.data.messages,
              ).join('\n');

              this.setState({
                  loading: false,
                  errorMessage,
              });
          } else {
              this.setState({
                  loading: false,
                  errorMessage: this.props.intl.formatMessage({
                      id: 'ERROR_PAGES.STORING_DATA',
                  }),
              });
          }
      }
  };

  handleDeleteDetail = async() => {
      this.setState({loading: true});

      try {
          await API.properties.delete(this.props.match.params.id);

          this.setState({
              loading: false,
              shouldRedirectToIndex: true,
              redirectSuccessMessage: this.props.intl.formatMessage({
                  id: 'PROPERTIES.PROPERTY_SUCCESSFULLY_DELETED_MESSAGE',
              }),
          });
      } catch (e) {
          this.setState({
              loading: false,
              errorMessage: this.props.intl.formatMessage({
                  id: 'ERROR_PAGES.DELETING_DATA',
              }),
          });
      }
  };

  loadProducers = async () => {
      const producers = await API.producers.all();
      this.setState({
          producers: producers,
      });
  };

  loadSoilTypes = async () => {
      const soilTypes = await API.properties.soilTypes();
      this.setState({
          soilTypes: soilTypes,
      });
  };

  loadWaterTypes = async () => {
      const waterTypes = await API.properties.waterTypes();
      this.setState({
          waterTypes: waterTypes,
      });
  };

  loadCertificateTypes = async () => {
      const certificateTypes = await API.properties.certificateTypes();
      this.setState({
          certificateTypes: certificateTypes,
      });
  };

  loadVarieties = async () => {
    const varieties = await API.varieties.all();
    this.setState({
      varieties
    });
  };

  addDetail = () => {
    const details = this.state.sectors;
    const variety = this.state.selectedVariety;
    const alias = this.state.alias;
    const exist = details.find(item => {
      if (item.alias === alias) {
        return true;
      } else {
        return false
      }
    });

    const detail = this.state.varieties.find((item) => {
      if (item.id === variety) {
        return item;
      } else {
        return null;
      }
    });

    if (!exist && detail && this.state.alias && this.state.surface) {
      detail.alias = this.state.alias;
      detail.surface = this.state.surface;
      details.push(detail);

      this.setState({
        sectors: details,
        selectedVariety: 0,
        surface: '',
        alias: '',
      });
    }
  }

  deleteDetail = async(index) => {
    const details = this.state.sectors;
    details.splice(index,1);
    this.setState({
        deliveryVarieties: details,
    })
  };

  changeDetail = async(detail, type) => {
    switch (type) {
      default: 
        break;
      case 'variety':
        this.setState({
          selectedVariety: parseInt(detail),
        });
        break;
      case 'surface':
        this.setState({
          surface: parseInt(detail),
        });
        break;
      case "alias":
        this.setState({
          alias: detail,
        });
        break;
    }
  }

  render() {
    const initialValues =  {
        ...this.state.detail,
        soilType: this.state.detail.soilType.id,
        producer: this.state.detail.producer.id,
        propertyType: this.state.detail.propertyType.toLocaleLowerCase(),
        waterType: this.state.detail.waterType.id,
        certificateType: this.state.detail.certificateType?.id || '',
        hasCertificate: this.state.detail.hasCertificate
    }
    return this.state.shouldRedirectToDetail ? (
      <Redirect
        data-test='redirectToDetail'
        to={{
          pathname: `/properties/${this.props.match.params.id}`,
          state: {successMessage: this.state.redirectSuccessMessage},
        }}
      />
    ) : this.state.shouldRedirectToIndex ? (
      <Redirect
        data-test='redirectToIndex'
        to={{
          pathname: '/properties',
          state: {
            successMessage: this.state.redirectSuccessMessage,
          },
        }}
      />
    ) : (
      <>
        <Alert
          variant='danger'
          className='mb-4'
          show={!!this.state.errorMessage}
        >
          <p className='mb-0'>{this.state.errorMessage}</p>
        </Alert>

        <Form
          data-test='editPropertyComponent'
          initialValues={initialValues}
          backRedirectUrl='/properties'
          backText={this.props.intl.formatMessage({
            id: 'LIST.BACK_TO_LIST',
          })}
          loading={this.state.loading}
          handleSubmit={this.onSubmit}
          producers={this.state.producers}
          propertyTypes={this.state.propertyTypes}
          soilTypes={this.state.soilTypes}
          waterTypes={this.state.waterTypes}
          certificateTypes={this.state.certificateTypes}
          onMapClick={this.onClickOverMap}
          sectors={this.state.sectors}
          varieties={this.state.varieties}
          deleteDetail={this.deleteDetail}
          addDetail={this.addDetail}
          selectedVariety={this.state.selectedVariety}
          alias={this.state.alias}
          surface={this.state.surface}
          changeDetail={this.changeDetail}
        />

        <Modal
          show={this.state.isLoadErrorModalOpen}
          onHide={this.toggleLoadErrorModal}
        >
          <Modal.Header>
            <Modal.Title>
              {this.props.intl.formatMessage({
                id: 'USERS.ERROR_MODAL_TITLE',
              })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.intl.formatMessage({
              id: 'USERS.ERROR_MODAL_TEXT',
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              className='btn-bold btn-light-dark'
              href={`/properties/${this.props.match.params.id}`}
            >
              {this.props.intl.formatMessage({
                id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
              })}
            </Button>
            <LoadingButton
              variant='primary'
              className='btn-bold'
              loading={this.state.loading}
              onClick={this.fetchUser}
            >
              {this.props.intl.formatMessage({
                id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
              })}
            </LoadingButton>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
}

export default injectIntl(EditProperty);
