import general from './general/es';
import starterList from './starter_list/es';
import roles from './roles/es';
import users from './users/es';
import routes from './routes/es';
import plants from './plants/es';
import varieties from './varieties/es';
import states from './states/es';
import municipalities from './municipalities/es';
import plagues from './plagues/es';
import vivariums from './vivariums/es';
import banks from './banks/es.json';
import licensees from './licensees/es.json';
import deliveries from './deliveries/es.json';
import productions from './productions/es.json';
import properties from './properties/es.json';
import producers from './producers/es.json';
import wt from './waterTypes/es.json';
import st from './soilTypes/es.json';
import ct from './certificateTypes/es.json';

import reports from './reports/es.json';

export default {
  ...general,
  ...starterList,
  ...roles,
  ...users,
  ...routes,
  ...plants,
  ...varieties,
  ...states,
  ...municipalities,
  ...plagues,
  ...vivariums,
  ...banks,
  ...licensees,
  ...deliveries,
  ...productions,
  ...properties,
  ...producers,
  ...reports,
  ...wt,
  ...st,
  ...ct,
};
