import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import API from '../../common/utils/API';
import Form from '../../components/form/properties/PropertyForm';
import {irrigationSystemType} from 'components/form/properties/Types';
export class CreateProperty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      shouldRedirectToIndex: false,
      errorMessage: '',
      producers: [],
      soilTypes: [],
      waterTypes: [],
      propertyTypes: [
        {id: 'owned', name: 'OWNED'},
        {id: 'rented', name: 'RENTED'},
      ],
      varieties: [],
      certificateTypes: [],
      sectors: [],
      selectedVariety: 0,
      surface: 0,
      alias: ''
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.loadProducers(),
      this.loadSoilTypes(),
      this.loadWaterTypes(),
      this.loadCertificateTypes(),
      this.loadVarieties(),
    ]);
  }

  onSubmit = async (input) => {
    if (this.state.sectors.length === 0) {
      this.setState({
          loading: false,
          errorMessage: this.props.intl.formatMessage({
              id: 'PROPERTIES.VALIDATION.NO_SECTORS',
          }),
      });
      return;
    }

    try {
      const send = {
        ...input,
        sectors: this.state.sectors.map(item => ({
          variety: item.id,
          name: item.alias,
          surface: item.surface,
        })),
      };

      await API.properties.save(send);
      this.setState({
        loading: false,
        shouldRedirectToIndex: true,
      });
    } catch (e) {
      if (e.response && e.response.status === 422) {
        const errorMessage = Object.values(e.response.data.messages).join('\n');

        this.setState({
          loading: false,
          errorMessage,
        });
      } else {
        this.setState({
          loading: false,
          errorMessage: this.props.intl.formatMessage({
            id: 'ERROR_PAGES.STORING_DATA',
          }),
        });
      }
    }
  };

  loadProducers = async () => {
    const producers = await API.producers.all();
    this.setState({
      producers: producers,
    });
  };

  loadSoilTypes = async () => {
    const soilTypes = await API.properties.soilTypes();
    this.setState({
      soilTypes: soilTypes,
    });
  };

  loadWaterTypes = async () => {
    const waterTypes = await API.properties.waterTypes();
    this.setState({
      waterTypes: waterTypes,
    });
  };

  loadVarieties = async () => {
    const varieties = await API.varieties.all();
    this.setState({
      varieties
    });
  }

  loadCertificateTypes = async () => {
    const certificateTypes = await API.properties.certificateTypes();
    this.setState({
      certificateTypes: certificateTypes,
    });
  };

  onClickOverMap = (event) => {
    console.log(event);
  };

  addDetail = () => {
    const details = this.state.sectors;
    const variety = this.state.selectedVariety;
    const alias = this.state.alias;
    const exist = details.find(item => {
      if (item.alias === alias) {
        return true;
      } else {
        return false
      }
    });

    const detail = this.state.varieties.find((item) => {
      if (item.id === variety) {
        return item;
      } else {
        return null;
      }
    });

    if (!exist && detail && this.state.alias && this.state.surface) {
      detail.alias = this.state.alias;
      detail.surface = this.state.surface;
      details.push(detail);

      this.setState({
        sectors: details,
        selectedVariety: 0,
        surface: '',
        alias: '',
      });
    }
  }

  deleteDetail = async(index) => {
    const details = this.state.sectors;
    details.splice(index,1);
    this.setState({
        deliveryVarieties: details,
    })
  };

  changeDetail = async(detail, type) => {
    switch (type) {
      default: 
        break;
      case 'variety':
        this.setState({
          selectedVariety: parseInt(detail),
        });
        break;
      case 'surface':
        this.setState({
          surface: parseInt(detail),
        });
        break;
      case "alias":
        this.setState({
          alias: detail,
        });
        break;
    }
  }

  render() {
    const initialValues = {
      isCertificated: true,
      irrigationSystemType: irrigationSystemType.HYDROPONICS,
      lavatories: false,
      sinks: false,
      diningRooms: false,
      trashCans: false,
      signs: false,
      recycleCans: false,
      wasteAreas: false,
      incomePolicies: false,
      hasWaterHole: false,
    };
    return this.state.shouldRedirectToIndex ? (
      <Redirect
        data-test='redirectToIndex'
        to={{
          pathname: '/properties',
          state: {
            successMessage: this.props.intl.formatMessage({
              id: 'PROPERTIES.PROPERTY_SUCCESSFULLY_CREATED_MESSAGE',
            }),
          },
        }}
      />
    ) : (
      <>
        <Alert
          variant='danger'
          className='mb-4'
          show={!!this.state.errorMessage}
        >
          <p className='mb-0'>{this.state.errorMessage}</p>
        </Alert>
        <Form
          data-test='createPropertyComponent'
          initialValues={initialValues}
          backRedirectUrl='/properties'
          backText={this.props.intl.formatMessage({
            id: 'LIST.BACK_TO_LIST',
          })}
          loading={this.state.loading}
          handleSubmit={this.onSubmit}
          producers={this.state.producers}
          propertyTypes={this.state.propertyTypes}
          soilTypes={this.state.soilTypes}
          waterTypes={this.state.waterTypes}
          certificateTypes={this.state.certificateTypes}
          onMapClick={this.onClickOverMap}
          sectors={this.state.sectors}
          varieties={this.state.varieties}
          deleteDetail={this.deleteDetail}
          addDetail={this.addDetail}
          selectedVariety={this.state.selectedVariety}
          alias={this.state.alias}
          surface={this.state.surface}
          changeDetail={this.changeDetail}
        />
      </>
    );
  }
}

export default injectIntl(CreateProperty);
