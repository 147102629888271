import React, {useState, useEffect, useCallback, Fragment} from 'react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import LoadingButton from 'components/form/LoadingButton';
import Can from 'common/security/Can';

import API from 'common/utils/API';

export const CertificateTypeDetail = ({
  match: {
    params: {id},
  },
}) => {
  const [certificateType, setCertificateType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadErrorModal, setLoadErrorModal] = useState(false);
  const intl = useIntl();

  const fetchDetail = useCallback(async () => {
    setLoading(true);

    try {
      const certificateType = await API.certificateTypes.detail(id);

      setCertificateType(certificateType);
      setLoadErrorModal(false);
    } catch (error) {
      setLoadErrorModal(true);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const onHideModal = useCallback(() => setLoadErrorModal(false), []);

  useEffect(() => {
    fetchDetail();
  }, [fetchDetail]);

  if (loading || !certificateType)
    return (
      <Modal show={loadErrorModal} onHide={onHideModal}>
        <Modal.Header>
          <Modal.Title>
            {intl.formatMessage({
              id: 'CERTIFICATETYPES.ERROR_MODAL_TITLE',
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {intl.formatMessage({
            id: 'CERTIFICATETYPES.ERROR_MODAL_TEXT',
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            className='btn-bold btn-light-dark'
            as={NavLink}
            to={'/certificate-types'}
          >
            {intl.formatMessage({
              id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
            })}
          </Button>
          <LoadingButton
            variant='primary'
            className='btn-bold'
            loading={loading}
            onClick={fetchDetail}
          >
            {intl.formatMessage({
              id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
            })}
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    );

  return (
    <Fragment>
      <div className='mb-4 d-flex justify-content-end'>
        <Button
          as={NavLink}
          to='/certificate-types'
          className='btn btn-dark btn-bold btn-light-dark'
        >
          {intl.formatMessage({
            id: 'LIST.BACK_TO_LIST',
          })}
        </Button>
      </div>

      <Card className='mb-4'>
        <Card.Header>
          {intl.formatMessage({
            id: 'REPORTS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
          })}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col className='text-center' md={3}>
              <label className='field-title'>#</label>
              <p>{certificateType.id}</p>
            </Col>
            <Col className='text-center' md={3}>
              <label className='field-title'>Nombre</label>
              <p>{certificateType.name}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className='d-flex justify-content-between'>
        <div />
        <div>
          <Can run='CERTIFICATETYPES_EDIT'>
            <Button
              as={NavLink}
              to={`/certificate-types/${id}/edit`}
              className='btn-warning btn-bold ml-2'
            >
              {intl.formatMessage({
                id: 'CERTIFICATETYPES.EDIT_BUTTON_TEXT',
              })}
            </Button>
          </Can>
        </div>
      </div>
    </Fragment>
  );
};

export default CertificateTypeDetail;
