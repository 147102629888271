import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import { FaTrashAlt } from 'react-icons/fa';
import Can from '../../../common/security/Can';

import BaseForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Forms from 'react-bootstrap/Form';

import Input from '../Input';
import LoadingButton from '../LoadingButton';
import ConfirmModal from '../ConfirmModal';
import Datepicker from '../DatePicker';

import { formatISODate } from '../../../common/utils/helpers';

export default function Form(props) {
    const intl = useIntl();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const validationSchema = Yup.object({
        licensee: Yup.number().required(
            intl.formatMessage({id: 'DELIVERIES.VALIDATION.LICENSEE_MISSING'}),
        ),
        vivarium: Yup.number().required(
            intl.formatMessage({id: 'DELIVERIES.VALIDATION.VIVARIUM_MISSING'}),
        ),
        estimatedDeliveryDate: Yup.date().required(
            intl.formatMessage({id: 'DELIVERIES.VALIDATION.ESTIMATED_DELIVERY_DATE_MISSING'}),
        )
    });
    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const addDetail = () => {
        props.addDetail();
    }

    const handleToggleIsDeleteModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const handleConfirmDelete = () => {
        props.handleDeleteItem();
        handleToggleIsDeleteModal();
    }

    const changeVariety = (event) =>{
        props.changeDetail(event.target.value, 'variety');
    }
    
    const changeQuantity = (event) => {
        props.changeDetail(event.target.value, 'quantity');
    }
    return (
        <Formik
            data-test='plantFormComponent'
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors, setFieldValue}) => (
                <>
                    <div className='mb-4 d-flex justify-content-end'>
                        <Button
                            data-test='backButton'
                            as={NavLink}
                            to={props.backRedirectUrl}
                            className='btn btn-dark btn-bold btn-light-dark'
                        >
                            {props.backText}
                        </Button>
                    </div>

                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id: 'DELIVERIES.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                                })}
                            </Card.Header>

                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md={4}>
                                        <p>
                                            {intl.formatMessage({
                                                id: 'DELIVERIES.LICENSEE_LABEL',
                                            })}
                                        </p>
                                        <Forms.Control as='select'
                                            name="licensee"
                                            onChange={handleChange}
                                            error={errors.licensee}
                                            isInvalid={errors.licensee}
                                            required
                                            value={values.licensee}
                                        >
                                            <option value=''>
                                                {intl.formatMessage({id:'DELIVERIES.LICENSEE_SELECT_PLACEHOLDER'})}
                                            </option>
                                            {props.licensees.map((licensee) => (
                                                <option value={licensee.id} key={licensee.id}>
                                                    {licensee.firstName} {licensee.lastName}
                                                </option>
                                            ))}
                                        </Forms.Control>
                                        <small show={errors.licensee} className="text-danger form-text">
                                            {errors.licensee}
                                        </small>
                                    </Col>
                                    <Col md={4}>
                                        <p>{intl.formatMessage({
                                                id: 'DELIVERIES.VIVARIUM_LABEL',
                                            })}
                                        </p>
                                        <Forms.Control as='select'
                                            name="vivarium"
                                            onChange={handleChange}
                                            error={errors.vivarium}
                                            isInvalid={errors.vivarium}
                                            required
                                            value={values.vivarium}
                                        >
                                            <option value=''>
                                                {intl.formatMessage({id:'DELIVERIES.VIVARIUM_SELECT_PLACEHOLDER'})}
                                            </option>
                                            {props.vivariums.map((viv) => (
                                                <option value={viv.id} key={viv.id}>
                                                    {viv.name}
                                                </option>
                                            ))}
                                        </Forms.Control>
                                        <small show={errors.vivarium} className="text-danger form-text">
                                            {errors.vivarium}
                                        </small>
                                    </Col>
                                    <Col md={4}>
                                        <p>
                                            {intl.formatMessage({
                                                id: 'DELIVERIES.ESTIMATED_DELIVERY_DATE_LABEL',
                                            })}
                                        </p>
                                        <Datepicker
                                            error={errors.estimatedDeliveryDate}
                                            isInvalid={errors.estimatedDeliveryDate}
                                            required
                                            data-test='testDate'
                                            placeholder={intl.formatMessage({id:'DELIVERIES.ESTIMATED_DATE_PLACEHOLDER'})}
                                            value={values.estimatedDeliveryDate}
                                            name='estimatedDeliveryDate'
                                            onChange={(val)=> {
                                                setFieldValue('estimatedDeliveryDate', 
                                                formatISODate(val))
                                            }}
                                        >
                                        </Datepicker>
                                        <small show={errors.estimatedDeliveryDate} className="text-danger form-text">
                                            {errors.estimatedDeliveryDate}
                                        </small>
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>

                            <Card.Header>
                                {intl.formatMessage({
                                    id: 'DELIVERIES.VARIETIES_INFORMATION_FORM_SECTION_TITLE',
                                })}
                            </Card.Header>

                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md={4}>
                                        <p>
                                            {intl.formatMessage({
                                                id: 'DELIVERIES.VARIETY_LABEL',
                                            })}
                                        </p>
                                        <Forms.Control as='select'
                                            name="variety"
                                            onChange={changeVariety}
                                            value={props.selectedVariety}
                                        >
                                            <option value=''>
                                                {intl.formatMessage({id:'DELIVERIES.VARIETY_SELECT_PLACEHOLDER'})}
                                            </option>
                                            {props.varieties.map((variety) => (
                                                <option value={variety.id} key={variety.id}>
                                                    {variety.plant} - {variety.name}
                                                </option>
                                            ))}
                                        </Forms.Control>
                                    </Col>
                                    <Col md={4}>
                                        <div className='col'>
                                            <p>{intl.formatMessage({
                                                    id: 'DELIVERIES.QUANTITY_FIELD_LABEL',
                                                })}
                                            </p>
                                        </div>
                                        <Input type='number'
                                            name='quantity'
                                            placeholder={intl.formatMessage({
                                                id: 'DELIVERIES.QUANTITY_FIELD_PLACEHOLDER'
                                            })}
                                            value={props.selectedQuantity}
                                            onChange={changeQuantity}
                                        />
                                    </Col>
                                    <Col md={4}><br/>
                                        <LoadingButton onClick={addDetail}>
                                            Agregar
                                        </LoadingButton>
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                            
                            <Card.Body>
                                <table className='starter-list table table-bordered table-striped'>
                                    <thead>
                                        <tr>
                                            <th>
                                                {intl.formatMessage({id:'DELIVERIES.VARIETIES_TABLE_PLANT_LABEL'})}
                                            </th>
                                            <th>
                                                {intl.formatMessage({id:'DELIVERIES.VARIETIES_TABLE_VARIETY_LABEL'})}
                                            </th>
                                            <th>
                                                {intl.formatMessage({id:'DELIVERIES.VARIETIES_TABLE_QUANTITY_LABEL'})}
                                            </th>
                                            <th>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        props.deliveryVarieties.map((item) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>
                                                        {item.plant}
                                                    </td>
                                                    <td>
                                                        {item.name}
                                                    </td>
                                                    <td>
                                                        {item.quantity}
                                                    </td>
                                                    <td>
                                                        <Button onClick={() => props.deleteDetail(item.id)}>
                                                            <FaTrashAlt></FaTrashAlt>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </Card.Body>
                        </Card>
                    </BaseForm>

                    <div className='d-flex justify-content-between'>
                        {props.isEdit ? (
                            <div>
                                <div>
                                    <Can run='DELIVERIES_DELETE' cantRun={<div />}>
                                        <ConfirmModal
                                            titleModal={intl.formatMessage({
                                                id:
                                                    'DELIVERIES.DELIVERY_MODAL_TITLE',
                                            })}

                                            handleConfirm = {
                                                handleConfirmDelete
                                            }
                                            handleShow={
                                                handleToggleIsDeleteModal
                                            }
                                            handleClose={
                                                handleToggleIsDeleteModal
                                            }
                                            show={isDeleteModalOpen}
                                            variant='danger'
                                            buttonClassName='btn-bold'
                                            buttonLabel={intl.formatMessage(
                                                {id: 'DELIVERIES.DELETE_DELIVERY_BUTTON_LABEL'}
                                            )}
                                            buttonAcceptLabel={intl.formatMessage(
                                                { id:'DELIVERIES.CONFIRM_DELETE_BUTTON_LABEL'},
                                            )}
                                            buttonCloseLabel={intl.formatMessage(
                                                { id: 'USERS.CANCEL_DELETE_BUTTON_LABEL'},
                                            )}
                                        >
                                            <p>
                                                {intl.formatMessage({
                                                    id:
                                                        'DELIVERIES.DELETE_DELIVERY_CONFIRMATION_MESSAGE',
                                                })}
                                            </p>
                                        </ConfirmModal>
                                    </Can>
                                </div>
                            </div>
                        ) : (
                            props.isEdit ? (<div>
                                {intl.formatMessage({id:'DELIVERIES.STATE_CANT_BE_DELETED'})}
                            </div>): <div/>
                        )}
                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'GENERAL.SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
