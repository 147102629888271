import React, {useState, useEffect, useCallback, Fragment} from 'react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import {useSelector} from 'react-redux';

import LoadingButton from 'components/form/LoadingButton';

import API from 'common/utils/API';

export const ReportDetail = ({
  match: {
    params: {id},
  },
}) => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadErrorModal, setLoadErrorModal] = useState(false);
  const {lang} = useSelector((state) => state.langReducer);
  const intl = useIntl();

  const fetchDetail = useCallback(async () => {
    setLoading(true);

    try {
      const report = await API.reports.detail(id);
      setReport(report);
      setLoadErrorModal(false);
    } catch (error) {
      setLoadErrorModal(true);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const onHideModal = useCallback(() => setLoadErrorModal(false), []);

  useEffect(() => {
    fetchDetail();
  }, [fetchDetail]);

  if (loading || !report)
    return (
      <Modal show={loadErrorModal} onHide={onHideModal}>
        <Modal.Header>
          <Modal.Title>
            {intl.formatMessage({
              id: 'REPORTS.ERROR_MODAL_TITLE',
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {intl.formatMessage({
            id: 'REPORTS.ERROR_MODAL_TEXT',
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            className='btn-bold btn-light-dark'
            as={NavLink}
            to={'/reports'}
          >
            {intl.formatMessage({
              id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
            })}
          </Button>
          <LoadingButton
            variant='primary'
            className='btn-bold'
            loading={loading}
            onClick={fetchDetail}
          >
            {intl.formatMessage({
              id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
            })}
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    );

  return (
    <Fragment>
      <div className='mb-4 d-flex justify-content-end'>
        <Button
          as={NavLink}
          to='/reports'
          className='btn btn-dark btn-bold btn-light-dark'
        >
          {intl.formatMessage({
            id: 'LIST.BACK_TO_LIST',
          })}
        </Button>
      </div>

      <Card className='mb-4'>
        <Card.Header>
          {intl.formatMessage({
            id: 'REPORTS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
          })}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col className='text-center' md={3}>
              <label className='field-title'>#</label>
              <p>{report.id}</p>
            </Col>
            <Col className='text-center' md={3}>
              <label className='field-title'>Folio</label>
              <p>{report.folio}</p>
            </Col>
            <Col className='text-center' md={3}>
              <label className='field-title'>
                {intl.formatMessage({
                  id: 'REPORTS.LICENSEE_FIELD_LABEL',
                })}
              </label>
              <p>{report.production.licensee}</p>
            </Col>
            <Col className='text-center' md={3}>
              <label className='field-title'>
                {intl.formatMessage({
                  id: 'REPORTS.PRODUCER_FIELD_LABEL',
                })}
              </label>
              <p>
                {report.producer.firstName}
                {report.producer.lastName ? ` ${report.producer.lastName}` : ''}
              </p>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col className='text-center' md={3}>
              <label className='field-title'>
                {intl.formatMessage({
                  id: 'REPORTS.PROPERTY_FIELD_LABEL',
                })}
              </label>
              <p>{report.property.name}</p>
            </Col>
            <Col className='text-center' md={3}>
              <label className='field-title'>
                {intl.formatMessage({
                  id: 'REPORTS.SURFACE_FIELD_LABEL',
                })}
              </label>
              <p>{report.production.hectares}</p>
            </Col>
            <Col className='text-center' md={3}>
              <label className='field-title'>
                {intl.formatMessage({
                  id: 'REPORTS.PLANTATION_DATE_FIELD_LABEL',
                })}
              </label>
              <p>
                {new Date(report.production.plantationDate).toLocaleDateString(
                  lang,
                  {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  },
                )}
              </p>
            </Col>
            <Col className='text-center' md={3}>
              <label className='field-title'>
                {intl.formatMessage({
                  id: 'REPORTS.VARIETY_FIELD_LABEL',
                })}
              </label>
              <p>{report.production.variety}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className='mb-4'>
        <Card.Header>
          {intl.formatMessage({
            id: 'REPORTS.PRODUCTION_ASPECT_FORM_SECTION_TITLE',
          })}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col className='text-center' md={3}>
              <label className='field-title'>
                {intl.formatMessage({
                  id: 'REPORTS.QUALITY_FIELD_LABEL',
                })}
              </label>
              <p>
                {intl.formatMessage({
                  id: `REPORTS.QUALITY_${report.categoryQuality.toUpperCase()}`,
                })}
              </p>
            </Col>
            <Col className='text-center' md={3}>
              <label className='field-title'>
                {intl.formatMessage({
                  id: 'REPORTS.WEEK_FIELD_LABEL',
                })}
              </label>
              <p>{report.weekOfYear}</p>
            </Col>
            <Col className='text-center' md={3} offset-md={3}>
              <label className='field-title'>
                {intl.formatMessage({
                  id: 'REPORTS.FENOLOGIC_STATE_FIELD_LABEL',
                })}
              </label>
              <p>{report.fenologicState}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className='mb-4'>
        <Card.Header>
          <label className='field-title'>
            {intl.formatMessage({
              id: 'REPORTS.PHOTOS_SECTION_TITLE',
            })}
          </label>
        </Card.Header>
        <Card.Body>
          <Row className='mt-4'>
              {report.pictures.map(({url}, index) => (
                <Col className='mb-4' key={index} xs={1} sm={2} lg={4}>
                  <Image style={{width: '100%'}} src={url} />
                </Col>
              ))}
              <Col sm={12}>
                <label className='field-title'>
                  {intl.formatMessage({
                    id: 'REPORTS.PHOTO_COMMENTS_LABEL',
                  })}:
                </label>
            </Col>
              <Col sm={12}>{report.photosComments}</Col>
            </Row>
        </Card.Body>
      </Card>

      <Card className='mb-4'>
        <Card.Header>
          {intl.formatMessage({
            id: 'REPORTS.PEST_AND_DISEASE_MONITORING_FORM_SECTION_TITLE',
          })}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={12}>
              <Table striped borderless>
                <thead>
                  <tr>
                    <th>
                      <label className='field-title'>
                        {intl.formatMessage({
                          id: 'REPORTS.PLAGUE_TABLE_HEADER',
                        })}
                      </label>
                    </th>
                    <th>
                      <label className='field-title'>
                        {intl.formatMessage({
                          id: 'REPORTS.INFESTATION_TABLE_HEADER',
                        })}
                      </label>
                    </th>
                    <th>
                      <label className='field-title'>
                        {intl.formatMessage({
                          id: 'REPORTS.MUEASUREMENT_TABLE_HEADER',
                        })}
                      </label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.FRUIT_WORM`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.INFESTATION_${report.fruitWorm.toUpperCase()}`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.FRUIT_PROCESS`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.MITES`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.INFESTATION_${report.mites.toUpperCase()}`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.LEAF_UNDERSIDE`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Trips</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.INFESTATION_${report.trips.toUpperCase()}`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.FLOWERS_BY_HECTARE`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Mayate</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.INFESTATION_${report.mayate.toUpperCase()}`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.FLOWERS_BY_HECTARE`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.WHITE_FLY`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.INFESTATION_${report.whiteFly.toUpperCase()}`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.FRUIT_PROCESS`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Chicharrita</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.INFESTATION_${report.chicharrita.toUpperCase()}`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.SINGLES_BY_LEAF`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Pulgon</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.INFESTATION_${report.pulgon.toUpperCase()}`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.SINGLES_BY_LEAF`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.GRAY_MOLD`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.INFESTATION_${report.grayMold.toUpperCase()}`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.FRUIT_BY_HECTARE`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Antracnosis</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.INFESTATION_${report.antracnosis.toUpperCase()}`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.SINGLES_BY_LEAF`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Cenicilla</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.INFESTATION_${report.cenicilla.toUpperCase()}`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.GREEN_SPROUTS`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Prenospora</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.INFESTATION_${report.prenospora.toUpperCase()}`,
                      })}
                    </td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.SAMPLING_${report.prenosporaSampling.toUpperCase()}`,
                      })}
                    </td>
                  </tr>
                  {report.other ? (
                    <tr>
                      <td>{report.other}</td>
                      <td>
                        {intl.formatMessage({
                          id: `REPORTS.INFESTATION_${report.otherMeasurement.toUpperCase()}`,
                        })}
                      </td>
                      <td>
                        {intl.formatMessage({
                          id: `REPORTS.SAMPLING_${report.otherSampling.toUpperCase()}`,
                        })}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className='mb-4'>
        <Card.Header>
          {intl.formatMessage({
            id: 'REPORTS.NATURAL_ENEMIES_FORM_SECTION_TITLE',
          })}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={12}>
              <Table striped borderless>
                <thead>
                  <tr>
                    <th>
                      <label className='field-title'>
                        {intl.formatMessage({
                          id: 'REPORTS.PLAGUE_ENEMY_TABLE_HEADER',
                        })}
                      </label>
                    </th>
                    <th>
                      <label className='field-title'>
                        {intl.formatMessage({
                          id: 'REPORTS.PLAGUE_TO_DELETE_TABLE_HEADER',
                        })}
                      </label>
                    </th>
                    <th>
                      <label className='field-title'>
                        {intl.formatMessage({
                          id: 'REPORTS.INDIVIDUALS_TABLE_HEADER',
                        })}
                      </label>
                    </th>
                    <th>
                      <label className='field-title'>
                        {intl.formatMessage({
                          id: 'REPORTS.SAMPLING_TABLE_HEADER',
                        })}
                      </label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Encarisia Formosa</td>
                    <td>
                      {intl.formatMessage({
                        id: 'REPORTS.WHITE_FLY',
                      })}
                    </td>
                    <td>{report.encarisiaFormosa}</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.SINGLES_BY_LEAF`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Neoseiulis barkeri</td>
                    <td>Trips</td>
                    <td>{report.neoseiulisBarkery}</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.FLOWER_BUDS_AND_FLOWERS_BY_HECARE`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Amlyseius cucumeris</td>
                    <td>Trips</td>
                    <td>{report.amlyseiusCucumeris}</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.FLOWER_BUDS_AND_FLOWERS_BY_HECARE`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Chinche orius</td>
                    <td>Trips</td>
                    <td>{report.chincheOrius}</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.FLOWER_BUDS_AND_FLOWERS_BY_HECARE`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Amlyseius cucumeris</td>
                    <td>Trips</td>
                    <td>{report.amlyseiusCucumeris}</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.FLOWER_BUDS_AND_FLOWERS_BY_HECARE`,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Phytoseluis permisilis</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.RED_SPIDER`,
                      })}
                    </td>
                    <td>{report.phytoseluisPermisilis}</td>
                    <td>
                      {intl.formatMessage({
                        id: `REPORTS.LEAF_UNDERSIDE`,
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className='mb-4'>
        <Card.Header>
          {intl.formatMessage({
            id: 'REPORTS.TECHNICAL_RECOMENDATION_SECTION_TITLE',
          })}
        </Card.Header>
        <Card.Body>
          { report.recomendations.length ? (
            report.recomendations.map((recomendation, i) => (
              <Fragment key={i}>
                <Row className={i > 0 ? 'mt-5' : ''}>
                  <Col className='text-center' md={3}>
                    <label className='field-title'>
                      {intl.formatMessage({
                        id: 'REPORTS.COMERCIAL_NAME',
                      })}
                    </label>
                    <p>{recomendation.comercialName}</p>
                  </Col>
                  <Col className='text-center' md={3}>
                    <label className='field-title'>
                      {intl.formatMessage({
                        id: 'REPORTS.ACTIVE_INGREDIENT',
                      })}
                    </label>
                    <p>{recomendation.activeIngredient}</p>
                  </Col>
                  <Col className='text-center' md={3}>
                    <label className='field-title'>
                      {intl.formatMessage({
                        id: 'REPORTS.CLASS',
                      })}
                    </label>
                    <p>
                      {intl.formatMessage({
                        id: `REPORTS.PRODUCT_CLASS_${recomendation.productClasss.toUpperCase()}`,
                      })}
                    </p>
                  </Col>
                  <Col className='text-center' md={3}>
                    <label className='field-title'>
                      {intl.formatMessage({
                        id: 'REPORTS.SECURITY_INTERVAL',
                      })}
                    </label>
                    <p>{recomendation.securityInterval}</p>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col className='text-center' md={3}>
                    <label className='field-title'>
                      {intl.formatMessage({
                        id: 'REPORTS.DOSIS_BY',
                      })}
                    </label>
                    <p>
                      {recomendation.dosisBy}{' '}
                      {intl.formatMessage({
                        id: `REPORTS.UNIT_MEASUREMENT_${recomendation.unitOfMeasurement.toUpperCase()}`,
                      })}{' '}
                      {intl.formatMessage({
                        id: 'REPORTS.OF_WATER_IN',
                      })}{' '}
                      {recomendation.disolveInLiters} lt{' '}
                      {intl.formatMessage({
                        id: 'REPORTS.OF_WATER',
                      })}
                    </p>
                  </Col>
                  <Col className='text-center' md={3}>
                    <label className='field-title'>
                      {intl.formatMessage({
                        id: 'REPORTS.DOSIS_BY_HECTARE',
                      })}
                    </label>
                    <p>
                      {recomendation.dosisByHectare}{' '}
                      {intl.formatMessage({
                        id: `REPORTS.UNIT_MEASUREMENT_${recomendation.unitOfMeasurementHectare.toUpperCase()}`,
                      })}{' '}
                      x{' '}
                      {intl.formatMessage({
                        id: 'REPORTS.HECTARE',
                      })}
                    </p>
                  </Col>
                </Row>
              </Fragment>
            )))
            :
            <p>{intl.formatMessage({id: 'REPORTS.NO_RECOMENDATIONS_PLACEHOLDER'})}</p>
          }
        </Card.Body>
      </Card>

      <Card className='mb-4'>
        <Card.Header>
          {intl.formatMessage({
            id: 'REPORTS.SIGNATURES_SECTION_TITLE',
          })}
        </Card.Header>
        <Card.Body>
          <Row>
            <Col className='text-center' sm={12} md={4}>
              <label className='field-title'>{report.witness}</label>

              <Image
                style={{width: '100%'}}
                src={`data:image/png;base64,${report.signature2}`}
              />
            </Col>
            <Col className='text-center' sm={12} md={4}>
              <label className='field-title'>{report.createdBy.trim()}</label>
              <Image
                style={{width: '100%'}}
                src={`data:image/png;base64,${report.signature}`}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default ReportDetail;
