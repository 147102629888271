import React, {useState, useEffect, useCallback} from 'react';
import Alert from 'react-bootstrap/Alert';
import {useIntl} from 'react-intl';

import StarterList from 'components/starter/StarterList';

import API from 'common/utils/API';

export const CertificateTypesList = () => {
  const [certificateTypes, setCertificateTypes] = useState([]);
  const [pageConfig, setPageConfig] = useState({
    page: 1,
    pageSize: 12,
    pageCount: 2,
    total: 0,
  });
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [error, setError] = useState(false);
  const intl = useIntl();

  const clearErrorMessage = () => {
    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  const fetchData = useCallback(async (pageNumber = 1, filters = {}) => {
    try {
      const result = await API.certificateTypes.get(pageNumber, filters);

      setCertificateTypes(result.items);
      setPageConfig({
        page: result.page,
        pageSize: result.pageSize,
        pageCount: result.pageCount,
        total: result.total,
      });
      setError(false);
    } catch (error) {
      clearErrorMessage();
      setError(true);
    }
  }, []);

  const handleSearchChange = useCallback(
    ({target: {value}}) => {
      const filters = {
        keyword: value,
      };

      if (searchTimeout > 0) {
        clearTimeout(searchTimeout);
      }

      setKeyword(value);

      setSearchTimeout(
        setTimeout(() => {
          fetchData(1, filters);
        }, 300),
      );
    },
    [fetchData, searchTimeout],
  );

  const handleSearchClear = useCallback(() => {
    setKeyword('');

    fetchData(1);
  }, [fetchData]);

  const handlePageChange = useCallback(
    (pageNumber) => {
      fetchData(pageNumber);
    },
    [fetchData],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div data-test='certificateTypesListComponent'>
      <Alert
        data-test='errorAlert'
        variant='danger'
        className='mb-4'
        show={error}
      >
        <p className='mb-0'>
          {intl.formatMessage({
            id: 'ERROR_PAGES.LOADING_DATA',
          })}
        </p>
      </Alert>
      <StarterList
        onSearchKeywordClear={handleSearchClear}
        onSearchKeywordChange={handleSearchChange}
        searchKeyword={keyword}
        onPageChange={handlePageChange}
        page={pageConfig.page}
        pageSize={pageConfig.pageSize}
        total={pageConfig.total}
        addNewPermission='CERTIFICATETYPES_CREATE'
        addNewLink='certificate-types/create'
        data={{
          headerTitles: [
            '#',
            intl.formatMessage({
              id: 'CERTIFICATETYPES.NAME_TABLE_HEADER',
            }),
          ],
          rows: certificateTypes.map((certificateType) => ({
            content: [certificateType.id, certificateType.name],
            link: `/certificate-types/${certificateType.id}`,
          })),
        }}
      />
    </div>
  );
};

export default CertificateTypesList;
