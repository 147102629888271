export default class Bank {
    constructor({
        id = 0,
        name = '',
        code = '',
    } = {}) {
        this.id = id;
        this.name = name;
        this.code = code;
    }
}