export default class Variety {
    constructor({
        id = 0,
        plant='',
        plantId ='',
        name = ''
    } = {}) {
        this.id = id;
        this.plant = plant;
        this.plantId = plantId;
        this.name = name;
    }
}