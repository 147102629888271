import {API} from 'common/utils/API';
import PaginatedList from 'common/utils/PaginatedList';
import Report from 'common/models/Report';

const ReportsApiService = {
  get: async (pageNumber = 1, filters = {}) => {
    let url = `/fieldvisits/?page=${pageNumber}`;

    if (filters.from) {
      url += `&from=${filters.from}`;
    }

    if (filters.to) {
      url += `&to=${filters.to}`;
    }

    if (filters.variety) {
      url += `&variety=${filters.variety}`;
    }

    if (filters.user) {
      const userType = filters.user.substring(filters.user.indexOf('-') + 1);

      filters.user = filters.user.replace(`-${userType}`, '');

      url += `&${userType}=${filters.user}`;
    }

    const {data: response} = await API.get(url);

    const list = new PaginatedList();
    list.items = response.data.map((report) => new Report(report));
    list.page = response.meta.page;
    list.pageCount = response.meta.lastPage;
    list.pageSize = response.meta.perPage;
    list.total = response.meta.total;
    return list;
  },

  detail: async (id) => {
    const {data: response} = await API.get(`/fieldvisits/${id}`);
    const report = new Report(response.data);
    return report;
  },
};

export default ReportsApiService;
