import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import PropertiesList from './PropertiesList';
import PropertyDetail from './PropertyDetail';
import CreateProperty from './CreateProperty';
import EditProperty from './EditProperty';

function PropertiesRoutes() {
    const intl = useIntl();

    return (
        <Switch data-test='productionsComponent'>
            <PrivateRoute  permission='PROPERTIES_INDEX'
                exact
                path='/properties'
                component={withLayout(
                    PropertiesList,
                    intl.formatMessage({id: 'ROUTES.PROPERTIES.INDEX'}),
                )}
            />
            <PrivateRoute permission='PROPERTIES_CREATE'
                exact
                path='/properties/create'
                component={withLayout(
                    CreateProperty,
                    intl.formatMessage({id: 'ROUTES.PROPERTIES.CREATE'}),
                )}
            />
            <PrivateRoute permission='PROPERTIES_SHOW'
                exact
                path='/properties/:id'
                component={withLayout(
                    PropertyDetail,
                    intl.formatMessage({id: 'ROUTES.PROPERTIES.SHOW'}),
                )}
            />
            <PrivateRoute permission="PROPERTIES_EDIT"
                exact
                path='/properties/:id/edit'
                component={withLayout(
                    EditProperty,
                    intl.formatMessage({id: 'ROUTES.PROPERTIES.EDIT'}),
                )}
            />
        </Switch>
    );
}

export default PropertiesRoutes;
