export const fiscalType ={
    FISICA: 'fisica',
    MORAL:'moral'
}

export const producerType = {
    NEW: 'new',
    SETTLED_DOWN: "settled_down"
}

export const producerStatus = {
    APPROVED: 'approved',
    CONDITIONED_APPROVED: 'conditioned_approved',
    PENDING: 'pending',
    REJECTED: 'rejected'
}