export const propertyType = {
    'OWNED': 'owned',
    'RENTED': 'rented'
}

export const propertyStatus = {
    'PENDING': 'pending',
    'APPROVED': 'approved',
    'REJECTED': 'rejected',
}

export const irrigationSystemType = {
    'CONVENTIONAL': 'conventional',
    'HYDROPONICS': 'hydroponics',
    'SUBSTRATUM': 'substratum'
}

