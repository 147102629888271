import React from 'react';
import { injectIntl } from 'react-intl';
import Alert  from 'react-bootstrap/Alert';
import StarterList from '../../components/starter/StarterList';
import API from '../../common/utils/API'

export class ProductionsList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            productions: [],
            page: 1,
            pageSize: 12,
            pageCount: 2,
            total: 0,
            searchTimeout: 0,
            search: '',
            error: false,
        }
    }

    async componentDidMount() {
        await Promise.all([this.fetchData()]);
    }

    fetchData = async (pageNumber = 1) => {
        try {
            const result = await API.productions.get(
                pageNumber,
                this.state.search,
                this.state.plant,
            );
            this.setState({
                productions: result.items,
                page: result.page,
                pageSize: result.pageSize,
                pageCount: result.pageCount,
                total: result.total,
                error: false,
            });
        } catch (error) {
            this.setState({ error: true },
                this.clearErroMessage,
            );
        }
    }

    handleSearchKeywordChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchData(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchData(1);
            },
        );
    };

    handlePageChange = (pageNumber) => {
        return this.fetchData(pageNumber);
    };

    render() {
        return (
            <div data-test='productionsListComponent'>
                <Alert data-test='errorAlert'
                    variant='danger'
                    className="mb-4"
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>    
                </Alert>

                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    addNewPermission={'PRODUCTIONS_CREATE'}
                    data={{
                        headerTitles: [
                            "#",
                            this.props.intl.formatMessage({id:'PRODUCTIONS.PRODUCTION_PRODUCER_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'PRODUCTIONS.PRODUCTION_LICENSEE_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'PRODUCTIONS.PRODUCTION_PROPERTY_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'PRODUCTIONS.PRODUCTION_CREATEDBY_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'PRODUCTIONS.PRODUCTION_PLANTATION_DATE_TABLE_HEADER'}),
                        ],
                        rows: this.state.productions.map((prod) =>({
                            content: [
                                prod.id,
                                prod.producer,
                                prod.licensee,
                                prod.property,
                                prod.createdBy,
                                prod.plantationDateFormatted,
                            ],
                            link: `/productions/${prod.id}`,
                        }))
                    }}
                >
                </StarterList>
            </div>
        );
    }
}

export default injectIntl(ProductionsList);