import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import PlantList from './PlantList';
import PlantDetail from './PlantDetail';
import CreatePlant from './CreatePlant'
import EditPlant from './EditPlant';

import withLayout from '../../components/ui/Layout';

function PlantsRoutes() {
    const intl = useIntl();

    return (
        <Switch data-test='plantsComponent'>
            <PrivateRoute  permission='PLANTS_INDEX'
                exact
                path='/plants'
                component={withLayout(
                    PlantList,
                    intl.formatMessage({id: 'ROUTES.PLANTS.INDEX'}),
                )}
            />

            <PrivateRoute permission='PLANTS_CREATE'
                exact
                path='/plants/create'
                component={withLayout(
                    CreatePlant,
                    intl.formatMessage({id: 'ROUTES.PLANTS.CREATE'}),
                )}
            />
            
            <PrivateRoute permission='PLANTS_SHOW'
                exact
                path='/plants/:id'
                component={withLayout(
                    PlantDetail,
                    intl.formatMessage({id: 'ROUTES.PLANTS.SHOW'}),
                )}
            />
            <PrivateRoute permission='PLANTS_EDIT'
                exact
                path='/plants/:id/edit'
                component={withLayout(
                    EditPlant,
                    intl.formatMessage({id: 'ROUTES.PLANTS.EDIT'}),
                )}
            />

            
        </Switch>
    );
}

export default PlantsRoutes;
