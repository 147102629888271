import React from "react";
import { Row, Col, } from "react-bootstrap";
import { useIntl } from "react-intl";

export default function WaterHole(props) {
    const intl = useIntl();
    return (
        <Row>
            <Col md={2}>
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_HAS_WATERHOLE_LABEL',
                    })}
                </label>
                <p className={props.data.hasWaterHole === true ? 'text-success':'text-danger'}>
                    {props.data.hasWaterHole === true ? 
                    intl.formatMessage({id: 'PROPERTIES.TRUE_VALUE'}):
                    intl.formatMessage({id: 'PROPERTIES.FALSE_VALUE'})
                }</p>
            </Col>
            
            {props.data.hasWaterHole ? (
                <>
                <Col md={2}>
                <label className='field-title'>
                    {intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CNACONCESION_LABEL',
                    })}
                </label>
                <p>
                    {props.data.cnaConcesion }</p>
                </Col>
                <Col md={2}>
                    <label className='field-title'>
                        {intl.formatMessage({
                            id: 'PROPERTIES.PROPERTY_DEPTH_LABEL',
                        })}
                    </label>
                    <p>
                        {props.data.waterHoleDeepness }</p>
                </Col>
                <Col md={2}>
                    <label className='field-title'>
                        {intl.formatMessage({
                            id: 'PROPERTIES.PROPERTY_WATERPUMPCAPACITY_LABEL',
                        })}
                    </label>
                    <p>
                        {props.data.waterPumpCapacity }</p>
                </Col>
                <Col md={2}>
                    <label className='field-title'>
                        {intl.formatMessage({
                            id: 'PROPERTIES.PROPERTY_PIPEWIDTH_LABEL',
                        })}
                    </label>
                    <p>
                        {props.data.pipeDiamenter }</p>
                </Col>
                <Col md={2}>
                    <label className='field-title'>
                        {intl.formatMessage({
                            id: 'PROPERTIES.PROPERTY_LITERSPERSECOND_CAPACITY_LABEL',
                        })}
                    </label>
                    <p>
                        {props.data.litersPerSecond }</p>
                </Col>

                </>
                ):(<div/>)
            }
        </Row>
    );

}