import React from "react";
import { Card} from "react-bootstrap";
import { useIntl } from "react-intl";

export default function ProducerPropertiesList(props) {
    const intl = useIntl();
    const properties = props.properties;
    return (
        <Card className="mt-2 mb-4">
            <Card.Header>
                <p>
                    {intl.formatMessage({id: 'PRODUCERS.PRODUCER_PROPERTIES_LABEL'})}
                </p>
            </Card.Header>
            <Card.Body>
                <table className='mb-0 table'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{intl.formatMessage({id:'PROPERTIES.PROPERTY_NAME_TABLE_HEADER'})}</th>
                            <th>{intl.formatMessage({id:'PROPERTIES.PROPERTY_ADDRESS_TABLE_HEADER'})}</th>
                        </tr>
                    </thead>
                    <tbody>
                        { properties.length > 0 ? 
                            properties.map((prop)=>{
                                return (
                                    <tr>
                                        <td>{prop.id}</td>
                                        <td>{prop.name}</td>
                                        <td>{prop.address}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={3}>
                                    {intl.formatMessage({id: 'PRODUCERS.PRODUCER_NOPROPERTIES_MESSAGE'})}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </Card.Body>
        </Card>
    );
}