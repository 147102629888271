import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import LicenseesList from './LicenseesList';
import CreateLicensee from './CreateLicensee';
import LicenseeDetail from './LicenseeDetail';
import withLayout from '../../components/ui/Layout';
import EditLicensee from './EditLicensee';

function LicenseesRoutes() {
    const intl = useIntl();

    return (
        <Switch data-test='stateComponent'>
            <PrivateRoute  permission='LICENSEES_INDEX'
                exact
                path='/licensees'
                component={withLayout(
                    LicenseesList,
                    intl.formatMessage({id: 'ROUTES.LICENSEES.INDEX'}),
                )}
            />

            <PrivateRoute  permission='LICENSEES_CREATE'
                exact
                path='/licensees/create'
                component={withLayout(
                    CreateLicensee,
                    intl.formatMessage({id: 'ROUTES.LICENSEES.CREATE'}),
                    )}
            />
            
            <PrivateRoute permission='LICENSEES_SHOW'
                exact
                path='/licensees/:id'
                component={withLayout(
                    LicenseeDetail,
                    intl.formatMessage({id: 'ROUTES.LICENSEES.SHOW'}),
                )}
            />
            <PrivateRoute  permission='LICENSEES_EDIT'
                exact
                path='/licensees/:id/edit'
                component={withLayout(
                    EditLicensee,
                    intl.formatMessage({id: 'ROUTES.LICENSEES.EDIT'}),
                )}
            />

        </Switch>
    );
}

export default LicenseesRoutes;
