import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import ProducersList from './ProducersList';
import ProducerDetail from './ProducerDetail';
import CreateProducer from './CreateProducer';
import EditProducer from './EditProducer';

function ProductionsRoutes() {
    const intl = useIntl();

    return (
        <Switch data-test='productionsComponent'>
            <PrivateRoute permission='PRODUCERS_INDEX'
                exact
                path='/producers'
                component={withLayout(
                    ProducersList,
                    intl.formatMessage({id: 'ROUTES.PRODUCERS.INDEX'}),
                )}
            />
            <PrivateRoute permission='PRODUCER_CREATE'
                exact
                path='/producers/create'
                component={withLayout(
                    CreateProducer,
                    intl.formatMessage({id: 'ROUTES.PRODUCERS.CREATE'}),
                )}
            />
            <PrivateRoute permission='PRODUCERS_SHOW'
                exact
                path='/producers/:id'
                component={withLayout(
                    ProducerDetail,
                    intl.formatMessage({id: 'ROUTES.PRODUCERS.SHOW'}),
                )}
            />
            <PrivateRoute  permission='PRODUCER_EDIT'
                exact
                path='/producers/:id/edit'
                component={withLayout(
                    EditProducer,
                    intl.formatMessage({id: 'ROUTES.PRODUCERS.EDIT'}),
                )}
            />
        </Switch>
    );
}

export default ProductionsRoutes;
