import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import BaseForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Input from '../Input';
import LoadingButton from '../LoadingButton';
import ConfirmModal from '../ConfirmModal';
import Can from '../../../common/security/Can';

export default function Form(props) {
    const intl = useIntl();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const validationSchema = Yup.object({
        name: Yup.string().required(
            intl.formatMessage({id: 'PLANTS.VALIDATION.NAME_MISSING'}),
        )
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const handleToggleIsDeleteModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    return (
        <Formik
            data-test='plantFormComponent'
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors, setFieldValue}) => (
                <>
                    <div className='mb-4 d-flex justify-content-end'>
                        <Button
                            data-test='backButton'
                            as={NavLink}
                            to={props.backRedirectUrl}
                            className='btn btn-dark btn-bold btn-light-dark'
                        >
                            {props.backText}
                        </Button>
                    </div>

                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'PLANTS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                                })}
                            </Card.Header>

                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md='12'>
                                        <BaseForm.Row>
                                            <Input
                                                md='12'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'PLANTS.PLANT_NAME_FIELD_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'PLANTS.PLANT_NAME_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='name'
                                                value={values.name}
                                                onChange={handleChange}
                                                error={errors.name}
                                                isInvalid={errors.name}
                                            />
                                        </BaseForm.Row>

                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>
                    </BaseForm>

                    <div className='d-flex justify-content-between'>
                        {props.isEdit && !values.varieties.length ? (
                            <div>
                                <div>
                                    <Can run='PLANTS_DELETE' cantRun={<div />}>
                                        <ConfirmModal
                                            titleModal={intl.formatMessage({
                                                id:
                                                    'PLANTS.DELETE_PLANT_MODAL_TITLE',
                                            })}

                                            handleConfirm = {
                                                props.handleDeleteItem
                                            }
                                            handleShow={
                                                handleToggleIsDeleteModal
                                            }
                                            handleClose={
                                                handleToggleIsDeleteModal
                                            }
                                            show={isDeleteModalOpen}
                                            variant='danger'
                                            buttonClassName='btn-bold'
                                            buttonLabel={intl.formatMessage({
                                                id:
                                                    'PLANTS.DELETE_PLANT_BUTTON_LABEL',
                                            })}
                                            buttonAcceptLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'PLANTS.CONFIRM_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                            buttonCloseLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.CANCEL_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                        >
                                            <p>
                                                {intl.formatMessage({
                                                    id:
                                                        'PLANTS.DELETE_PLANT_CONFIRMATION_MESSAGE',
                                                })}
                                            </p>
                                        </ConfirmModal>
                                    </Can>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {props.isEdit ? (
                                    intl.formatMessage({id: 'PLANTS.PLANT_CANNOT_BE_DELETED'})
                                ):(<div/>)
                                }
                            </div>
                        )}

                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'GENERAL.SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
