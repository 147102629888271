import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Property from '../../models/Property';
import SoilType from '../../models/SoilType';
import WaterType from '../../models/WaterType';
import CertificateType from '../../models/CertificateType';
import { store } from 'common/redux/StoreReducers';

const PropertiesApiService =  {
    get: async (pageNumber = 1, keyword = '') => {
        const {data: response} = await API.get(
            `/properties/?page=${pageNumber}&search=${keyword}`,
        );

        const list = new PaginatedList();
        list.items = response.data.map((prod) => new Property(prod));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;
        return list;    
    },

    save: async (params) => {
    console.log("🚀 ~ file: PropertiesApiService.js ~ line 24 ~ save: ~ params", params)

        await API.post(`/properties`, params, {
            headers: {'Accept-Language': store.getState().langReducer.lang},
        });

        return {};
    },
    
    detail: async (id) => {
        const {data: response} = await API.get(`/properties/${id}`);
        const property = new Property(response.data);
        return property;
    },

    update: async (id, input) => {
        const {data: response} = await API.put(`/properties/${id}`, input);
        return {producer: new Property(response.data)};
    },

    soilTypes: async () => {
        const {data: response} = await API.get(`/properties/soilTypes`);
        return response.data.map((type) => new SoilType(type));
    },

    waterTypes: async () => {
        const {data: response} = await API.get(`/properties/waterTypes`);
        return response.data.map((type) => new WaterType(type));
    },

    certificateTypes: async () => {
        const {data: response} = await API.get(`/properties/certificateTypes`);
        return response.data.map((type) => new CertificateType(type));
    },
    
    getByProducer: async(producer) => {
        const {data: response} = await API.get(`properties/byProducer?producer=${producer}`);
        const properties = response.data.map((prop) => new Property(prop));
        return properties;
    }
}

export default PropertiesApiService;