import React from 'react';
import { injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Row, Col} from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import LoadingButton from '../../components/form/LoadingButton';
import Delivery from '../../common/models/Delivery';
import Can from '../../common/security/Can';
import { Badge } from 'react-bootstrap';
import ConfirmModal from '../../components/form/ConfirmModal';
import API from '../../common/utils/API';
import {Redirect} from 'react-router-dom';

export class DeliveryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            delivery: new Delivery(),
            loading: false,
            isToggleUserStatusModalOpen: false,
            isLoadErrorModalOpen: false,
            isCancelModalOpen: false,
            isDeliverModalOpen: false,
            error: '',
            successMessage: '',
            shouldRedirect:false
        }
    }

    deliveryStatus =  {
        'pending': this.props.intl.formatMessage({id: 'DELIVERIES.DELIVERY_STATUS_PENDING'}),
        'delivered': this.props.intl.formatMessage({id: 'DELIVERIES.DELIVERY_STATUS_DELIVERED'}), 
        'cancelled': this.props.intl.formatMessage({id: 'DELIVERIES.DELIVERY_STATUS_CANCELLED'}), 
        'fullyseeded': this.props.intl.formatMessage({id: 'DELIVERIES.DELIVERY_STATUS_FULLYSEEDED'}), 
        'partiallyseeded': this.props.intl.formatMessage({id: 'DELIVERIES.DELIVERY_STATUS_PARTIALLYSEEDED'}), 
    }
    deliveryStatuses =  {
        'PENDING': 'pending',
        'DELIVERED': 'delivered',
        'CANCELLED': 'cancelled',
        'FULLYSEEDED': 'fullyseeded',
        'PARTIALLYSEEDED': 'partiallyseeded'
    }

    async componentDidMount() {
        await this.loadDetail();
    }

    loadDetail = async () => {
        this.setState({
            loading: true,
        });
        try {
            const delivery = await API.deliveries.detail(this.props.match.params.id);
            this.setState({
                delivery,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (error) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    }

    cancelDelivery = async() => {
        await API.deliveries.cancel(this.props.match.params.id);
        this.setState({
            shouldRedirect: true,
            successMessage: this.props.intl.formatMessage({id: 'DELIVERIES.DELIVERY_SUCCESSFULLY_CANCELED_MESSAGE'})
        });
    }

    deliver = async () => {
        await API.deliveries.deliver(this.props.match.params.id);
        this.setState({
            shouldRedirect: true,
            successMessage: this.props.intl.formatMessage({id: 'DELIVERIES.DELIVERY_SUCCESSFULLY_DELIVERED_MESSAGE'})
        });
    }

    openModalDelete = () =>{
        this.setState({
            isDeleteModalOpen: true,
        })
    }

    toggleChangeModal = (which) => {
        switch (which) {
            case 'cancel':
                this.setState({
                    isCancelModalOpen:!this.state.isCancelModalOpen
                });
                break;
            case 'deliver':
                this.setState({
                    isDeliverModalOpen:!this.state.isDeliverModalOpen
                });
                break;
            default:
                this.setState({
                    isDeliverModalOpen:false,
                    isCancelModalOpen:false,
                });
                break;
        }
    }
    render() {
        return this.state.shouldRedirect ?
        (
            <Redirect data-test='redirectTo'
                to={{
                    pathname: `/deliveries/${this.props.match.params.id}`,
                    state: {
                        successMessage: this.state.successMessage,
                    },
                }}
            />
        )
        :(
            <>
                <Alert data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}>
                </Alert>

                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/deliveries'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>

                <Card className='mb-4' data-test='plagueDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'USERS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={1}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'DELIVERIES.DELIVERY_ID_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.delivery.id}</p>
                            </Col>
                            <Col md={3}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'DELIVERIES.DELIVERY_LICENSEE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.delivery.licenseeDetail.firstName} {this.state.delivery.licenseeDetail.lastName} 
                                </p>
                            </Col>
                            <Col md={2}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'DELIVERIES.DELIVERY_STATUS_FIELD_LABEL',
                                    })}
                                </label><br/>
                                <Badge pill 
                                    className={'badge bg-info text-white'} >
                                    {this.deliveryStatus[this.state.delivery.status]}
                                </Badge>
                            </Col>
                            <Col md={3}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'DELIVERIES.DELIVERY_DATE_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.delivery.deliveryDate ?? this.state.delivery.estimatedDeliveryDate}</p>
                            </Col>
                            <Col md={3}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'DELIVERIES.DELIVERY_VIVARIUM_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.delivery.vivariumDetail.name}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Body className='starter-list'>
                        <table className='table table-striped table-bordered'>
                            <thead>
                            <tr>
                                <th colSpan={3}>
                                    {this.props.intl.formatMessage({id: 'DELIVERIES.VARIETIES_TABLE_CAPTION'})}
                                </th>
                            </tr>
                                <tr>
                                    <th>{this.props.intl.formatMessage({id: 'DELIVERIES.VARIETIES_TABLE_VARIETY_LABEL'})}</th>
                                    <th>{this.props.intl.formatMessage({id: 'DELIVERIES.VARIETIES_TABLE_PLANT_LABEL'})}</th>
                                    <th>{this.props.intl.formatMessage({id: 'DELIVERIES.VARIETIES_TABLE_QUANTITY_LABEL'})}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.delivery.varieties.map((item) => {
                                        return (<tr key={item.id}>
                                            <td>{item.name}</td>
                                            <td>{item.plant}</td>
                                            <td>{item.quantity}</td>
                                        </tr>)
                                    })

                                }
                            </tbody>
                        </table>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-between'>
                    <div>
                        <Can run='DELIVERIES_EDIT'>
                            {this.state.delivery.status === this.deliveryStatuses.PENDING ? 
                            (   <div>
                                    <Button as={NavLink}
                                        to={`/deliveries/${this.props.match.params.id}/edit`}
                                        className='btn-warning btn-bold ml-2'
                                    >
                                        {this.props.intl.formatMessage({
                                            id: 'DELIVERIES.EDIT_DELIVERY_BUTTON_TEXT',
                                        })}
                                    </Button>

                                    <ConfirmModal titleModal={this.props.intl.formatMessage({
                                            id: 'DELIVERIES.CANCEL_DELIVERY_TITLE',
                                        })}
                                        handleConfirm={this.cancelDelivery}
                                        handleShow={() => this.toggleChangeModal('cancel')}
                                        handleClose={()=> this.toggleChangeModal('cancel')}
                                        show={this.state.isCancelModalOpen}
                                        variant='danger'
                                        buttonClassName='btn-bold ml-2'
                                        buttonLabel={this.props.intl.formatMessage({
                                            id: 'DELIVERIES.CANCEL_DELIVERY_BUTTON_TEXT',
                                        })}
                                        buttonAcceptLabel={this.props.intl.formatMessage(
                                            {id: 'DELIVERIES.CONFIRM_CANCEL_BUTTON_TEXT'}
                                        )}
                                        buttonCloseLabel={this.props.intl.formatMessage(
                                            {id: 'USERS.CANCEL_DELETE_BUTTON_LABEL'}
                                        )}
                                    >
                                        <p>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'DELIVERIES.CANCEL_DELIVERY_TEXT',
                                            })}
                                        </p>
                                    </ConfirmModal>

                                    <ConfirmModal titleModal={this.props.intl.formatMessage({
                                            id: 'DELIVERIES.DELIVER_DELIVERY_TITLE',
                                        })}
                                        handleConfirm={this.deliver}
                                        handleShow={()=> this.toggleChangeModal('deliver')}
                                        handleClose={() => this.toggleChangeModal('deliver')}
                                        show={this.state.isDeliverModalOpen}
                                        variant='success'
                                        buttonClassName='btn-bold ml-2'
                                        buttonLabel={this.props.intl.formatMessage({
                                            id: 'DELIVERIES.DELIVER_DELIVERY_BUTTON_TEXT',
                                        })}
                                        buttonAcceptLabel={this.props.intl.formatMessage(
                                            {id: 'DELIVERIES.DELIVER_DELIVERY_BUTTON_TEXT'}
                                        )}
                                        buttonCloseLabel={this.props.intl.formatMessage(
                                            {id: 'USERS.CANCEL_DELETE_BUTTON_LABEL'}
                                        )}
                                        >
                                        <p>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'DELIVERIES.CANCEL_DELIVERY_TEXT',
                                            })}
                                        </p>
                                    </ConfirmModal>
                                </div>
                            ):( this.state.delivery.status === this.deliveryStatuses.CANCELLED ?
                                <div>{this.props.intl.formatMessage({id:'DELIVERIES.DELIVERY_CANNOT_BE_EDITED_CANCELED'})}</div> :
                                <div>{this.props.intl.formatMessage({id:'DELIVERIES.DELIVERY_CANNOT_BE_EDITED'})}</div>
                            )}
                        </Can>
                    </div>
                </div>

                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'USERS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/deliveries'
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.loadDetail}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(DeliveryDetail);