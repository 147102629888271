import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {Col} from 'react-bootstrap';
import * as FeatherIcons from 'react-icons/fi';

const Input = React.forwardRef((props, ref) => {
    const {hiddenToggle, onClickToggle, ...rest} = props;
    const radios = rest.radios || [];
    const style = {};

    return (
        <>
            <Form.Group
                style={style}
                as={Col}
                xs={rest.xs}
                sm={rest.sm}
                md={rest.md}
                lg={rest.lg}
                xl={rest.xl}
                controlId={rest.controlId}
            >
                {rest.label ? <Form.Label>{rest.label}</Form.Label> : null}
                {
                    {
                        text: <Form.Control ref={ref} {...rest} />,
                        number: <Form.Control ref={ref} {...rest} />,
                        email: <Form.Control ref={ref} {...rest} />,

                        password: (
                            <InputGroup>
                                <Form.Control
                                    ref={ref}
                                    {...rest}
                                    type={hiddenToggle ? 'password' : 'text'}
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text
                                        id={'#inputGroupPrepend' + rest.name}
                                        onClick={onClickToggle}
                                    >
                                        <FeatherIcons.FiEye
                                            hidden={!hiddenToggle}
                                        />
                                        <FeatherIcons.FiEyeOff
                                            hidden={hiddenToggle}
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        ),

                        textarea: (
                            <Form.Control ref={ref} {...rest} as={rest.type} />
                        ),

                        select: (
                            <Form.Control
                                ref={ref}
                                {...rest}
                                as={rest.type}
                                id={rest.id}
                                onChange={rest.onChange}
                                value={rest.value}
                            >
                                <option value=''>{rest.placeholder}</option>
                                {rest.children}
                                {rest.options?.length
                                    ? rest.options.map(({value, label}) => (
                                          <option key={value} value={value}>
                                              {label}
                                          </option>
                                      ))
                                    : null}
                                {rest.groupoptions?.length
                                    ? rest.groupoptions.map(
                                          ({label: group, options = []}) => (
                                              <optgroup
                                                  key={group}
                                                  label={group}
                                              >
                                                  {options.map(
                                                      ({value, label}) => (
                                                          <option
                                                              key={value}
                                                              value={value}
                                                          >
                                                              {label}
                                                          </option>
                                                      ),
                                                  )}
                                              </optgroup>
                                          ),
                                      )
                                    : null}
                            </Form.Control>
                        ),

                        radio: (
                            <>
                                {radios.map((radio) => (
                                    <Form.Check
                                        custom
                                        type={rest.type}
                                        id={radio.id}
                                        label={radio.label}
                                        checked={radio.value === rest.value}
                                        value={radio.value}
                                        onChange={rest.onChange}
                                    />
                                ))}
                            </>
                        ),

                        checkbox: (
                            <Form.Check
                                custom
                                label={rest.placeholder}
                                key={rest.id}
                                {...rest}
                                onChange={rest.onChange}
                            />
                        ),
                        switch: (
                            <Form.Check
                                custom
                                label={rest.placeholder}
                                key={rest.id}
                                {...rest}
                                onChange={rest.onChange}
                            />
                        ),
                    }[rest.type]
                }

                {rest.muted ? (
                    <Form.Text className='text-muted'>{rest.muted}</Form.Text>
                ) : null}
                {rest.error ? (
                    <Form.Text className='text-danger'>{rest.error}</Form.Text>
                ) : null}
            </Form.Group>
        </>
    );
});

export default Input;
