import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import CertificateTypesList from 'containers/certificate-types/CertificateTypesList';
import CertificateTypeDetail from 'containers/certificate-types/CertificateTypeDetail';
import CreateCertificateType from 'containers/certificate-types/CreateCertificateType';
import EditCertificateType from 'containers/certificate-types/EditCertificateType';

function CertificateTyesRoutes() {
  const intl = useIntl();

  return (
    <Switch data-test='certificateTypesComponent'>
      <PrivateRoute
        permission='CERTIFICATETYPES_INDEX'
        exact
        path='/certificate-types'
        component={withLayout(
          CertificateTypesList,
          intl.formatMessage({id: 'ROUTES.CERTIFICATETYPES.INDEX'}),
        )}
      />
      <PrivateRoute
        permission='CERTIFICATETYPES_CREATE'
        exact
        path='/certificate-types/create'
        component={withLayout(
          CreateCertificateType,
          intl.formatMessage({id: 'ROUTES.CERTIFICATETYPES.CREATE'}),
        )}
      />
      <PrivateRoute
        permission='CERTIFICATETYPES_SHOW'
        exact
        path='/certificate-types/:id'
        component={withLayout(
          CertificateTypeDetail,
          intl.formatMessage({id: 'ROUTES.CERTIFICATETYPES.SHOW'}),
        )}
      />
      <PrivateRoute
        permission='CERTIFICATETYPES_EDIT'
        exact
        path='/certificate-types/:id/edit'
        component={withLayout(
          EditCertificateType,
          intl.formatMessage({id: 'ROUTES.CERTIFICATETYPES.EDIT'}),
        )}
      />
    </Switch>
  );
}

export default CertificateTyesRoutes;
