export default class Licensee {
    constructor({
        id = 0,
        firstName = '',
        lastName = '',
        rfc = '',
        noInt = '',
        address = '',
        zipCode = '',
        location = '',
        email = '',
        phone = '',
        fiscalType = '',
        fiscalName = '',
        municipality = {},
        municipalityId = '',
        stateId = '',
    } = {}) {
        this.id = id;
        this.fullName = firstName + ' ' +  lastName;
        this.firstName = firstName;
        this.lastName = lastName;
        this.rfc = rfc;
        this.address = address;
        this.zipCode = zipCode;
        this.noInt = noInt;
        this.location = location;
        this.email = email;
        this.phone = phone;
        this.fiscalType = fiscalType;
        this.municipality = municipality;
        this.fiscalName = fiscalName;
        this.stateId = municipality?.stateId;
        this.municipalityId = municipalityId;
        this.stateId = stateId;
    }
}
