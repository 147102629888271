import React from "react";
import { Alert } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { Redirect } from "react-router";
import API from "../../common/utils/API";

import Form from '../../components/form/municipalities/MunicipalityForm';

export class CreateMunicipality extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            shouldRedirectToIndex: false,
            errorMessage: '',
            states: []
        };
    }

    async componentDidMount() {
        this.fetchStates();
    }
    onSubmit = async(input) => {
        const data = {
            state: input.stateId,
            name: input.name
        }
        try {
            await API.municipalities.save(data);
            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
            })
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.STORING_DATA',
                    }),
                });
            }
        }
    }

    fetchStates = async() => {
        const states = await API.states.all();

        this.setState({
            states: states
        })
    }

    render() {
        const initialValues =  {
            name:'',
            state: ''
        };
        return this.state.shouldRedirectToIndex ? (
            <Redirect data-test='redirectToIndex'
                to={{
                    pathname: '/municipalities',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id: 'MUNICIPALITIES.MUNICIPALITY_SUCCESSFULLY_CREATED_MESSAGE'
                        })
                    }
                }}
            />
        ): (<>
            <Alert variant='danger'
                className='mb-4'
                show={!!this.state.errorMessage}>
                <p className="mb-0">{this.state.errorMessage}</p>
            </Alert>
            <Form data-test='createMunicipalityComponent'
                initialValues={initialValues}
                backRedirectUrl='/municipalities'
                backText={this.props.intl.formatMessage({id: 'LIST.BACK_TO_LIST'})}
                loading={this.state.loading}
                handleSubmit={this.onSubmit}
                states={this.state.states}
                >
            </Form>
        </>);
    }
}

export default injectIntl(CreateMunicipality);
