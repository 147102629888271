import React from "react";
import { injectIntl } from 'react-intl';
import { Alert } from "react-bootstrap";
import API from "../../common/utils/API";
import Form from '../../components/form/municipalities/MunicipalityForm';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";
import LoadingButton from '../../components/form/LoadingButton';
import { Redirect } from "react-router-dom";
import Municipality from "../../common/models/Municipality";


export class EditMunicipality extends React.Component {
    constructor (props) {
        super(props);
        this.state =  {
            loading: false,
            shouldRedirectToDetail: false,
            shouldRedirectToIndex: false,
            municipality: new Municipality(),
            states: [],
            errorMessage: '',
            isLoadErrorModalOpen: false,
            redirectSuccessMessage: '',
        }
    }
    async componentDidMount() {
        await Promise.all([this.fetchDetail(), this.fetchStates()]);
    }

    fetchDetail = async() => {
        this.setState({
            loading: true,
        })
        try {
            const detail = await API.municipalities.detail(this.props.match.params.id);
            this.setState({
                municipality: detail,
                loading: false,
                isLoadErrorModalOpen: false,
            })
        } catch (error) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    }

    fetchStates = async() => {
        const states = await API.states.all();

        this.setState({
            states: states
        })
    }

    onSubmit = async (input) => {
        this.setState({loading: true});
            try {
            await API.municipalities.update(
                this.props.match.params.id,
                {name: input.name, state: input.stateId},
            );

            this.setState({
                shouldRedirectToDetail: true,
                loading: false,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'MUNICIPALITIES.MUNICIPALITY_SUCCESSFULLY_UPDATED_MESSAGE',
                }),
            });
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.UPDATING_DATA',
                    }),
                });
            }
        }
    }

    handleDeleteDetail = async() => {
        this.setState({loading: true});

        try {
            await API.municipalities.delete(this.props.match.params.id);

            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'MUNICIPALITIES.MUNICIPALITY_SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                loading: false,
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.DELETING_DATA',
                }),
            });
        }
    }
    render() {
        const initialValues =  {
            name: this.state.municipality.name,
            stateId: this.state.municipality.stateId,
            state: this.state.municipality.stateId
        }
        return this.state.shouldRedirectToDetail ? (
            <Redirect
                data-test='redirectToDetail'
                to={{
                    pathname: `/municipalities/${this.props.match.params.id}`,
                    state: { successMessage: this.state.redirectSuccessMessage,},
                }}
            />
        ): this.state.shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/municipalities',
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) :
        (<>
            <Alert
                variant='danger'
                className='mb-4'
                show={!!this.state.errorMessage}
            >
                <p className='mb-0'>{this.state.errorMessage}</p>
            </Alert>

            <Form data-test='updateMunicipalityComponent'
                initialValues={initialValues}
                backRedirectUrl={`/municipalities/${this.props.match.params.id}`}
                loading={this.state.loading}
                isEdit={true}
                states={this.state.states}
                handleSubmit={this.onSubmit}
                handleDeleteMunicipality={this.handleDeleteDetail}
                backText={this.props.intl.formatMessage({
                    id: 'LIST.BACK_TO_DETAIL',
                })}
            >
            </Form>

            <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'USERS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href={`/municipalities/${this.props.match.params.id}`}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchUser}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
            </Modal>
        </>);
    }
}

export default injectIntl(EditMunicipality);
