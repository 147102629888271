import React, {useMemo, Fragment, useCallback, useState} from 'react';
import {Formik} from 'formik';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import BaseForm from 'react-bootstrap/Form';
import * as Yup from 'yup';

import Input from 'components/form/Input';
import LoadingButton from 'components/form/LoadingButton';
import ConfirmModal from 'components/form/ConfirmModal';
import Can from 'common/security/Can';

export const Form = ({
  handleSubmit,
  initialValues,
  backRedirectUrl,
  backText,
  loading,
  handleDeleteWaterType,
}) => {
  const intl = useIntl();
  const [isDeleteWaterTypeModalOpen, setIsDeleteWaterTypeModalOpen] = useState(
    false,
  );
  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required(
          intl.formatMessage({id: 'WATERTYPES.FORM_MISSING_NAME'}),
        ),
      }),
    [intl],
  );

  const handleToggleIsDeleteWaterTypeModal = useCallback(() => {
    setIsDeleteWaterTypeModalOpen(!isDeleteWaterTypeModalOpen);
  }, [isDeleteWaterTypeModalOpen]);

  return (
    <Formik
      data-test='propertyFormComponent'
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({handleSubmit, handleChange, values, errors, setFieldValue}) => (
        <Fragment>
          <div className='mb-4 d-flex justify-content-end'>
            <Button
              data-test='backButton'
              as={NavLink}
              to={backRedirectUrl}
              className='btn btn-dark btn-bold btn-light-dark'
            >
              {backText}
            </Button>
          </div>
          <BaseForm>
            <Card className='mb-4'>
              <Card.Header>
                {intl.formatMessage({
                  id: 'WATERTYPES.GENERAL_INFORMATION',
                })}
              </Card.Header>
              <Card.Body>
                <BaseForm.Row>
                  <Input
                    md='4'
                    type='text'
                    name='name'
                    value={values.name}
                    onChange={handleChange}
                    error={errors.name}
                    isInvalid={errors.name}
                    label={intl.formatMessage({
                      id: 'WATERTYPES.WATERTYPE_NAME_FIELD_LABEL',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'WATERTYPES.WATERTYPE_NAME_FIELD_LABEL',
                    })}
                  />
                </BaseForm.Row>
              </Card.Body>
            </Card>
          </BaseForm>
          <div className='d-flex justify-content-between'>
            <div>
              {handleDeleteWaterType && (
                <Can run='WATERTYPES_DELETE' cantRun={<div />}>
                  <ConfirmModal
                    titleModal={intl.formatMessage({
                      id: 'WATERTYPES.DELETE_MODAL_TITLE',
                    })}
                    handleConfirm={handleDeleteWaterType}
                    handleShow={handleToggleIsDeleteWaterTypeModal}
                    handleClose={handleToggleIsDeleteWaterTypeModal}
                    show={isDeleteWaterTypeModalOpen}
                    variant='danger'
                    buttonClassName='btn-bold'
                    buttonLabel={intl.formatMessage({
                      id: 'WATERTYPES.DELETE_BUTTON_LABEL',
                    })}
                    buttonAcceptLabel={intl.formatMessage({
                      id: 'WATERTYPES.CONFIRM_DELETE_BUTTON_LABEL',
                    })}
                    buttonCloseLabel={intl.formatMessage({
                      id: 'WATERTYPES.CANCEL_DELETE_BUTTON_LABEL',
                    })}
                  >
                    <p>
                      {intl.formatMessage({
                        id: 'WATERTYPES.DELETE_CONFIRMATION_MESSAGE',
                      })}
                    </p>
                  </ConfirmModal>
                </Can>
              )}
            </div>
            <div className='text-right'>
              <LoadingButton
                variant='primary'
                className='btn-bold'
                type='submit'
                onClick={handleSubmit}
                loading={loading}
              >
                {intl.formatMessage({
                  id: 'GENERAL.SAVE_BUTTON_TEXT',
                })}
              </LoadingButton>
            </div>
          </div>
        </Fragment>
      )}
    </Formik>
  );
};

export default Form;
