import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Bank from '../../models/Bank';
import {store} from '../../redux/StoreReducers';

const BanksApiService =  {
    get: async (pageNumber = 1, keyword = '') => {
        const {data: response} = await API.get(
            `/banks/?page=${pageNumber}&search=${keyword}`,
        );
        const list = new PaginatedList();
        list.items = response.data.map((bank) => new Bank(bank));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;
        return list;    
    },

    detail: async(id) => {
        const {data: response} = await API.get(`/banks/${id}`);
        const bank = new Bank(response.data);
        return bank;
    },

    all: async() => {
        const {data: response} = await API.get(`/banks/all`);
        return response.data;
    },

    save: async (input) => {
        await API.post(`/banks/`, input, {
            headers: {'Accept-Language': store.getState().langReducer.lang},
        });

        return {};
    },

    update: async (id, params) => {
        const {data: response} = await API.put(`/banks/${id}`, params);
        return {bank: new Bank(response.data)};
    },

    delete: async (id) => {
        await API.delete(`/banks/${id}`);
        return {};
    },
}

export default BanksApiService;