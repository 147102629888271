import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import Users from './users/Users';
import Roles from './roles/Roles';
import PlantsRoutes from './plants/PlantsRoutes';
import VarietiesRoutes from './varieties/VarietiesRoutes';
import StatesRoutes from './states/StatesRoutes';
import MunicipalitiesRoutes from './municipalties/MunicipalitiesRoutes';
import PlagueRoutes from './plagues/PlagueRoutes';
import VivariumsRoutes from './vivariums/VivariumsRoutes';
import BanksRoutes from './banks/BanksRoutes';
import LicenseesRoutes from './licensees/LicenseesRoutes';
import DeliveriesRoutes from './deliveries/DeliveriesRoutes';
import ProductionsRoutes from './productions/ProductionsRoutes';
import PropertiesRoutes from './properties/PropertiesRoutes';
import ProducersRoutes from './producers/ProducersRoutes';
import ReportsRoutes from 'containers/reports/ReportsRoutes';
import WatwerTypeRoutes from 'containers/water-types/WaterTypesRoutes';
import SoilTypeRoutes from 'containers/soil-types/SoilTypesRoutes';
import CertificateTyesRoutes from 'containers/certificate-types/CertificateTypesRoutes';

import Dashboard from './dashboard/Dashboard';
import withLayout from '../components/ui/Layout';
import {useIntl} from 'react-intl';

function MainContainer(props) {
    const intl = useIntl();

    return (
        <Switch>
            <Route
                path='/'
                exact
                component={withLayout(
                    Dashboard,
                    intl.formatMessage({id: 'MENU.DASHBOARD'}),
                )}
            />
            <Route path='/users' component={Users} />
            <Route path='/roles' component={Roles} />
            <Route path='/plants' component={PlantsRoutes} />
            <Route path='/varieties' component={VarietiesRoutes} />
            <Route path='/states' component={StatesRoutes} />
            <Route path='/municipalities' component={MunicipalitiesRoutes} />
            <Route path='/plagues' component={PlagueRoutes} />
            <Route path='/vivariums' component={VivariumsRoutes} />
            <Route path='/banks' component={BanksRoutes} />
            <Route path='/licensees' component={LicenseesRoutes} />
            <Route path='/deliveries' component={DeliveriesRoutes} />
            <Route path='/productions' component={ProductionsRoutes} />
            <Route path='/properties' component={PropertiesRoutes} />
            <Route path='/producers' component={ProducersRoutes} />
            <Route path='/reports' component={ReportsRoutes} />
            <Route path='/water-types' component={WatwerTypeRoutes} />
            <Route path='/soil-types' component={SoilTypeRoutes} />
            <Route path='/certificate-types' component={CertificateTyesRoutes} />
            <Route render={() => <Redirect to="/" />} />
        </Switch>
    );
}

const logout = function () {
    return {
        type: 'LOGOUT',
    };
};

const mapStateToProps = function (state) {
    return {
        user: state.sessionReducer.user,
        title: state.testReducer.title,
        content: state.testReducer.content,
    };
};

const mapDispatchToProps = {
    logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
