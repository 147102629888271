import React from 'react';
import { injectIntl } from 'react-intl';
import Alert  from 'react-bootstrap/Alert';
import StarterList from '../../components/starter/StarterList';
import API from '../../common/utils/API'
import { Modal, Button, Col } from 'react-bootstrap';
import LoadingButton from 'components/form/LoadingButton';
import {Row} from 'react-bootstrap';

export class DeliveriesList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            deliveries: [],
            page: 1,
            status: undefined,
            dateSearch: undefined,
            pageSize: 12,
            pageCount: 2,
            total: 0,
            searchTimeout: 0,
            search: '',
            error: false,
            isReportModalOpen: false,
            licensees: [],
            modalFilters: {
                licensee: '',
                vivarium: '',
                from: '',
                to: ''
            }
        }
    }
    deliveryStatus =  {
        'pending': this.props.intl.formatMessage({id: 'DELIVERIES.DELIVERY_STATUS_PENDING'}),
        'delivered': this.props.intl.formatMessage({id: 'DELIVERIES.DELIVERY_STATUS_DELIVERED'}), 
        'cancelled': this.props.intl.formatMessage({id: 'DELIVERIES.DELIVERY_STATUS_CANCELLED'}), 
        'fullyseeded': this.props.intl.formatMessage({id: 'DELIVERIES.DELIVERY_STATUS_FULLYSEEDED'}), 
        'partiallyseeded': this.props.intl.formatMessage({id: 'DELIVERIES.DELIVERY_STATUS_PARTIALLYSEEDED'}), 
        }

    deliveryStatusSelect = Object.keys(this.deliveryStatus).map((key) => ({
            value: key,
            label: this.deliveryStatus[key]
        }));

    async componentDidMount() {
        await Promise.all([
            this.fetchData(),
            this.fetchFilters(),
        ]);
    }

    fetchData = async (pageNumber = 1) => {
        try {
            const result = await API.deliveries.get(
                pageNumber,
                this.state.search,
                this.state.status,
                this.state.dateSearch
            );
            this.setState({
                deliveries: result.items,
                page: result.page,
                pageSize: result.pageSize,
                pageCount: result.pageCount,
                total: result.total,
                error: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({ error: true },
                this.clearErroMessage,
            );
        }
    }

    handleDateSearch  = (date) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            'dateSearch': date,
            searchTimeout: setTimeout(() => {
                return this.fetchData(1);
            }, 300),
        });
    } 

    handleSearchKeywordChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        let filters = this.state.modalFilters;
        filters[event.target.name] =  event.target.value;

        this.setState({
            [event.target.name]: event.target.value,
            modalFilters: filters,
            searchTimeout: setTimeout(() => {
                return this.fetchData(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchData(1);
            },
        );
    };

    handlePageChange = (pageNumber) => {
        return this.fetchData(pageNumber);
    };

    getReport = async () => {
        await API.deliveries.report(this.state.modalFilters);
        this.toggleReportModalOpen();
    }

    toggleReportModalOpen = () => {
        this.setState({
            isReportModalOpen: !this.state.isReportModalOpen,
        });
    }
    
    fetchFilters = async () => {
        const licensees = await API.licensees.all();
        const vivariums = await API.vivariums.all();

        this.setState({
            licensees: licensees.map(lic=>({value: lic.id, label: lic.firstName + ' ' + lic.lastName})),
            vivariums: vivariums.map(viv => ({value: viv.id, label: viv.name})),
        })
    }

    handleChangeFilter = async (eve, type) => {
        let filters = this.state.modalFilters;
        switch (type) {
            case "licensee":
                filters.licensee = eve.target.value;
                break;
            case "vivarium":
                filters.vivarium = eve.target.value;
                break;
            case "from":
                filters.from = eve;
                break;
            case "to":
                filters.to = eve;
                break;
            default: break;
        }
        this.setState({
            modalFilters: filters
        });
    }

    render() {
        return (
            <div data-test='deliveriesListComponent'>
                <Alert data-test='errorAlert'
                    variant='danger'
                    className="mb-4"
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>    
                </Alert>
                <div className='d-flex justify-content-start mb-2'>
                    <Button
                        className='btn-warning btn-bold'
                        onClick={this.toggleReportModalOpen}
                    >
                        {this.props.intl.formatMessage({
                            id: 'DELIVERIES.REPORT_DELIVERIES_BUTTON_TEXT',
                        })}
                    </Button>
                </div>
                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    onAutocompleteOrDateSearchChange={this.handleDateSearch}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    addNewPermission={'DELIVERIES_CREATE'}
                    addNewLink={`deliveries/create`}
                    data={{
                        headerTitles: [
                            "#",
                            this.props.intl.formatMessage({id:'DELIVERIES.DELIVERY_LICENSEE_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'DELIVERIES.DELIVERY_VIVARIUM_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'DELIVERIES.DELIVERY_ESTIMATED_DELIVERY_DATE_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'DELIVERIES.DELIVERY_STATUS_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'DELIVERIES.DELIVERY_DELIVERY_DATE_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'DELIVERIES.DELIVERY_QUANTITY_TABLE_HEADER'}),
                        ],
                        rows: this.state.deliveries.map((delivery) =>({
                            content: [
                                delivery.id,
                                delivery.licensee,
                                delivery.vivarium,
                                delivery.estimatedDeliveryDate,
                                this.deliveryStatus[delivery.status],
                                delivery.deliveryDate,
                                delivery.quantity,
                                
                            ],
                            link: `/deliveries/${delivery.id}`,
                        }))
                    }}
                    searchFields={[
                        {
                            options:  this.deliveryStatusSelect,
                            name:`status`,
                            placeholder: this.props.intl.formatMessage({id:'DELIVERIES.FILTER_STATUS_PLACEHOLDER'}),
                            value: this.state.status
                        },
                        {
                            name: 'from',
                            placeholder: this.props.intl.formatMessage({id: 'DELIVERIES.FROM_DATE_FILTER_PLACEHOLDER'}),
                            value: this.state.from,
                            date: true
                        },
                        {
                            name: 'to',
                            placeholder: this.props.intl.formatMessage({id: 'DELIVERIES.TO_DATE_FILTER_PLACEHOLDER'}),
                            value: this.state.to,
                            date: true
                        },
                        {
                            options:  this.state.licensees,
                            name:`licensee`,
                            placeholder: this.props.intl.formatMessage({id:'DELIVERIES.LICENSEE_MODAL_FILTER_PLACEHOLDER'}),
                            value: this.state.licensee
                        },
                        {
                            options:  this.state.vivariums,
                            name:`vivarium`,
                            placeholder: this.props.intl.formatMessage({id:'DELIVERIES.VIVARIUM_MODAL_FILTER_PLACEHOLDER'}),
                            value: this.state.vivarium
                        },
                    ]}
                >
                </StarterList>
                <Modal
                    show={this.state.isReportModalOpen}
                    onHide={this.toggleReportModalOpen}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'DELIVERIES.REPORT_DELIVERIES_BUTTON_TEXT',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <p>
                                    {this.props.intl.formatMessage({id: "DELIVERIES.MODAL_EXPORT_MESSAGE"})}
                                </p>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            onClick={this.getReport}
                        >
                            {this.props.intl.formatMessage({
                                id: 'DELIVERIES.REPORT_DELIVERIES_BUTTON_TEXT',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(DeliveriesList);