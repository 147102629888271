export default class Report {
  constructor({
    id = '',
    folio = '',
    plantation = '',
    plantationFolio = '',
    hectares = '',
    createdBy = '',
    createdAt = '',
    property = {},
    producer = {},
    production = {},
    licensee = {},
    pictures = [],
    weekOfYear = '',
    photosComments = '',
    observations = '',
    fenologicState = '',
    hasRecomendation = '',
    recomendations = [],
    signature = '',
    signature2 = '',
    categoryQuality = '',
    fruitWorm = '',
    mites = '',
    trips = '',
    mayate = '',
    chinche = '',
    whiteFly = '',
    chicharrita = '',
    pulgon = '',
    grayMold = '',
    antracnosis = '',
    cenicilla = '',
    prenospora = '',
    prenosporaSampling = '',
    other = '',
    otherMeasurement = '',
    otherSampling = '',
    encarisiaFormosa = '',
    neoseiulisBarkery = '',
    amlyseiusCucumeris = '',
    chincheOrius = '',
    phytoseluisPermisilis = '',
    witness = '',
  } = {}) {
    this.id = id;
    this.folio = folio;
    this.plantation = plantation;
    this.plantationFolio = plantationFolio;
    this.hectares = hectares;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.property = property;
    this.producer = producer;
    this.production = production;
    this.licensee = licensee;
    this.pictures = pictures;
    this.weekOfYear = weekOfYear;
    this.photosComments = photosComments;
    this.observations = observations;
    this.fenologicState = fenologicState;
    this.hasRecomendation = hasRecomendation;
    this.recomendations = recomendations;
    this.signature = signature;
    this.signature2 = signature2;
    this.categoryQuality = categoryQuality;
    this.fruitWorm = fruitWorm;
    this.mites = mites;
    this.trips = trips;
    this.mayate = mayate;
    this.chinche = chinche;
    this.whiteFly = whiteFly;
    this.chicharrita = chicharrita;
    this.pulgon = pulgon;
    this.grayMold = grayMold;
    this.antracnosis = antracnosis;
    this.cenicilla = cenicilla;
    this.prenospora = prenospora;
    this.prenosporaSampling = prenosporaSampling;
    this.other = other;
    this.otherMeasurement = otherMeasurement;
    this.otherSampling = otherSampling;
    this.encarisiaFormosa = encarisiaFormosa;
    this.neoseiulisBarkery = neoseiulisBarkery;
    this.amlyseiusCucumeris = amlyseiusCucumeris;
    this.chincheOrius = chincheOrius;
    this.phytoseluisPermisilis = phytoseluisPermisilis;
    this.witness = witness;
  }
}
