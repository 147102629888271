export default class Producer {
    constructor({
        id = '',
        status = '',
        fullName = '',
        firstName = '',
        lastName = '',
        middleLastName= '',
        email = '',
        fiscalType = '',
        phone = '',
        hectares = '',
        plantsPerHectare = '',
        type = '',
        businessName = '',
        employees = '',
        recruitSource = '',
        averageSalary = '',
        hasOffices = '',
        resourcesOrigin = '',
        budgetAvailable = '',
        bank = {},
        hasColdRoom = '',
        hasRefrigeratorTruck = '',
        requiresFinancing = '',
        hasExperience = '',
        experience = '',
        experienceTime = '',
        hasProductionManager = '',
        bussinesPartners = '',
        reasonChangeCultive = '',
        producerCodeOwn = '',
        seasonsWorking = '',
        lastLineBusiness = '',
        variety = '',
        varietyId = '',
        plant = '',
        signature = '',
        bankId='',
        createdBy = {}
    } = {}) {
        this.id = id;
        this.status = status;
        this.fullName = fullName;
        this.firstName = firstName;
        this.lastName = lastName;
        this.middleLastName = middleLastName;
        this.email = email;
        this.fiscalType = fiscalType;
        this.phone = phone;
        this.hectares = hectares;
        this.plantsPerHectare = plantsPerHectare;
        this.type = type;
        this.businessName = businessName??'';
        this.employees = employees ?? 0;
        this.recruitSource = recruitSource;
        this.averageSalary = averageSalary ?? 0;
        this.hasOffices = hasOffices;
        this.resourcesOrigin = resourcesOrigin;
        this.budgetAvailable = budgetAvailable;
        this.bank = bank;
        this.hasColdRoom = hasColdRoom;
        this.hasRefrigeratorTruck = hasRefrigeratorTruck;
        this.requiresFinancing = requiresFinancing;
        this.hasExperience = hasExperience;
        this.experience = experience ?? '';
        this.experienceTime = experienceTime ?? '';
        this.hasProductionManager = hasProductionManager;
        this.bussinesPartners = bussinesPartners ?? '';
        this.reasonChangeCultive = reasonChangeCultive;
        this.producerCodeOwn = producerCodeOwn ?? '';
        this.seasonsWorking = seasonsWorking ?? '';
        this.lastLineBusiness = lastLineBusiness??'';
        this.variety = variety;
        this.plant = plant;
        this.signature = signature;
        this.bankId = bankId;
        this.createdBy = createdBy;
        this.licensee = createdBy?.id;
        this.variety = variety;
        this.varietyId = varietyId;
    }
}
