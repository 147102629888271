import React from "react";
import {Map, GoogleApiWrapper,InfoWindow, Marker} from 'google-maps-react';

export class MapContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showingInfoWindow: true,
            activeMarker: {},
            selectedPlace: {},
            lat: props.lat || '19.8798975',
            lng: props.lng || '-103.6139883',
            property: props.property || '',
            address: props.address || '',
        };
    }

    onMarkerClick = (props, marker, e) =>{
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    componentDidMount = props => {
    }

    onClose = () =>{
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    }

    render() {
        return (
            <Map
                google={this.props.google}
                zoom={16}
                style={{width:'97%', height:'99%'}}
                initialCenter={
                    {
                        lat: this.state.lat,
                        lng: this.state.lng
                    }
                }
                disableDefaultUI={true}
            >
                
                    <Marker onClick={this.onMarkerClick}
                        name={this.props.property}
                        address={this.props.address}
                    ></Marker>
                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                        onClose={this.onClose}
                    >
                    <div style={{padding: `15px`}}>
                        <h4>{this.state.selectedPlace.name}</h4>
                        <h5>{this.state.selectedPlace.address}</h5>
                    </div>
                    </InfoWindow>
                
            </Map>
        )
    }
}

export default GoogleApiWrapper(
    (props) => ({ apiKey: props.API_KEY})
)(MapContainer);