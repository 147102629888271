import React from "react";
import { Alert } from "react-bootstrap";
import { injectIntl } from "react-intl";
import API from "../../common/utils/API";
import StarterList from "../../components/starter/StarterList";

export class MunicipalitiesList  extends React.Component{
    constructor(props) {
        super(props);
        this.state =  {
            municipalities: [],
            page: 1,
            pageSize: 12,
            pageCount: 1,
            total: 0,
            searchTimeout: 0,
            search: '',
            state: '',
            states:[],
            error: false,
        }
    }

    async componentDidMount() {
        await Promise.all([this.fetchData(1), this.fetchStates()]);
    }

    fetchData = async(pageNumber = 1) => {
        try {
            const result = await API.municipalities.get(
                pageNumber,
                this.state.search,
                this.state.state,
                this.state.pageSize
            );

            this.setState({
                municipalities: result.items,
                page: result.page,
                pageCount: result.pageCount,
                total: result.total,
                error: false,
            });
        } catch (error) {
            this.setState({
                error: true
            }, this.clearErrorMessage)
        }
    }

    fetchStates = async() => {
        try {
            const states = await API.states.all();
            this.setState({
                error: false,
                states: states.map((state) =>({value: state.id, label: state.name})),
            });
            
        } catch (error) {
            this.setState({
                error: error
            })
        }
    }

    pageSizeChange = async(event) => {
        await this.setState({
            pageSize: +event.target.value
        });
        await this.fetchData(1);
    }

    handleSearchKeywordChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchData(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchData(1);
            },
        );
    };

    handlePageChange = (pageNumber) => {
        return this.fetchData(pageNumber);
    };

    render() {
        return (
            <div data-test='municipalitiesListComponent'>
                <Alert data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                    >
                        <p className="mb-0">
                            {
                                this.props.intl.formatMessage({id: 'ERROR_PAGES.LOADING_DATA'})
                            }
                        </p>

                </Alert>

                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    onPageSizeChange={this.pageSizeChange}
                    total={this.state.total}
                    pageSizeOptions={[5,12,20,50,100]}
                    addNewPermission={'MUNICIPALITIES_CREATE'}
                    addNewLink={`municipalities/create`}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({id:'MUNICIPALITIES.SATE_NAME_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'MUNICIPALITIES.MUNICIPALITY_NAME_TABLE_HEADER'}),
                        ],
                        rows: this.state.municipalities.map((municipality) => ({
                            content: [
                                municipality.id,
                                municipality.state,
                                municipality.name
                            ],
                            link: `/municipalities/${municipality.id}`
                        }))
                    }}
                    searchFields={[
                        {
                            options: this.state.states,
                            name: 'state',
                            placeholder: this.props.intl.formatMessage({id: 'MUNICIPALITIES.FILTER_BY_STATE_PLACE_HOLDER'}),
                            value: this.state.state,
                        },
                    ]}
                    >
                </StarterList>

            </div>
        );
    }
}

export default injectIntl(MunicipalitiesList);