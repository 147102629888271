import React from 'react';
import { injectIntl } from 'react-intl';
import Alert  from 'react-bootstrap/Alert';

import StarterList from '../../components/starter/StarterList';
import API from '../../common/utils/API'

export class PlantList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            plants: [],
            page: 1,
            pageSize: 12,
            pageCount: 2,
            total: 0,

            searchTimeout: 0,
            search: '',
            error: false,
        }
    }

    async componentDidMount() {
        await Promise.all([this.fetchPlants()]);
    }

    fetchPlants = async (pageNumber = 1) => {
        try {
            const result = await API.plants.get(
                pageNumber,
                this.state.search,
            );
            this.setState({
                plants: result.items,
                page: result.page,
                pageSize: result.pageSize,
                pageCount: result.pageCount,
                total: result.total,
                error: false,
            });
        } catch (error) {
            this.setState(
                {
                    error: true,
                },
                this.clearErroMessage,
            );
        }
    }

    handleSearchKeywordChange = (event) => {
        /* istanbul ignore else */
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchPlants(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchPlants(1);
            },
        );
    };

    handlePageChange = (pageNumber) => {
        return this.fetchPlants(pageNumber);
    };
    render() {
        return (
            <div data-test='plantListComponent'>
                <Alert 
                    data-test='errorAlert'
                    variant='danger'
                    className="mb-4"
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>    
                </Alert>

                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    addNewLink={`plants/create`}
                    data={{
                        headerTitles: [
                            "#",
                            this.props.intl.formatMessage({id:'PLANTS.PLANT_NAME_TABLE_HEADER'}),
                        ],
                        rows: this.state.plants.map((plant) =>({
                            content: [
                                plant.id,
                                plant.name
                            ],
                            link: `/plants/${plant.id}`,
                        }))
                    }}
                >
                </StarterList>

            </div>
        );
    }
}

export default injectIntl(PlantList);