import axios from 'axios';
import roles from './roles/Roles';
import session from './session/Session';
import users from './users/Users';
import plants from './plants/PlantsApiService';
import varieties from './varieties/VarietiesApiService';
import states from './states/StatesApiService';
import municipalities from './municipalties/MunicipalitiesApiService';
import plagues from './plagues/PlaguesApiService';
import vivariums from './vivariums/VivariumsApiService';
import banks from './banks/BanksApiService';
import licensees from './licensees/LicenseesApiService';
import deliveries from './deliveries/DeliveriesApiService';
import productions from './productions/ProductionsApiService';
import properties from './properties/PropertiesApiService';
import producers from './producers/ProducersApiService';
import reports from 'common/utils/reports/ReportsApiService';
import supervisors from 'common/utils/supervisors/SupervisorsApiService';
import waterTypes from 'common/utils/water-types/WaterTypeApiService';
import soilTypes from 'common/utils/soil-types/SoilTypesApiService';
import certificateTypes from 'common/utils/certificate-types/CertificateTypesService';

import permissions from './permissions/Permissions';
import {store} from '../redux/StoreReducers';

const authTokens = {
  bearer: undefined,
  refreshToken: undefined,
};

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Accept: 'application/json',
    Authorization: ' bearer',
    FromOrigin: 'web',
  },
});

// Export setters for bearer and refresh token

export const setBearerToken = (token) => {
  authTokens.bearer = token;
  API.defaults.headers.common['Authorization'] = token;
  console.log('did set bearer token');
};

export const setRefreshToken = (token) => {
  authTokens.refreshToken = token;
};

API.interceptors.request.use((config) => {
  if (store.getState().sessionReducer.session) {
    config.headers.Authorization =
      'Bearer ' + store.getState().sessionReducer.session.token;
  }
  config.headers['Accept-Language'] = store.getState().langReducer.lang;
  return config;
});

// Set interceptor for refreshing auth token
API.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default {
  users,
  roles,
  session,
  permissions,
  plants,
  varieties,
  states,
  municipalities,
  plagues,
  vivariums,
  banks,
  licensees,
  deliveries,
  productions,
  properties,
  producers,
  reports,
  supervisors,
  waterTypes,
  soilTypes,
  certificateTypes,
};
