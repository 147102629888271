import React from 'react';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import {injectIntl} from 'react-intl';

import API from '../../common/utils/API';
import Form from '../../components/form/roles/RoleForm';

export class CreateRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            shouldRedirectToIndex: false,
            errorMessage: '',
            permissions: [],
            permissionGroups: [],
        };
    }

    async componentDidMount() {
        return this.fetchPermissions();
    }

    fetchPermissions = async () => {
        this.setState({loading: true});

        try {
            const {permissions} = await API.permissions.get();

            this.setState({
                permissionGroups: permissions.map(
                    (permission) => permission.group,
                ),
                permissions,
                loading: false,
            });
        } catch (e) {
            this.setState({
                errorMessage: this.props.intl.formatMessage({
                    id: 'ERROR_PAGES.LOADING_DATA',
                }),
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});

        try {
            await API.roles.save(input);

            this.setState({loading: false, shouldRedirectToIndex: true});
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.STORING_DATA',
                    }),
                });
            }
        }
    };

    render() {
        const initialValues = {
            name: '',
            permissions: [],
        };
        const permissionGroups = this.state.permissionGroups
            .filter((x, i, a) => a.indexOf(x) === i)
            .sort();

        return this.state.shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/roles',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id: 'ROLES.ROLE_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form
                    data-test='createRoleComponent'
                    initialValues={initialValues}
                    backRedirectUrl='/roles'
                    backText={this.props.intl.formatMessage({
                        id: 'LIST.BACK_TO_LIST',
                    })}
                    permissions={this.state.permissions}
                    permissionGroups={permissionGroups}
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                />
            </>
        );
    }
}
export default injectIntl(CreateRole);
