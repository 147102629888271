import {API} from 'common/utils/API';

const SupervisorsApiService = {
    all: async () => {
        const {data: response} = await API.get(`/supervisors/all`);
        return response.data;
    },
};

export default SupervisorsApiService;
