import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import API from '../../common/utils/API';
import Form from '../../components/form/producers/ProducerForm';

export class CreateProducer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            shouldRedirectToIndex: false,
            errorMessage: '',
            varieties: [],
            banks: [],
            producerTypes: [
                {id: 'new', name: 'NUEVO'},
                {id: 'settled_down', name: 'ESTABLECIDO'}
            ],
            fiscalTypes: [
                {id: 'fisica', name: 'FÍSICA'},
                {id: 'moral', name: 'MORAL'}
            ],
            producerStatus: [
                {id: 'pending', name: this.props.intl.formatMessage({id: 'PRODUCERS.PRODUCER_STATUS_PENDING'})},
                {id: 'approved', name: this.props.intl.formatMessage({id: 'PRODUCERS.PRODUCER_STATUS_APPROVED'})},
                {id: 'conditioned_approved', name: this.props.intl.formatMessage({id: 'PRODUCERS.PRODUCER_STATUS_CONDITIONED'})},
                {id: 'rejected', name: this.props.intl.formatMessage({id: 'PRODUCERS.PRODUCER_STATUS_REJECTED'})},
            ],
            licensees: []
        };
    }

    async componentDidMount() {
        await this.fetchVarieties();
        await this.fetchBanks();
        await this.fetchLicensees();
    };

    onSubmit = async (input) => {
        console.log("🚀 ~ file: CreateProducer.js ~ line 41 ~ CreateProducer ~ onSubmit= ~ input", input)
        
        try {
            await API.producers.save(input);
            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
            });
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.STORING_DATA',
                    }),
                });
            }
        }
    };

    fetchVarieties = async() => {
        try {
            const varieties = await API.varieties.all();
            this.setState({
                varieties: varieties
            });
        } catch (error) {
            console.log(error)
            this.setState({
                error: false
            });
        }
    };

    fetchBanks = async() => {
        try {
            const banks = await API.banks.all();
            this.setState({
                banks: banks
            });
        } catch (error) {
            console.log(error)
            this.setState({
                error: false
            });
        }
    };

    fetchLicensees = async() => {
        try {
            const licensees = await API.licensees.all();
            this.setState({
                licensees
            });
        } catch (error) {
            console.log(error)
            this.setState({
                error: false
            });
        }
    }

    render() {
        const initialValues = {
        };
        return this.state.shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/producers',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id: 'PRODUCERS.PRODUCER_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <>
                <Alert variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form data-test='CreateProducerComponent'
                    initialValues={initialValues}
                    backRedirectUrl='/states'
                    backText={this.props.intl.formatMessage({
                        id: 'LIST.BACK_TO_LIST',
                    })}
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                    varieties={this.state.varieties}
                    banks={this.state.banks}
                    fiscalTypes={this.state.fiscalTypes}
                    producerTypes={this.state.producerTypes}
                    producerStatus={this.state.producerStatus}
                    licensees={this.state.licensees}
                />
            </>
        );
    }
}

export default injectIntl(CreateProducer);
