import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import ProductionsList from './ProductionsList';
import ProductionDetail from './ProductionDetail';

function ProductionsRoutes() {
    const intl = useIntl();

    return (
        <Switch data-test='productionsComponent'>
            <PrivateRoute  permission='PRODUCTIONS_INDEX'
                exact
                path='/productions'
                component={withLayout(
                    ProductionsList,
                    intl.formatMessage({id: 'ROUTES.PRODUCTIONS.INDEX'}),
                )}
            />
            <PrivateRoute permission='PRODUCTIONS_SHOW'
                exact
                path='/productions/:id'
                component={withLayout(
                    ProductionDetail,
                    intl.formatMessage({id: 'ROUTES.PRODUCTIONS.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default ProductionsRoutes;
