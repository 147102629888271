import React from 'react';
import { injectIntl } from 'react-intl';
import Alert  from 'react-bootstrap/Alert';
import StarterList from '../../components/starter/StarterList';
import API from '../../common/utils/API'

export class ProducersList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            producers: [],
            licensees: [],
            page: 1,
            pageSize: 12,
            pageCount: 2,
            total: 0,
            searchTimeout: 0,
            search: '',
            licensee: 0,
            error: false,
        }
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchData(),
            this.loadLicensees(),
        ]);
    }

    fetchData = async (pageNumber = 1) => {
        try {
            const result = await API.producers.get(
                pageNumber,
                this.state.search,
                parseInt(this.state.licensee)
            );
            this.setState({
                producers: result.items,
                page: result.page,
                pageSize: result.pageSize,
                pageCount: result.pageCount,
                total: result.total,
                error: false,
            });
        } catch (error) {
            console.log("error en el fetc", error);
            this.setState({ error: true },
                this.clearErroMessage,
            );
        }
    }

    loadLicensees = async() => {
        const lics = await API.licensees.all();
        this.setState({
            licensees: lics.map((lic)=> ({
                value: lic.id,
                label: lic.firstName + ' ' + lic.lastName
            })),
        })
    }

    handleSearchKeywordChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchData(1);
            }, 300),
        });
    };

    handleSearchKeywordClear = (field = 'search') => {
        this.setState(
            {
                pageSize: 1,
                [field]: '',
            },
            () => {
                return this.fetchData(1);
            },
        );
    };

    handlePageChange = (pageNumber) => {
        return this.fetchData(pageNumber);
    };

    render() {
        return (
            <div data-test='producersListComponent'>
                <Alert data-test='errorAlert'
                    variant='danger'
                    className="mb-4"
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ERROR_PAGES.LOADING_DATA',
                        })}
                    </p>    
                </Alert>

                <StarterList
                    onSearchKeywordClear={this.handleSearchKeywordClear}
                    onSearchKeywordChange={this.handleSearchKeywordChange}
                    onPageChange={this.handlePageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    addNewPermission={'PRODUCERS_CREATE'}
                    addNewLink={`producers/create`}
                    data={{
                        headerTitles: [
                            "#",
                            this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_NAME_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_LASTNAME_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_EMAIL_TABLE_HEADER'}),
                            this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_STATUS_TABLE_HEADER'}),
                        ],
                        rows: this.state.producers.map((prod) =>({
                            content: [
                                prod.id,
                                prod.firstName,
                                prod.lastName,
                                prod.email,
                                this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_STATUS_' + prod.status.toUpperCase() })
                            ],
                            link: `/producers/${prod.id}`,
                        }))
                    }}
                    searchFields={[
                        {
                            options: this.state.licensees,
                            name: 'licensee',
                            placeholder: this.props.intl.formatMessage({id:'PRODUCERS.SELECT_ONE'}),
                            value: this.state.licensee,
                        },
                    ]}
                >
                </StarterList>
            </div>
        );
    }
}

export default injectIntl(ProducersList);