import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {store} from '../../common/redux/StoreReducers';
import ColumnContainer from './ColumnContainer';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import API from '../../common/utils/API';

import LoadingButton from '../../components/form/LoadingButton';

import * as FeatherIcons from 'react-icons/fi';

class LoginContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            email: '',
            password: '',
            error: null,
        };
    }

    handleLoginFormSubmit = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
        });
        API.session
            .login(this.state.email, this.state.password)
            .then((response) => {
                store.dispatch(
                    this.props.login(
                        response.user,
                        response.token,
                        response.refreshToken,
                    ),
                );
            })
            .catch((e) => {
                console.log(e.response.data.messages.error);
                this.setState({
                    error: e.response.data.messages.error,
                    loading: false,
                });
            });
    };

    render() {
        return (
            <ColumnContainer>
                <Form onSubmit={this.handleLoginFormSubmit}>
                    <Form.Group>
                        <h3 className='text-dark'>
                            {this.props.intl.formatMessage({
                                id: 'AUTH.LOGIN.LOGIN_TITLE',
                            })}
                        </h3>
                        <hr />
                    </Form.Group>

                    <Alert
                        variant='danger'
                        onClose={() => this.setState({error: null})}
                        dismissible
                        show={this.state.error}
                    >
                        <FeatherIcons.FiAlertCircle
                            size='20'
                            className='mb-3'
                        />
                        <Alert.Heading as='h5'>
                            {this.state.error
                                ? 'Error'
                                : this.props.intl.formatMessage({
                                      id:
                                          'AUTH.LOGIN.INVALID_INFORMATION_ERROR_TITLE',
                                  })}
                        </Alert.Heading>
                        <p className='mb-0'>
                            {this.state.error ||
                                this.props.intl.formatMessage({
                                    id:
                                        'AUTH.LOGIN.INVALID_INFORMATION_ERROR_MESSAGE',
                                })}
                        </p>
                    </Alert>

                    <Form.Group controlId='formBasicEmail'>
                        <Form.Label>
                            {this.props.intl.formatMessage({
                                id: 'AUTH.LOGIN.EMAIL_ADDRESS',
                            })}
                        </Form.Label>
                        <Form.Control
                            type='email'
                            placeholder={this.props.intl.formatMessage({
                                id:
                                    'AUTH.LOGIN.EMAIL_ADDRESS_FIELD_PLACEHOLDER',
                            })}
                            value={this.state.email}
                            onChange={(e) =>
                                this.setState({email: e.target.value})
                            }
                        />
                    </Form.Group>
                    <Form.Group controlId='formBasicPassword'>
                        <Form.Label>
                            {this.props.intl.formatMessage({
                                id: 'AUTH.LOGIN.PASSWORD',
                            })}
                        </Form.Label>
                        <Form.Control
                            type='password'
                            placeholder={this.props.intl.formatMessage({
                                id: 'AUTH.LOGIN.PASSWORD_FIELD_PLACEHOLDER',
                            })}
                            value={this.state.password}
                            onChange={(e) =>
                                this.setState({password: e.target.value})
                            }
                        />
                    </Form.Group>
                    <Form.Group className='text-right'>
                        <Button
                            variant='link'
                            className='px-0'
                            href='/forgot-password'
                        >
                            {this.props.intl.formatMessage({
                                id: 'AUTH.LOGIN.FORGOT_PASSWORD_LINK',
                            })}
                        </Button>
                    </Form.Group>
                    <LoadingButton
                        variant='primary'
                        type='submit'
                        loading={this.state.loading}
                        block
                    >
                        {this.props.intl.formatMessage({
                            id: 'AUTH.LOGIN.LOGIN_BUTTON',
                        })}
                    </LoadingButton>
                </Form>
            </ColumnContainer>
        );
    }
}

const login = function (user, token, refreshToken) {
    return {
        type: 'LOGIN',
        payload: {
            user: user,
            token: token,
            refreshToken: refreshToken,
        },
    };
};

const mapDispatchToProps = {
    login: login,
};

export default connect(null, mapDispatchToProps)(injectIntl(LoginContainer));
