import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import VivariumsList from './VivariumsList';
import withLayout from '../../components/ui/Layout';
import VivariumDetail from './VivariumDetail';
import CreateVivarium from './CreateVivarium';
import EditVivarium from './EditVivarium';
function VivariumsRoutes() {
    const intl = useIntl();

    return (
        <Switch data-test='vivariumsComponent'>
            <PrivateRoute  permission='VARIETIES_INDEX'
                exact
                path='/vivariums'
                component={withLayout(
                    VivariumsList,
                    intl.formatMessage({id: 'ROUTES.VIVARIUMS.INDEX'}),
                )}
            />
            <PrivateRoute  permission='VARIETIES_CREATE'
                exact
                path='/vivariums/create'
                component={withLayout(
                    CreateVivarium,
                    intl.formatMessage({id: 'ROUTES.VIVARIUMS.CREATE'}),
                )}
            />
            <PrivateRoute  permission='VARIETIES_SHOW'
                exact
                path='/vivariums/:id'
                component={withLayout(
                    VivariumDetail,
                    intl.formatMessage({id: 'ROUTES.VIVARIUMS.SHOW'}),
                )}
            />

            <PrivateRoute  permission='VIVARIUMS_EDIT'
                exact
                path='/vivariums/:id/edit'
                component={withLayout(
                    EditVivarium,
                    intl.formatMessage({id: 'ROUTES.VIVARIUMS.EDIT'}),
                )}
            />
        </Switch>
    );
}

export default VivariumsRoutes;
