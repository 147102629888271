import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';

import API from '../../common/utils/API';
import Form from '../../components/form/varieties/VarietyForm';
import Variety from '../../common/models/Variety';

export class CreateVariety extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            variety: new Variety(),
            loading: false,
            shouldRedirectToIndex: false,
            plants: [],
            errorMessage: '',
        };
    };

    async componentDidMount() {
        return this.fetchPlants();
    };

    onSubmit = async (input) => {
        try {
            await API.varieties.save(input);
            this.setState({
                loading: false,
                shouldRedirectToIndex: true,
            });
        } catch (e) {
            if (e.response && e.response.status === 422) {
                const errorMessage = Object.values(
                    e.response.data.messages,
                ).join('\n');

                this.setState({
                    loading: false,
                    errorMessage,
                });
            } else {
                this.setState({
                    loading: false,
                    errorMessage: this.props.intl.formatMessage({
                        id: 'ERROR_PAGES.STORING_DATA',
                    }),
                });
            }
        }
    };

    fetchPlants = async () => {
        try {
            const plants = await API.varieties.allPlants();
            this.setState({
                plants: plants
            });
        } catch (error) {
            this.setState({
                error: false
            });
        }
    };

    render() {
        const initialValues = {
            plant: this.state.variety.plant.id,
            name: this.state.variety.name,
        };
        const plants = this.state.plants.map((plant) =>({
            id: plant.id,
            name: plant.name
        }) )
        return this.state.shouldRedirectToIndex ? (
            <Redirect
                data-test='redirectToIndex'
                to={{
                    pathname: '/varieties',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id: 'USERS.USER_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.errorMessage}
                >
                    <p className='mb-0'>{this.state.errorMessage}</p>
                </Alert>
                <Form data-test='createVarietyComponent'
                    initialValues={initialValues}
                    backRedirectUrl='/varieties'
                    backText={this.props.intl.formatMessage({id: 'LIST.BACK_TO_LIST'})}
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                    plants={plants}
                />
            </>
        );
    };
}

export default injectIntl(CreateVariety);
