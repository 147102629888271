import { Formik } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import * as Yup from 'yup';
import {Card, Col, Button} from 'react-bootstrap'; 
import BaseForm from 'react-bootstrap/Form';
import Input from "../Input";
import LoadingButton from "../LoadingButton";
import ConfirmModal from "../ConfirmModal";
import Can from "../../../common/security/Can";


export default function Form(props){
    const intl  = useIntl();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const validationSchema = Yup.object({
        name: Yup.string().required(
            intl.formatMessage({id: 'MUNICIPALITIES.VALIDATION.NAME_MISSING'})
        ),
        stateId: Yup.number().required(
            intl.formatMessage({id: 'MUNICIPALITIES.VALIDATION.STATE_MISSING'})
        )
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const handleToggleIsDeleteModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const handleConfirmDelete = () => {
        props.handleDeleteMunicipality();
        handleToggleIsDeleteModal();
    };

    return (
        <Formik data-test="municipalityFormComponent"
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
        {({handleSubmit, handleChange, values, errors}) =>(
            <>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button data-test='backButton'
                        as={NavLink}
                        to={props.backRedirectUrl}
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {props.backText}
                    </Button>
                </div>

                <BaseForm onSubmit={handleSubmit}>
                    <Card className='mb-4'>
                        <Card.Header>
                            {intl.formatMessage({
                                id: 'STATES.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>

                        <Card.Body>
                            <BaseForm.Row>
                                <Col md='12'>
                                    <BaseForm.Row>
                                        <Input md='12'
                                            type='select'
                                            label={intl.formatMessage({
                                                id: 'MUNICIPALITIES.STATE_NAME_FIELD_LABEL',
                                            })}
                                            placeholder={intl.formatMessage({
                                                id: 'MUNICIPALITIES.SELECT_STATE_FIRST_OPTION'
                                            })}
                                            name='stateId'
                                            value={values.stateId}
                                            onChange={handleChange}
                                            error={errors.stateId}
                                            isInvalid={errors.stateId}
                                            options={props.states.map((state) => ({ value: state.id, label: state.name }))}
                                        />
                                    </BaseForm.Row>

                                    <BaseForm.Row>
                                        <Input
                                            md='12'
                                            type='text'
                                            label={intl.formatMessage({
                                                id: 'MUNICIPALITIES.MUNICIPALITY_NAME_FIELD_LABEL'}
                                            )}
                                            placeholder={intl.formatMessage(
                                                { id: 'MUNICIPALITIES.MUNICIPALITY_NAME_FIELD_PLACEHOLDER'},
                                            )}
                                            name='name'
                                            value={values.name}
                                            onChange={handleChange}
                                            error={errors.name}
                                            isInvalid={errors.name}
                                        />
                                    </BaseForm.Row>

                                </Col>
                            </BaseForm.Row>
                        </Card.Body>
                    </Card>
                </BaseForm>

                {props.isEdit ? (
                    <div>
                        <div>
                            <Can run='MUNICIPALITY_DELETE' cantRun={<div />}>
                                <ConfirmModal
                                    titleModal={intl.formatMessage({
                                        id:
                                            'MUNICIPALITIES.DELETE_MUNICIPALITY_MODAL_TITLE',
                                    })}

                                    handleConfirm = {
                                        handleConfirmDelete
                                    }
                                    handleShow={
                                        handleToggleIsDeleteModal
                                    }
                                    handleClose={
                                        handleToggleIsDeleteModal
                                    }
                                    show={isDeleteModalOpen}
                                    variant='danger'
                                    buttonClassName='btn-bold'
                                    buttonLabel={intl.formatMessage(
                                        {id: 'MUNICIPALITIES.DELETE_MUNICIPALITY_BUTTON_LABEL'}
                                    )}
                                    buttonAcceptLabel={intl.formatMessage(
                                        { id:'MUNICIPALITIES.CONFIRM_DELETE_BUTTON_LABEL'},
                                    )}
                                    buttonCloseLabel={intl.formatMessage(
                                        { id: 'USERS.CANCEL_DELETE_BUTTON_LABEL'},
                                    )}
                                >
                                    <p>
                                        {intl.formatMessage({
                                            id:
                                                'MUNICIPALITIES.DELETE_MUNICIPALITY_CONFIRMATION_MESSAGE',
                                        })}
                                    </p>
                                </ConfirmModal>
                            </Can>
                        </div>
                    </div>
                ) : (<div/>)}


                    <div className='text-right'>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            type='submit'
                            loading={props.loading}
                            onClick={handleSubmit}
                        >
                            {intl.formatMessage({
                                id: 'GENERAL.SAVE_BUTTON_TEXT',
                            })}
                        </LoadingButton>
                    </div>

            </>
        )}
        </Formik>
    );
}