export default class Plant {
    constructor({
        id = 0,
        name = '',
        varieties = [],
    } = {}) {
        this.id = id;
        this.name = name;
        this.varieties = varieties;
    }
}
