import { store } from "../redux/StoreReducers";

export default class Property {
    constructor({
        id = 0,
        name = '',
        latitude = '',
        longitude = '',
        address = '',
        producer = {},
        hasWaterHole = false,
        propertyType = 'owned',
        area = '',
        masl = '',
        rainSeason = '',
        frostPerYear = '',
        latestCrops = '',
        boundaries = '',
        certificateValidity = '',
        lavatories = '',
        sinks = '',
        diningRooms = '',
        trashCans = '',
        signs = '',
        recycleCans = '',
        wasteAreas = '',
        incomePolicies = '',
        cnaConcesion = '',
        waterHoleDeepness = '',
        waterPumpCapacity = '',
        pipeDiamenter = '',
        litersPerSecond = 0,
        waterTanks = '',
        tanksCapacity = '',
        status = '',
        irrigationSystemType = '',
        subBrand = '',
        subComposition = '',
        subMix = '',
        subIrrigationSectors = '',
        subAnnualConsumption = '',
        subTotalRequirement = '',
        convHose = '',
        convController = '',
        convWaterPump = '',
        convTanks = '',
        convIrrigationHead = '',
        convFilters = '',
        convValves = '',
        flowerpot = '',
        picketsPerPlant = '',
        certificateType = {},
        soilType = {},
        waterType = {},
        isCertificated = false,
        sectors = [],
    } = {}) {
        this.id = id;
        this.name = name;
        this.latitude = latitude;
        this.longitude = longitude;
        this.address = address;
        this.producer = producer;
        this.propertyType = propertyType.toUpperCase();
        this.hasWaterHole = hasWaterHole === true;
        this.latitude = latitude;
        this.longitude = longitude;
        this.area = area;
        this.masl = masl;
        this.rainSeason = rainSeason;
        this.frostPerYear = frostPerYear;
        this.latestCrops = latestCrops;
        this.boundaries = boundaries;
        this.certificateValidity = certificateValidity;
        this.certificateValidityFormatted =  certificateValidity ? new Date(certificateValidity)
            .toLocaleDateString(store.getState().langReducer.lang,{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
            : certificateValidity;
        this.lavatories = lavatories;
        this.sinks = sinks;
        this.diningRooms = diningRooms;
        this.trashCans = trashCans;
        this.signs = signs;
        this.recycleCans = recycleCans;
        this.wasteAreas = wasteAreas;
        this.incomePolicies = incomePolicies;
        this.cnaConcesion = cnaConcesion || '';
        this.waterHoleDeepness = waterHoleDeepness|| '';
        this.waterPumpCapacity = waterPumpCapacity|| '';
        this.pipeDiamenter = pipeDiamenter|| '';
        this.litersPerSecond = litersPerSecond || 0;
        this.waterTanks = waterTanks || '';
        this.tanksCapacity = tanksCapacity || '';
        this.status = status;
        this.irrigationSystemType = irrigationSystemType;
        this.subBrand = subBrand || '';
        this.subComposition = subComposition || '';
        this.subMix = subMix || '';
        this.subIrrigationSectors = subIrrigationSectors || '';
        this.subAnnualConsumption = subAnnualConsumption || '';
        this.subTotalRequirement = subTotalRequirement || '';
        this.convHose = convHose || '';
        this.convController = convController || '';
        this.convWaterPump = convWaterPump || '';
        this.convTanks = convTanks || '';
        this.convIrrigationHead = convIrrigationHead || '';
        this.convFilters = convFilters || '';
        this.convValves = convValves || '';
        this.flowerpot = flowerpot || '';
        this.picketsPerPlant = picketsPerPlant || '';
        this.certificateType = certificateType;
        this.soilType = soilType;
        this.waterType = waterType;
        this.isCertificated = isCertificated;
        this.sectors = sectors.map((item) => ({
            name: item.variety,
            alias: item.name,
            surface: item.surface,
            id: item.varietyId,
        }));
    }
}