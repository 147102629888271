import { store } from "../redux/StoreReducers";
export default class Production {
    constructor({
        id = 0,
        licensee= '',
        producer = '',
        property ='',
        plantationDate = '',
        createdBy = '',
        deliveryId = '',
        cultivatedHectares = '',
        density = '',
        distanceBetweenGrooves = '',
        distanceBetweenPlants = '',
        large = '',
        latitude = '',
        licenseeId = '',
        longitude = '',
        plant = '',
        plantationFramework = '',
        plantsByHectare = '',
        producerId = '',
        productionCycle = '',
        propertyId = '',
        qrCode = '',
        qrCodeUrl = '',
        totalPlants = '',
        vivarium = '',
        vivariumId = '',
        width = '',
        address = ''
    } = {}) {
        this.id = id;
        this.licensee = licensee;
        this.producer = producer;
        this.property = property;
        this.plantationDateFormatted = plantationDate 
            ? new Date(plantationDate)
                .toLocaleDateString(store.getState().langReducer.lang, {
                    weekday: 'long', 
                    year: 'numeric', 
                    month: 'long', 
                    day: 'numeric' 
                }) : plantationDate;
        this.plantationDate = plantationDate;
        this.createdBy = createdBy;
        this.deliveryId = deliveryId;
        this.cultivatedHectares= cultivatedHectares;
        this.density= density;
        this.distanceBetweenGrooves= distanceBetweenGrooves;
        this.distanceBetweenPlants= distanceBetweenPlants;
        this.large= large;
        this.latitude= latitude;
        this.licensee= licensee;
        this.licenseeId= licenseeId;
        this.longitude= longitude;
        this.plant= plant;
        this.plantationDate= plantationDate;
        this.plantationFramework= plantationFramework;
        this.plantsByHectare= plantsByHectare;
        this.producer= producer;
        this.producerId= producerId;
        this.productionCycle= productionCycle;
        this.property= property;
        this.propertyId= propertyId;
        this.qrCode= qrCode;
        this.qrCodeUrl= qrCodeUrl;
        this.totalPlants= totalPlants;
        this.vivarium= vivarium;
        this.vivariumId= vivariumId;
        this.width= width;
        this.address = address;
    }
}
