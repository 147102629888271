import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

function Datepicker(props) {
    let date = props.value ? new Date(props.value) : null;
    let minDate = props.minDate ? new Date(props.minDate) : null;
    let maxDate = props.maxDate ? new Date(props.maxDate) : null;

    if (date) {
        date = new Date(date.getTime() - date.getTimezoneOffset() * -60000);
    }

    if (minDate) {
        minDate.setDate(minDate.getDate() + 1);
        minDate = new Date(
            minDate.getTime() - minDate.getTimezoneOffset() * -60000,
        );
    }

    if (maxDate) {
        maxDate.setDate(maxDate.getDate() - 1);
        maxDate = new Date(
            maxDate.getTime() - maxDate.getTimezoneOffset() * -60000,
        );
    }

    return (
        <DatePicker
            selected={date}
            onChange={props.onChange}
            placeholderText={props.placeholder}
            dateFormat={props.format ? props.format : 'dd/MM/yyyy'}
            minDate={minDate}
            maxDate={maxDate}
            className='form-control'
        />
    );
}

export default Datepicker;
