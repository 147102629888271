import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Role from '../../models/Role';

const roles = {
    get: async (pageNumber = 1, keyword = '') => {
        const {data: response} = await API.get(
            `/roles?page=${pageNumber}&search=${keyword}`,
        );

        const list = new PaginatedList();

        list.items = response.data.map((role) => new Role(role));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    getAll: async () => {
        const {data: response} = await API.get('/roles/all');

        return {
            roles: response.data,
        };
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/roles/${id}`);

        return {
            role: new Role(response.data),
        };
    },
    save: async (params) => {
        await API.post(`/roles`, params);

        return {};
    },
    update: async (id, params) => {
        await API.put(`/roles/${id}`, params);

        return {};
    },
    delete: async (id) => {
        await API.delete(`/roles/${id}`);

        return {};
    },
    constants: {
        ALL: 0,
    },
};

export default roles;
