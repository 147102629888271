import { store } from "../redux/StoreReducers";

export default class Delivery {
    constructor({
        id = '',
        licensee = '',
        vivarium = '',
        estimatedDeliveryDate = undefined,
        deliveryDate = undefined,
        quantity = '',
        status = '',
        licenseeDetail = {},
        vivariumDetail = {},
        varieties = [],
    } = {}) {
        this.id = id;
        this.licensee = licensee;
        this.vivarium = vivarium;
        this.estimatedDeliveryDate = estimatedDeliveryDate 
            ? new Date(estimatedDeliveryDate)
                .toLocaleDateString(store.getState().langReducer.lang,{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                : estimatedDeliveryDate;
        this.deliveryDate = deliveryDate 
            ? new Date(deliveryDate)
                .toLocaleDateString(store.getState().langReducer.lang,{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                : deliveryDate;
        this.estimatedDeliveryDateEdit = estimatedDeliveryDate;
        this.quantity = quantity;
        this.varieties = varieties;
        this.status = status;
        this.licenseeDetail = licenseeDetail;
        this.vivariumDetail = vivariumDetail;
        this.varieties = varieties;
    }
}