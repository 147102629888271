import React from 'react';
import { injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Row, Col} from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import LoadingButton from '../../components/form/LoadingButton';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import Producer from '../../common/models/Producer';
import ProducerPropertiesList from './ProducerPropertiesList';

export class ProducerDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            producer: new Producer(),
            banks: [],
            properties: [],
            loading: false,
            isToggleUserStatusModalOpen: false,
            isLoadErrorModalOpen: false,
            isDeleteModalOpen: false,
            error: '',
        }
    }

    async componentDidMount() {
        await this.loadDetail();
        await this.loadProperties();
    }

    loadDetail = async () => {
        this.setState({
            loading: true,
        });
        try {
            const producer = await API.producers.detail(this.props.match.params.id);
            this.setState({
                producer,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (error) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    }

    loadBanks = async () => {
        this.setState({
            loading: true,
        });
        try {
            const banks = await API.banks.all();
            this.setState({
                banks,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (error) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    }

    loadProperties = async () => {
        this.setState({
            loading: true,
        });

        try {
            const properties = await API.properties.getByProducer(this.props.match.params.id);
            this.setState({
                properties,
                loading: false.valueOf,
                isLoadErrorModalOpen: false,
            })
        } catch (error) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    }

    openModalDelete = () =>{
        this.setState({
            isDeleteModalOpen: true,
        })
    }

    render() {
        return (
            <>
                <Alert
                    data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}>

                </Alert>

                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/producers'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>

                <Card data-test='producerDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'USERS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row className='mb-3'>
                            <Col md={2}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_FISCALTYPE_LABEL'})}
                                </label>
                                <p>{this.state.producer.fiscalType ?? this.props.intl.formatMessage({id:'PRODUCERS.FISCAL_FIGURE_' + this.state.producer.fiscalType.toUpperCase()})}</p>
                            </Col>
                            <Col md={2}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_TYPE_LABEL'})}
                                </label>
                                <p>{this.state.producer.type ?? this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_' + this.state.producer.type.toUpperCase()})}</p>
                            </Col>
                            <Col md={3}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_LICENSEE_LABEL'})}
                                </label>
                                <p>{this.state.producer.createdBy.firstName + ' '
                                +this.state.producer.createdBy.lastName}</p>
                            </Col>
                            <Col md={3}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_STATUS_LABEL'})}
                                </label>
                                <p>{this.state.producer.status ?? this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_STATUS_' + this.state.producer.status.toUpperCase()})}</p>
                            </Col>
                            <Col md={2}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_REQUIRESFINANCING_LABEL'})}
                                </label>
                                <p>{this.state.producer.requiresFinancing ?
                                    this.props.intl.formatMessage({id: 'PRODUCERS.VALUE_TRUE'}):
                                    this.props.intl.formatMessage({id: 'PRODUCERS.VALUE_FALSE'})
                                }</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_FIRSTNAME_LABEL'})}
                                </label>
                                <p>{this.state.producer.firstName}</p>
                            </Col>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_LASTNAME_LABEL'})}
                                </label>
                                <p>{this.state.producer.lastName}</p>
                            </Col>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_MIDDLELASTNAME_LABEL'})}
                                </label>
                                <p>{this.state.producer.middleLastName}</p>
                            </Col>

                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_BUSINESSNAME_LABEL'})}
                                </label>
                                <p>{this.state.producer.businessName}</p>
                            </Col>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_EMAIL_LABEL'})}
                                </label>
                                <p><a href={"mailto:"+this.state.producer.email}>{this.state.producer.email}</a></p>
                            </Col>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_PHONE_LABEL'})}
                                </label>
                                <p>{this.state.producer.phone}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mt-2'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'PRODUCERS.DETAIL_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_PLANT_LABEL'})}
                                </label>
                                <p>{this.state.producer.plant}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_VARIETY_LABEL'})}
                                </label>
                                <p>{this.state.producer.variety}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_HECTARES_LABEL'})}
                                </label>
                                <p>{this.state.producer.hectares}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_PLANTSPERHECTARES_LABEL'})}
                                </label>
                                <p>{this.state.producer.plantsPerHectare}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mt-2'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'PRODUCERS.DETAIL_TITLE2',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_HASEXPERIENCE_LABEL'})}
                                </label>
                                <p>{this.state.producer.hasExperience ?
                                    this.props.intl.formatMessage({id: 'PRODUCERS.VALUE_TRUE'}):
                                    this.props.intl.formatMessage({id: 'PRODUCERS.VALUE_FALSE'})
                                }</p>
                            </Col>
                        </Row>
                        <Row>
                            { this.state.producer.hasExperience ? 
                            <>
                                <Col md={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_EXPERIENCE_LABEL'})}
                                    </label>
                                    <p>
                                        {this.state.producer.experience}
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_EXPERIENCETIME_LABEL'})}
                                    </label>
                                    <p>
                                        {this.state.producer.experienceTime}
                                    </p>
                                </Col>
                            </>
                            :<></>}
                        </Row>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_HASPRODUCTIONMANAGER_LABEL'})}
                                </label>
                                <p>{this.state.producer.hasProductionManager ?
                                    this.props.intl.formatMessage({id: 'PRODUCERS.VALUE_TRUE'}):
                                    this.props.intl.formatMessage({id: 'PRODUCERS.VALUE_FALSE'})
                                }</p>
                            </Col>
                        </Row>
                        <Row>
                            { this.state.producer.hasProductionManager ?
                            <>
                                <Col md={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_BUSSINESPARTNERS_LABEL'})}
                                    </label>
                                    <p>{this.state.producer.bussinesPartners}</p>
                                </Col>
                                <Col md={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_REASONCHANGECULTIVE_LABEL'})}
                                    </label>
                                    <p>{this.state.producer.reasonChangeCultive}</p>
                                </Col>
                                <Col md={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_SEASONSWORKING_LABEL'})}
                                    </label>
                                    <p>{this.state.producer.seasonsWorking}</p>
                                </Col>
                                <Col md={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_PRODUCERCODEOWN_LABEL'})}
                                    </label>
                                    <p>{this.state.producer.producerCodeOwn}</p>
                                </Col>
                            </>
                            :<></>
                            }
                        </Row>
                    </Card.Body>
                </Card>

                <Card className='mt-2'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'PRODUCERS.DETAIL_TITLE3',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_EMPLOYEES_LABEL'})}
                                </label>
                                <p>{this.state.producer.employees}</p>
                            </Col>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_RECRUITSOURCE_LABEL'})}
                                </label>
                                <p>{this.state.producer.recruitSource}</p>
                            </Col>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_AVERAGESALARY_LABEL'})}
                                </label>
                                <p>
                                    ${this.props.intl.formatNumber(this.state.producer.averageSalary,{style:"currency", currency:"MXP"})}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_HASOFFICES_LABEL'})}
                                </label>
                                <p>{this.state.producer.hasOffices ?
                                    this.props.intl.formatMessage({id: 'PRODUCERS.VALUE_TRUE'}):
                                    this.props.intl.formatMessage({id: 'PRODUCERS.VALUE_FALSE'})
                                }</p>
                            </Col>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_RESOURCESORIGIN_LABEL'})}
                                </label>
                                <p>
                                    {this.state.producer.resourcesOrigin}
                                </p>
                            </Col>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_BUDGETAVAILABLE_LABEL'})}
                                </label>
                                <p>
                                    ${this.props.intl.formatNumber(this.state.producer.budgetAvailable,{style:"currency", currency:"MXP",locale:"es_MX"})}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_BANK_LABEL'})}
                                </label>
                                <p>
                                    {this.state.producer.bank.name}
                                </p>
                            </Col>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_HASCOLDROOM_LABEL'})}
                                </label>
                                <p>{this.state.producer.hasColdRoom ?
                                    this.props.intl.formatMessage({id: 'PRODUCERS.VALUE_TRUE'}):
                                    this.props.intl.formatMessage({id: 'PRODUCERS.VALUE_FALSE'})
                                }</p>
                            </Col>
                            <Col md={4}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({id:'PRODUCERS.PRODUCER_HASREFRIGERATORTRUCK_LABEL'})}
                                </label>
                                <p>{this.state.producer.hasRefrigeratorTruck ?
                                    this.props.intl.formatMessage({id: 'PRODUCERS.VALUE_TRUE'}):
                                    this.props.intl.formatMessage({id: 'PRODUCERS.VALUE_FALSE'})
                                }</p>
                            </Col>
                        </Row>

                    </Card.Body>
                    <Card.Body>
                        <p>
                            {this.props.intl.formatMessage({id: 'PRODUCERS.SIGNATURE_TITLE'})}
                        </p>
                        {this.state.producer.signature ? 
                        <>
                            <img alt={this.state.producer.firstName}
                                src={this.state.producer.signature}
                            />
                        </>:<></>}
                    </Card.Body>
                </Card>

                <ProducerPropertiesList properties={this.state.properties}></ProducerPropertiesList>
                <div className='d-flex justify-content-between'>
                    <div>
                        <Can run='PRODUCERS_EDIT'>
                            <Button
                                as={NavLink}
                                to={`/producers/${this.props.match.params.id}/edit`}
                                className='btn-warning btn-bold ml-2'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'PRODUCERS.BUTTON_EDIT_LABEL',
                                })}
                            </Button>
                        </Can>
                    </div>
                </div>

                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'PRODUCERS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'PLANTS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/varieties'
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.loadDetail}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(ProducerDetail);