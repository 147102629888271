import * as Yup from 'yup';
import { irrigationSystemType } from './Types';
import { useIntl } from 'react-intl';

export default function Validation(props) {
    const intl = useIntl();
    return Yup.object({
        producer: Yup.number().min(1).required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_PRODUCER'})
        ),
        propertyType: Yup.string().required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_PROPERTYTYPE'})
        ),
        address: Yup.string().min(10).required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_ADDRESS'})
        ),
        latitude: Yup.number().min(-90).max(90).required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_LATLNG'})
        ),
        longitude: Yup.number().min(-180).max(180).required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_LATLNG'})
        ),
        name: Yup.string().required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_NAME'})
        ),

        area: Yup.number().min(1).required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_AREA'})
        ),
        masl: Yup.number().min(0).required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_MASL'})
        ),
        soilType: Yup.number().min(1).required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_SOILTYPE'})
        ),
        waterType: Yup.number().min(1).required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_WATERTYPE'})
        ),
        rainSeason: Yup.string().min(3).required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_RAINSEASON'})
        ),
        frostPerYear: Yup.string().min(3).required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_FROSTPERYEAR'})
        ),
        boundaries: Yup.string().min(3).required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_BOUNDARIES'})
        ),
        latestCrops: Yup.string().min(3).required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_LATESTCROPS'})
        ),
        isCertificated: Yup.boolean().required().default(false),
        certificateType: Yup.number().when('isCertificated',{
            is: true,
            then: Yup.number().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_CERTIFICATETYPE'})
            ).min(1),
            otherwise: Yup.number()
            }),
        certificateValidity: Yup.date().when('isCertificated',{
            is: true,
            then: Yup.date().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_CERTIFICATEVALIDITY'})
            ),
            otherwise: Yup.date()
            }),
        estimatedCertificationDate: Yup.date().when('isCertificated',{
            is: true,
            then: Yup.date(),
            otherwise: Yup.date().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_ESTIMATEDCERTIFICATEDATE'})
            )
        }),
        lavatories: Yup.boolean().required().default(false),
        sinks: Yup.boolean().required().default(false),
        diningRooms: Yup.boolean().required().default(false),
        trashCans: Yup.boolean().required().default(false),
        signs: Yup.boolean().required().default(false),
        recycleCans: Yup.boolean().required().default(false),
        wasteAreas: Yup.boolean().required().default(false),
        incomePolicies: Yup.boolean().required().default(false),

        hasWaterHole: Yup.boolean().required().default(false),
        cnaConcesion: Yup.string().when('hasWaterHole', {
            is: true,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_CNACONCESION'})
            ),
            otherwise: Yup.string()
        }),

        waterHoleDeepness: Yup.string().when('hasWaterHole', {
            is: true,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_WATERHOLEDEEPNESS'})
            ),
            otherwise: Yup.string()
        }),
        waterPumpCapacity: Yup.string().when('hasWaterHole', {
            is: true,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_WATERPUMPCAPACITY'})
            ),
            otherwise: Yup.string()
        }),
        pipeDiamenter: Yup.string().when('hasWaterHole', {
            is: true,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_PIPEDIAMETER'})
            ),
            otherwise: Yup.string()
        }),
        litersPerSecond: Yup.number().when('hasWaterHole', {
            is: true,
            then: Yup.number().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_LITERSPERSECOND'})
            ),
            otherwise: Yup.number()
        }),
        waterTanks: Yup.number().required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_WATERTANKS'})
        ),
        tanksCapacity: Yup.number().required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_TANKSCAPACITY'})
        ),

        irrigationSystemType: Yup.string().required(
            intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_IRRIGATIONSYSTEMTYPE'})
        ),
        subBrand: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.SUBSTRATUM,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_SUBBRAND'})
            ),
            otherwise: Yup.string()
        }),
        subComposition: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.SUBSTRATUM,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_SUBCOMPOSITION'})
            ),
            otherwise: Yup.string()
        }),
        subMix: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.SUBSTRATUM,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_SUBMIX'})
            ),
            otherwise: Yup.string()
        }),
        subIrrigationSectors: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.SUBSTRATUM,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_SUBIRRIGATIONSECTORS'})
            ),
            otherwise: Yup.string()
        }),
        subAnnualConsumption: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.SUBSTRATUM,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_SUBANNUALCONSUMPTION'})
            ),
            otherwise: Yup.string()
        }),
        subTotalRequirement: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.SUBSTRATUM,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_SUBTOTALREQUIREMENT'})
            ),
            otherwise: Yup.string()
        }),
        convHose: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.CONVENTIONAL,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_CONVHOSE'})
            ),
            otherwise: Yup.string()
        }),
        convController: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.CONVENTIONAL,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_CONVCONTROLLER'})
            ),
            otherwise: Yup.string()
        }),
        convWaterPump: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.CONVENTIONAL,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_CONVWATERPUMP'})
            ),
            otherwise: Yup.string()
        }),
        convTanks: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.CONVENTIONAL,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_CONVTANKS'})
            ),
            otherwise: Yup.string()
        }),
        convIrrigationHead: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.CONVENTIONAL,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_CONVIRRIGATIONHEAD'})
            ),
            otherwise: Yup.string()
        }),
        convFilters: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.CONVENTIONAL,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_CONVFILTERS'})
            ),
            otherwise: Yup.string()
        }),
        convValves: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.CONVENTIONAL,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_CONVVALVES'})
            ),
            otherwise: Yup.string()
        }),

        flowerpot: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.HYDROPONICS,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_FLOWERPOT'})
            ),
            otherwise: Yup.string()
        }),
        picketsPerPlant: Yup.string().when('irrigationSystemType', {
            is: irrigationSystemType.HYDROPONICS,
            then: Yup.string().required(
                intl.formatMessage({id: 'PROPERTIES.FORM_MISSING_PICKETSPERPLANT'})
            ),
            otherwise: Yup.string()
        })
    });
}