import React from 'react';
import {Formik} from 'formik';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Button, Card, Col} from 'react-bootstrap';
import BaseForm from 'react-bootstrap/Form';
import Input from '../Input';
import MapContainer from 'components/maps/Map2';
import Validation from './Validation';
import {} from './Types';
import LoadingButton from '../LoadingButton';
import Datepicker from '../DatePicker';
import {formatISODate} from '../../../common/utils/helpers';
import {irrigationSystemType} from './Types';
import {FaTrashAlt} from 'react-icons/fa';
import Forms from 'react-bootstrap/Form';

export default function Form(props) {
  const intl = useIntl();
  const validationSchema = Validation();
  const onSubmit = (input) => {
    props.handleSubmit(input);
  };

  const changeVariety = (event) =>{
    props.changeDetail(event.target.value, 'variety');
  }

  const changeSurface = (event) => {
      props.changeDetail(event.target.value, 'surface');
  }

  const changeAlias = (event) => {
      props.changeDetail(event.target.value, 'alias');
  }

  return (
    <Formik
      data-test='propertyFormComponent'
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({handleSubmit, handleChange, values, errors, setFieldValue}) => (
        <>
          <div className='mb-4 d-flex justify-content-end'>
            <Button
              data-test='backButton'
              as={NavLink}
              to={props.backRedirectUrl}
              className='btn btn-dark btn-bold btn-light-dark'
            >
              {props.backText}
            </Button>
          </div>
          <BaseForm>
            <Card className='mb-4'>
              <Card.Header>
                {intl.formatMessage({
                  id: 'PROPERTIES.GENERAL_INFORMATION',
                })}
              </Card.Header>
              <Card.Body>
                <BaseForm.Row>
                  <Input
                    md='4'
                    type='text'
                    name='name'
                    value={values.name}
                    onChange={handleChange}
                    error={errors.name}
                    isInvalid={errors.name}
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_NAME_FIELD_LABEL',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_NAME_FIELD_PLACEHOLDER',
                    })}
                  ></Input>
                  <Input
                    md='4'
                    type='select'
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_PRODUCER_FIELD_LABEL',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_PRODUCER_FIELD_PLACEHOLDER',
                    })}
                    name='producer'
                    value={values.producerId}
                    onChange={handleChange}
                    error={errors.producer}
                    isInvalid={errors.producer}
                    options={props.producers.map((producer) => ({
                      value: producer.id,
                      label: producer.fullName,
                    }))}
                  />
                  <Input
                    md='4'
                    type='select'
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_TYPE_FIELD_LABEL',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_GENERAL_SELECT_PLACEHOLDER',
                    })}
                    name='propertyType'
                    value={values.propertyType}
                    onChange={handleChange}
                    error={errors.propertyType}
                    isInvalid={errors.propertyType}
                    options={props.propertyTypes.map((type) => ({
                      value: type.id,
                      label: intl.formatMessage({
                        id: `PROPERTIES.PROPERTY_TYPE_${type.name.toUpperCase()}`,
                      }),
                    }))}
                  />
                </BaseForm.Row>
              </Card.Body>
              <Card.Header>
                {intl.formatMessage({
                  id: 'PROPERTIES.DETAIL_SECOND_TITLE',
                })}
              </Card.Header>
              <Card.Body>
                <BaseForm.Row>
                  <Input
                    md='4'
                    type='number'
                    name='area'
                    value={values.area}
                    onChange={handleChange}
                    error={errors.area}
                    isInvalid={errors.area}
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_AREA_FIELD_LABEL',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_AREA_FIELD_PLACEHOLDER',
                    })}
                  />
                  <Input
                    md='4'
                    type='number'
                    name='masl'
                    value={values.masl}
                    onChange={handleChange}
                    error={errors.masl}
                    isInvalid={errors.masl}
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_MSNM_FIELD_LABEL',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_MSNM_FIELD_PLACEHOLDER',
                    })}
                  />
                  <Input
                    md='4'
                    type='select'
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_GROUNDTYPE_FIELD_LABEL',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_GENERAL_SELECT_PLACEHOLDER',
                    })}
                    name='soilType'
                    value={values.soilType}
                    onChange={handleChange}
                    error={errors.soilType}
                    isInvalid={errors.soilType}
                    options={props.soilTypes.map((type) => ({
                      value: type.id,
                      label: type.name,
                    }))}
                  />
                </BaseForm.Row>
                <BaseForm.Row>
                  <Input
                    md='4'
                    type='select'
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_WATERTYPE_FIELD_LABEL',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_GENERAL_SELECT_PLACEHOLDER',
                    })}
                    name='waterType'
                    value={values.waterType}
                    onChange={handleChange}
                    error={errors.waterType}
                    isInvalid={errors.waterType}
                    options={props.waterTypes.map((type) => ({
                      value: type.id,
                      label: type.name,
                    }))}
                  />
                  <Input
                    md='4'
                    type='text'
                    name='rainSeason'
                    value={values.rainSeason}
                    onChange={handleChange}
                    error={errors.rainSeason}
                    isInvalid={errors.rainSeason}
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_RAINSEASON_FIELD_LABEL',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_RAINSEASON_FIELD_PLACEHOLDER',
                    })}
                  ></Input>
                  <Input
                    md='4'
                    type='text'
                    name='frostPerYear'
                    value={values.frostPerYear}
                    onChange={handleChange}
                    error={errors.frostPerYear}
                    isInvalid={errors.frostPerYear}
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_FROZENPERYEAR_FIELD_LABEL',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_FROZENPERYEAR_FIELD_PLACEHOLDER',
                    })}
                  ></Input>
                </BaseForm.Row>
              </Card.Body>
            </Card>

            <Card className='mb-4'>
              <Card.Header>
                <BaseForm.Row>
                  <Input
                    md='4'
                    type='text'
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_ADDRESS_FIELD_LABEL',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_ADDRESS_FIELD_LABEL',
                    })}
                    value={values.address}
                    onChange={handleChange}
                    error={errors.address}
                    name='address'
                    isInvalid={errors.address}
                  />
                  <Col md='4'>
                    <span>{errors.latitude || errors.longitude}</span>
                  </Col>
                </BaseForm.Row>
              </Card.Header>
              <Card.Body>
                <Col>
                  <div
                    style={{
                      height: `560px`,
                      width: `100% !important`,
                      border: '1px solid #999',
                    }}
                  >
                    <MapContainer
                      center={{
                        lat: values.latitude || 19.8798975,
                        lng: values.longitude || -103.6139883,
                      }}
                      API_KEY={process.env.REACT_APP_GOOGLE_API_KEY}
                      mapClick={(event) => {
                        if (event && event.lat && event.lng && event.address) {
                          setFieldValue('latitude', event.lat);
                          setFieldValue('longitude', event.lng);
                          setFieldValue('address', event.address);
                        }
                      }}
                    />
                  </div>
                </Col>
              </Card.Body>
            </Card>

            <Card className='mb-4'>
              <Card.Header>
                {intl.formatMessage({
                  id: 'PROPERTIES.DETAIL_THIRD_TITLE',
                })}
              </Card.Header>
              <Card.Body>
                <BaseForm.Row>
                  <Input
                    type='text'
                    md='6'
                    name='latestCrops'
                    value={values.latestCrops}
                    onChange={handleChange}
                    error={errors.latestCrops}
                    isInvalid={errors.latestCrops}
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_LASTESTCROPS_FIELD_LABEL',
                    })}
                  />
                  <Input
                    type='text'
                    md='6'
                    name='boundaries'
                    value={values.boundaries}
                    onChange={handleChange}
                    error={errors.boundaries}
                    isInvalid={errors.boundaries}
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_BOUNDARIES_FIELD_LABEL',
                    })}
                  />
                </BaseForm.Row>
                <BaseForm.Row>
                  <Input
                    className='mt-4'
                    type='switch'
                    id='isCertificated'
                    name='isCertificated'
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_HASCERTIFICATION_LABEL',
                    })}
                    onChange={() =>
                      setFieldValue('isCertificated', !values.isCertificated)
                    }
                    checked={values.isCertificated || false}
                  />
                  {values.isCertificated ? (
                    <>
                      <Col md={4}>
                        <p>
                          {intl.formatMessage({
                            id:
                              'PROPERTIES.PROPERTY_CERTIFICATEVALIDITY_FIELD_LABEL',
                          })}
                        </p>
                        <Datepicker
                          error={errors.certificateValidity}
                          isInvalid={errors.certificateValidity}
                          required
                          data-test='testDate'
                          value={values.certificateValidity}
                          name='certificateValidity'
                          onChange={(val) => {
                            setFieldValue(
                              'certificateValidity',
                              formatISODate(val),
                            );
                          }}
                        />
                        <small
                          show={errors.certificateValidity}
                          className='text-danger form-text'
                        >
                          {errors.certificateValidity}
                        </small>
                      </Col>
                      <Input
                        md='4'
                        type='select'
                        label={intl.formatMessage({
                          id: 'PROPERTIES.PROPERTY_CERTIFICATETYPE_FIELD_LABEL',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'PROPERTIES.PROPERTY_GENERAL_SELECT_PLACEHOLDER',
                        })}
                        name='certificateType'
                        value={values.certificateType}
                        onChange={handleChange}
                        error={errors.certificateType}
                        isInvalid={errors.certificateType}
                        options={props.certificateTypes.map((type) => ({
                          value: type.id,
                          label: type.name,
                        }))}
                      />
                    </>
                  ) : (
                    <>
                      <Col md={8}>
                        <p>
                          {intl.formatMessage({
                            id:
                              'PROPERTIES.PROPERTY_ESTIMATEDCERTIFICATIONDATE_FIELD_LABEL',
                          })}
                        </p>
                        <Datepicker
                          error={errors.estimatedCertificationDate}
                          isInvalid={errors.estimatedCertificationDate}
                          required
                          data-test='testDate'
                          value={values.estimatedCertificationDate}
                          name='estimatedCertificationDate'
                          onChange={(val) => {
                            setFieldValue(
                              'estimatedCertificationDate',
                              formatISODate(val),
                            );
                          }}
                        />
                        <small
                          show={errors.estimatedCertificationDate}
                          className='text-danger form-text'
                        >
                          {errors.estimatedCertificationDate}
                        </small>
                      </Col>
                    </>
                  )}
                </BaseForm.Row>
              </Card.Body>
              <Card.Body>
                <BaseForm.Row>
                  <p>
                    {intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_HAS_LABEL',
                    })}
                  </p>
                </BaseForm.Row>
                <BaseForm.Row>
                  <Input
                    md='3'
                    type='switch'
                    id='lavatories'
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_LAVATORIES_FIELD_LABEL',
                    })}
                    onChange={() =>
                      setFieldValue('lavatories', !values.lavatories)
                    }
                    checked={values.lavatories}
                  />
                  <Input
                    md='3'
                    type='switch'
                    id='sinks'
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_SINKS_FIELD_LABEL',
                    })}
                    onChange={() => setFieldValue('sinks', !values.sinks)}
                    checked={values.sinks}
                  />
                  <Input
                    md='3'
                    type='switch'
                    id='trashCans'
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_TRASHCANS_FIELD_LABEL',
                    })}
                    onChange={() =>
                      setFieldValue('trashCans', !values.trashCans)
                    }
                    checked={values.trashCans}
                  />
                  <Input
                    md='3'
                    type='switch'
                    id='diningRooms'
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_DININGROOMS_FIELD_LABEL',
                    })}
                    onChange={() =>
                      setFieldValue('diningRooms', !values.diningRooms)
                    }
                    checked={values.diningRooms}
                  />
                  <Input
                    md='3'
                    type='switch'
                    id='signs'
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_SIGNS_FIELD_LABEL',
                    })}
                    onChange={() => setFieldValue('signs', !values.signs)}
                    checked={values.signs}
                  />
                  <Input
                    md='3'
                    type='switch'
                    id='recycleCans'
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_RECYCLECANS_FIELD_LABEL',
                    })}
                    onChange={() =>
                      setFieldValue('recycleCans', !values.recycleCans)
                    }
                    checked={values.recycleCans}
                  />
                  <Input
                    md='3'
                    type='switch'
                    id='wasteAreas'
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_WASTEAREAS_FIELD_LABEL',
                    })}
                    onChange={() =>
                      setFieldValue('wasteAreas', !values.wasteAreas)
                    }
                    checked={values.wasteAreas}
                  />
                  <Input
                    md='3'
                    type='switch'
                    id='incomePolicies'
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_INCOMEPOLICIES_FIELD_LABEL',
                    })}
                    onChange={() =>
                      setFieldValue('incomePolicies', !values.incomePolicies)
                    }
                    checked={values.incomePolicies}
                  />
                </BaseForm.Row>
                <BaseForm.Row className='mt-4'>
                  <Input
                    className='mt-4'
                    md='4'
                    type='switch'
                    id='hasWaterHole'
                    name='hasWaterHole'
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_HAS_WATERHOLE_LABEL',
                    })}
                    onChange={() =>
                      setFieldValue('hasWaterHole', !values.hasWaterHole)
                    }
                    checked={values.hasWaterHole}
                  />
                  {values.hasWaterHole ? (
                    <>
                      <Input
                        type='text'
                        md='4'
                        name='cnaConcesion'
                        value={values.cnaConcesion}
                        onChange={handleChange}
                        error={errors.cnaConcesion}
                        isInvalid={errors.cnaConcesion}
                        label={intl.formatMessage({
                          id: 'PROPERTIES.PROPERTY_CNACONCESION_LABEL',
                        })}
                      />
                      <Input
                        type='text'
                        md='4'
                        name='waterHoleDeepness'
                        value={values.waterHoleDeepness}
                        onChange={handleChange}
                        error={errors.waterHoleDeepness}
                        isInvalid={errors.waterHoleDeepness}
                        label={intl.formatMessage({
                          id: 'PROPERTIES.PROPERTY_DEPTH_LABEL',
                        })}
                      />
                      <Input
                        type='text'
                        md='4'
                        name='waterPumpCapacity'
                        value={values.waterPumpCapacity}
                        onChange={handleChange}
                        error={errors.waterPumpCapacity}
                        isInvalid={errors.waterPumpCapacity}
                        label={intl.formatMessage({
                          id: 'PROPERTIES.PROPERTY_WATERPUMPCAPACITY_LABEL',
                        })}
                      />
                      <Input
                        type='text'
                        md='4'
                        name='pipeDiamenter'
                        value={values.pipeDiamenter}
                        onChange={handleChange}
                        error={errors.pipeDiamenter}
                        isInvalid={errors.pipeDiamenter}
                        label={intl.formatMessage({
                          id: 'PROPERTIES.PROPERTY_PIPEWIDTH_LABEL',
                        })}
                      />
                      <Input
                        type='number'
                        md='4'
                        name='litersPerSecond'
                        value={values.litersPerSecond}
                        onChange={handleChange}
                        error={errors.litersPerSecond}
                        isInvalid={errors.litersPerSecond}
                        label={intl.formatMessage({
                          id:
                            'PROPERTIES.PROPERTY_LITERSPERSECOND_CAPACITY_LABEL',
                        })}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </BaseForm.Row>
              </Card.Body>
            </Card>

            <Card className='mb-4'>
              <Card.Body>
                <p>
                  {intl.formatMessage({
                    id: 'PROPERTIES.DETAIL_SIXTH_TITLE',
                  })}
                </p>
                <BaseForm.Row>
                  <Input
                    md='4'
                    type='text'
                    name='waterTanks'
                    value={values.waterTanks}
                    onChange={handleChange}
                    error={errors.waterTanks}
                    isInvalid={errors.waterTanks}
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_NOTANQUES_LABEL',
                    })}
                    placeholder='10'
                  />

                  <Input
                    md='4'
                    type='text'
                    name='tanksCapacity'
                    value={values.tanksCapacity}
                    onChange={handleChange}
                    error={errors.tanksCapacity}
                    isInvalid={errors.tanksCapacity}
                    label={intl.formatMessage({
                      id: 'PROPERTIES.PROPERTY_CAPACITY_LABEL',
                    })}
                    placeholder='10'
                  />
                </BaseForm.Row>
                <p className='mt-4'>
                  {intl.formatMessage({
                    id: 'PROPERTIES.DETAIL_FIFTH_TITLE',
                  })}
                </p>
                <BaseForm.Row className='mt-2'>
                  <Input
                    className='mt-4'
                    md='4'
                    type='switch'
                    id={irrigationSystemType.CONVENTIONAL}
                    name={irrigationSystemType.CONVENTIONAL}
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.IRRIGATION_SYSTEM_CONVENTIONAL',
                    })}
                    onChange={() =>
                      setFieldValue(
                        'irrigationSystemType',
                        irrigationSystemType.CONVENTIONAL,
                      )
                    }
                    checked={
                      values.irrigationSystemType ===
                      irrigationSystemType.CONVENTIONAL
                    }
                  />
                  <Input
                    className='mt-4'
                    md='4'
                    type='switch'
                    id={irrigationSystemType.HYDROPONICS}
                    name={irrigationSystemType.HYDROPONICS}
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.IRRIGATION_SYSTEM_HYDROPONICS',
                    })}
                    onChange={() =>
                      setFieldValue(
                        'irrigationSystemType',
                        irrigationSystemType.HYDROPONICS,
                      )
                    }
                    checked={
                      values.irrigationSystemType ===
                      irrigationSystemType.HYDROPONICS
                    }
                  />
                  <Input
                    className='mt-4'
                    md='4'
                    type='switch'
                    id='irrigationSystemType'
                    name='irrigationSystemType'
                    placeholder={intl.formatMessage({
                      id: 'PROPERTIES.IRRIGATION_SYSTEM_SUBSTRATUM',
                    })}
                    onChange={() =>
                      setFieldValue(
                        'irrigationSystemType',
                        irrigationSystemType.SUBSTRATUM,
                      )
                    }
                    checked={
                      values.irrigationSystemType ===
                      irrigationSystemType.SUBSTRATUM
                    }
                  />
                </BaseForm.Row>

                {values.irrigationSystemType ===
                irrigationSystemType.CONVENTIONAL ? (
                  <BaseForm.Row>
                    <Input
                      type='text'
                      md='3'
                      name='convHose'
                      value={values.convHose}
                      onChange={handleChange}
                      error={errors.convHose}
                      isInvalid={errors.convHose}
                      label={intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_HOSE_LABEL',
                      })}
                    />
                    <Input
                      type='text'
                      md='3'
                      name='convController'
                      value={values.convController}
                      onChange={handleChange}
                      error={errors.convController}
                      isInvalid={errors.convController}
                      label={intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_CONTROLLER_LABEL',
                      })}
                    />
                    <Input
                      type='text'
                      md='3'
                      name='convWaterPump'
                      value={values.convWaterPump}
                      onChange={handleChange}
                      error={errors.convWaterPump}
                      isInvalid={errors.convWaterPump}
                      label={intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_WATERPUMP_LABEL',
                      })}
                    />
                    <Input
                      type='text'
                      md='3'
                      name='convTanks'
                      value={values.convTanks}
                      onChange={handleChange}
                      error={errors.convTanks}
                      isInvalid={errors.convTanks}
                      label={intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_TANKS_LABEL',
                      })}
                    />
                    <Input
                      type='text'
                      md='3'
                      name='convIrrigationHead'
                      value={values.convIrrigationHead}
                      onChange={handleChange}
                      error={errors.convIrrigationHead}
                      isInvalid={errors.convIrrigationHead}
                      label={intl.formatMessage({
                        id:
                          'PROPERTIES.PROPERTY_CONVENTIONAL_IRRIGATIONHEAD_LABEL',
                      })}
                    />
                    <Input
                      type='text'
                      md='3'
                      name='convFilters'
                      value={values.convFilters}
                      onChange={handleChange}
                      error={errors.convFilters}
                      isInvalid={errors.convFilters}
                      label={intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_FILTERS_LABEL',
                      })}
                    />
                    <Input
                      type='text'
                      md='3'
                      name='convValves'
                      value={values.convValves}
                      onChange={handleChange}
                      error={errors.convValves}
                      isInvalid={errors.convValves}
                      label={intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_CONVENTIONAL_VALVES_LABEL',
                      })}
                    />
                  </BaseForm.Row>
                ) : (
                  <></>
                )}

                {values.irrigationSystemType ===
                irrigationSystemType.HYDROPONICS ? (
                  <BaseForm.Row>
                    <Input
                      type='text'
                      md='6'
                      name='picketsPerPlant'
                      value={values.picketsPerPlant}
                      onChange={handleChange}
                      error={errors.picketsPerPlant}
                      isInvalid={errors.picketsPerPlant}
                      label={intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_HYDROPONICS_PICKETSPERPLANT',
                      })}
                    />
                    <Input
                      type='text'
                      md='6'
                      name='flowerpot'
                      value={values.flowerpot}
                      onChange={handleChange}
                      error={errors.flowerpot}
                      isInvalid={errors.flowerpot}
                      label={intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_HYDROPONICS_FLOWERPOT',
                      })}
                    />
                  </BaseForm.Row>
                ) : (
                  <></>
                )}

                {values.irrigationSystemType ===
                irrigationSystemType.SUBSTRATUM ? (
                  <BaseForm.Row>
                    <Input
                      type='text'
                      md='4'
                      name='subBrand'
                      value={values.subBrand}
                      onChange={handleChange}
                      error={errors.subBrand}
                      isInvalid={errors.subBrand}
                      label={intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_SUBSTRATUM_BRAND_LABEL',
                      })}
                    />
                    <Input
                      type='text'
                      md='4'
                      name='subComposition'
                      value={values.subComposition}
                      onChange={handleChange}
                      error={errors.subComposition}
                      isInvalid={errors.subComposition}
                      label={intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_SUBSTRATUM_COMPOSITION_LABEL',
                      })}
                    />
                    <Input
                      type='text'
                      md='4'
                      name='subMix'
                      value={values.subMix}
                      onChange={handleChange}
                      error={errors.subMix}
                      isInvalid={errors.subMix}
                      label={intl.formatMessage({
                        id: 'PROPERTIES.PROPERTY_SUBSTRATUM_MIX_LABEL',
                      })}
                    />
                    <Input
                      type='text'
                      md='4'
                      name='subIrrigationSectors'
                      value={values.subIrrigationSectors}
                      onChange={handleChange}
                      error={errors.subIrrigationSectors}
                      isInvalid={errors.subIrrigationSectors}
                      label={intl.formatMessage({
                        id:
                          'PROPERTIES.PROPERTY_SUBSTRATUM_IRRIGATION_SECTORS_LABEL',
                      })}
                    />
                    <Input
                      type='text'
                      md='4'
                      name='subAnnualConsumption'
                      value={values.subAnnualConsumption}
                      onChange={handleChange}
                      error={errors.subAnnualConsumption}
                      isInvalid={errors.subAnnualConsumption}
                      label={intl.formatMessage({
                        id:
                          'PROPERTIES.PROPERTY_SUBSTRATUM_ANNUAL_CONSUMPTION_LABEL',
                      })}
                    />
                    <Input
                      type='text'
                      md='4'
                      name='subTotalRequirement'
                      value={values.subTotalRequirement}
                      onChange={handleChange}
                      error={errors.subTotalRequirement}
                      isInvalid={errors.subTotalRequirement}
                      label={intl.formatMessage({
                        id:
                          'PROPERTIES.PROPERTY_SUBSTRATUM_TOTALREQUIREMENT_LABEL',
                      })}
                    />
                  </BaseForm.Row>
                ) : (
                  <></>
                )}
              </Card.Body>
            </Card>

            <Card className='mb-4'>
              <Card.Header>
                <p>
                  {intl.formatMessage({
                    id: 'PROPERTIES.SECTORS_TITLE',
                  })}
                </p>
              </Card.Header>
              <Card.Body>
                <BaseForm.Row>
                  <Col md={3}>
                    <div className='col'>
                      <p>
                        {intl.formatMessage({
                          id: 'PROPERTIES.SECTOR_ALIAS_LABEL',
                        })}
                      </p>
                    </div>
                    <Input
                      type='text'
                      name='alias'
                      placeholder={intl.formatMessage({
                        id: 'PROPERTIES.SECTOR_ALIAS_PLACEHOLDER',
                      })}
                      value={props.alias}
                      onChange={changeAlias}
                    />
                  </Col>
                  <Col md={3}>
                    <div className='col'>
                      <p>
                        {intl.formatMessage({
                          id: 'PROPERTIES.SECTOR_SURFACE_LABEL',
                        })}
                      </p>
                    </div>
                    <Input
                      type='number'
                      name='quantity'
                      placeholder={intl.formatMessage({
                        id: 'PROPERTIES.SECTOR_SURFACE_PLACEHOLDER',
                      })}
                      value={props.surface}
                      onChange={changeSurface}
                    />
                  </Col>
                  <Col md={3}>
                    <p>
                      {intl.formatMessage({
                        id: 'DELIVERIES.VARIETY_LABEL',
                      })}
                    </p>
                    <Forms.Control
                      as='select'
                      name='variety'
                      onChange={changeVariety}
                      value={props.selectedVariety}
                    >
                      <option value=''>
                        {intl.formatMessage({
                          id: 'DELIVERIES.VARIETY_SELECT_PLACEHOLDER',
                        })}
                      </option>
                      {props.varieties.map((variety) => (
                        <option value={variety.id} key={variety.id}>
                          {variety.plant} - {variety.name}
                        </option>
                      ))}
                    </Forms.Control>
                  </Col>
                  <Col md={3}>
                    <br />
                    <LoadingButton onClick={props.addDetail}>
                      Agregar
                    </LoadingButton>
                  </Col>
                </BaseForm.Row>
              </Card.Body>
              <Card.Body>
                <table className='starter-list table table-bordered table-striped'>
                  <thead>
                    <tr>
                      <th>
                        {intl.formatMessage({
                          id: 'PROPERTIES.TABLE_SECTORS_NAME_LABEL',
                        })}
                      </th>
                      <th>
                        {intl.formatMessage({
                          id: 'PROPERTIES.TABLE_SECTORS_SURFACE_LABEL',
                        })}
                      </th>
                      <th>
                        {intl.formatMessage({
                          id: 'PROPERTIES.TABLE_SECTORS_VARIETY_LABEL',
                        })}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.sectors.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.alias}</td>
                          <td>{item.surface}</td>
                          <td>{item.name}</td>
                          <td>
                            <Button onClick={() => props.deleteDetail(index)}>
                              <FaTrashAlt></FaTrashAlt>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </BaseForm>
          <div className='d-flex justify-content-between'>
            <div className='text-right'>
              <LoadingButton
                variant='primary'
                className='btn-bold'
                type='submit'
                onClick={handleSubmit}
                loading={props.loading}
              >
                {intl.formatMessage({
                  id: 'GENERAL.SAVE_BUTTON_TEXT',
                })}
              </LoadingButton>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}
