import {API} from '../API';
import PaginatedList from '../PaginatedList';
import SoilType from 'common/models/SoilType';
import {store} from 'common/redux/StoreReducers';

const SoilTypesApiService = {
  get: async (pageNumber = 1, filters) => {
    let url = `/soilTypes/?page=${pageNumber}`;

    if (filters.keyword) {
      url += `&search=${filters.keyword}`;
    }

    const {data: response} = await API.get(url);

    const list = new PaginatedList();
    list.items = response.data.map((wt) => new SoilType(wt));
    list.page = response.meta.page;
    list.pageCount = response.meta.lastPage;
    list.pageSize = response.meta.perPage;
    list.total = response.meta.total;
    return list;
  },
  detail: async (id) => {
    const {data: response} = await API.get(`/soilTypes/${id}`);
    const soilType = new SoilType(response.data);

    return soilType;
  },
  save: async (params) => {
    await API.post('/soilTypes', params, {
      headers: {'Accept-Language': store.getState().langReducer.lang},
    });

    return;
  },
  update: async (id, input) => {
    const {data: response} = await API.put(`/soilTypes/${id}`, input);

    return {soilType: new SoilType(response.data)};
  },
  delete: async (id) => {
    await API.delete(`/soilTypes/${id}`);

    return {};
  },
};

export default SoilTypesApiService;
