import React, {useState, useEffect, useCallback} from 'react';
import Alert from 'react-bootstrap/Alert';
import {useIntl} from 'react-intl';

import StarterList from 'components/starter/StarterList';

import API from 'common/utils/API';

export const ReportsList = () => {
  const [reports, setReports] = useState([]);
  const [varieties, setVarieties] = useState([]);
  const [users, setUsers] = useState([]);
  const [pageConfig, setPageConfig] = useState({
    page: 1,
    pageSize: 12,
    pageCount: 2,
    total: 0,
  });
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [fromFilter, setFromFilter] = useState('');
  const [toFilter, setToFilter] = useState('');
  const [varietyFilter, setVarietyFilter] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [error, setError] = useState(false);
  const intl = useIntl();

  const clearErrorMessage = () => {
    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  const fetchData = useCallback(async (pageNumber = 1, filters = {}) => {
    try {
      const result = await API.reports.get(pageNumber, filters);

      setReports(result.items);
      setPageConfig({
        page: result.page,
        pageSize: result.pageSize,
        pageCount: result.pageCount,
        total: result.total,
      });
      setError(false);
    } catch (error) {
      clearErrorMessage();
      setError(true);
    }
  }, []);

  const fetchFilters = useCallback(async () => {
    const varieties = await API.varieties.all();
    const supervisors = await API.supervisors.all();
    const licensees = await API.licensees.all();

    const formattedUsers = [
      ...supervisors.map((supervisor) => ({
        value: `${supervisor.id}-supervisor`,
        label: supervisor.fullName,
      })),
      ...licensees.map((licensee) => ({
        value: `${licensee.id}-licensee`,
        label: `${licensee.firstName}${
          licensee.lastName ? ` ${licensee.lastName}` : ''
        }`,
      })),
    ];

    const formattedVarieties = varieties.map(({id, name}) => ({
      value: id,
      label: `${name[0]}${name.slice(1).toLowerCase()}`,
    }));

    setVarieties(formattedVarieties);
    setUsers(formattedUsers);
  }, []);

  const handleAutocompleteOrDateSearch = useCallback(
    (value, filter) => {
      const filters = {
        from: fromFilter,
        to: toFilter,
        variety: varietyFilter,
        user: userFilter,
      };

      if (searchTimeout > 0) {
        clearTimeout(searchTimeout);
      }

      if (filter === 'from') {
        setFromFilter(value);
        filters.from = value;
      }

      if (filter === 'to') {
        setToFilter(value);
        filters.to = value;
      }

      setSearchTimeout(
        setTimeout(() => {
          fetchData(1, filters);
        }, 300),
      );
    },
    [fetchData, fromFilter, searchTimeout, toFilter, userFilter, varietyFilter],
  );

  const handlePickersChange = useCallback(
    ({target: {name, value}}) => {
      const filters = {
        from: fromFilter,
        to: toFilter,
        variety: varietyFilter,
        user: userFilter,
      };

      if (searchTimeout > 0) {
        clearTimeout(searchTimeout);
      }

      if (name === 'variety') {
        setVarietyFilter(value);
        filters.variety = value;
      }

      if (name === 'user') {
        setUserFilter(value);
        filters.user = value;
      }

      setSearchTimeout(
        setTimeout(() => {
          fetchData(1, filters);
        }, 300),
      );
    },
    [fetchData, fromFilter, searchTimeout, toFilter, userFilter, varietyFilter],
  );

  const handleSearchClear = useCallback(
    (filter) => {
      const filters = {
        from: fromFilter,
        to: toFilter,
        variety: varietyFilter,
      };

      if (filter === 'from') {
        setFromFilter('');
      }

      if (filter === 'to') {
        setToFilter('');
      }

      if (filter === 'variety') {
        setVarietyFilter('');
      }

      if (filter === 'user') {
        setUserFilter('');
      }

      filters[filter] = '';

      fetchData(1, filters);
    },
    [fetchData, fromFilter, toFilter, varietyFilter],
  );

  const handlePageChange = useCallback(
    (pageNumber) => {
      fetchData(pageNumber);
    },
    [fetchData],
  );

  useEffect(() => {
    fetchData();
    fetchFilters();
  }, [fetchData, fetchFilters]);

  return (
    <div data-test='reportsListComponent'>
      <Alert
        data-test='errorAlert'
        variant='danger'
        className='mb-4'
        show={error}
      >
        <p className='mb-0'>
          {intl.formatMessage({
            id: 'ERROR_PAGES.LOADING_DATA',
          })}
        </p>
      </Alert>
      <StarterList
        hideSearchInput
        onSearchKeywordClear={handleSearchClear}
        onSearchKeywordChange={handlePickersChange}
        onAutocompleteOrDateSearchChange={handleAutocompleteOrDateSearch}
        onPageChange={handlePageChange}
        page={pageConfig.page}
        pageSize={pageConfig.pageSize}
        total={pageConfig.total}
        data={{
          headerTitles: [
            '#',
            intl.formatMessage({
              id: 'REPORTS.PRODUCTOR_NAME_TABLE_HEADER',
            }),
            intl.formatMessage({
              id: 'REPORTS.PRODUCTION_NAME_TABLE_HEADER',
            }),
            intl.formatMessage({
              id: 'REPORTS.SURFACE_NAME_TABLE_HEADER',
            }),
            intl.formatMessage({
              id: 'REPORTS.CREATED_BY_NAME_TABLE_HEADER',
            }),
          ],
          rows: reports.map((report) => ({
            content: [
              report.id,
              report.producer,
              report.plantationFolio,
              report.hectares,
              report.createdBy,
            ],
            link: `/reports/${report.id}`,
          })),
        }}
        searchFields={[
          {
            name: 'from',
            placeholder: intl.formatMessage({
              id: 'REPORTS.FROM_DATE_TABLE_FILTER',
            }),
            maxDate: toFilter,
            value: fromFilter,
            date: true,
          },
          {
            name: 'to',
            placeholder: intl.formatMessage({
              id: 'REPORTS.TO_DATE_TABLE_FILTER',
            }),
            minDate: fromFilter,
            value: toFilter,
            date: true,
          },
          {
            options: varieties,
            name: 'variety',
            placeholder: intl.formatMessage({
              id: 'REPORTS.PLANTS_TABLE_FILTER',
            }),
            value: varietyFilter,
          },
          {
            options: users,
            name: 'user',
            placeholder: intl.formatMessage({
              id: 'REPORTS.USER_TABLE_FILTER',
            }),
            value: userFilter,
          },
        ]}
      />
    </div>
  );
};

export default ReportsList;
