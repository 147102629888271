import React from 'react';
import { injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {Row, Col} from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import LoadingButton from '../../components/form/LoadingButton';
import API from '../../common/utils/API';
import Production from '../../common/models/Production';
import MapContainer from '../../components/Map'

export class ProductionDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            production: new Production(),
            loading: false,
            isToggleUserStatusModalOpen: false,
            isLoadErrorModalOpen: false,
            isDeleteModalOpen: false,
            error: '',
        }
    }

    async componentDidMount() {
        await this.loadDetail();
    }

    loadDetail = async () => {
        this.setState({
            loading: true,
        });
        try {
            const production = await API.productions.detail(this.props.match.params.id);
            this.setState({
                production,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (error) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    }

    openModalDelete = () =>{
        this.setState({
            isDeleteModalOpen: true,
        })
    }

    render() {
        return (
            <>
                <Alert data-test='errorAlert'
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}>

                </Alert>

                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/productions'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>

                <Card className='mb-4' data-test='userDetailComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'USERS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col md={1}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_ID_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.id}</p>
                                    </Col>
                                    <Col md={2}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_PRODUCER_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.producer}</p>
                                    </Col>
                                    <Col md={2}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_LICENSEE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.licensee}</p>
                                    </Col>
                                    <Col md={2}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_DELIVERY_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.deliveryId}</p>
                                    </Col>
                                    <Col md={4}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_PLANTATION_DATE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.plantationDateFormatted}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>

                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'PRODUCTIONS.PRODUCTIONS_PLANTATION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col md={3}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_VIVARIUM_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.vivarium}</p>
                                    </Col>
                                    <Col md={3}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_PLANT_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.plant}</p>
                                    </Col>
                                    <Col md={3}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_CYCLE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.productionCycle} {this.props.intl.formatMessage({id:'PRODUCTIONS.PRODUCTION_CYCLE_UNIT'})}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_DISTANCE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.distanceBetweenGrooves}</p>
                                    </Col>
                                    <Col md={2}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_DISTANCE_PLANTS_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.distanceBetweenPlants}</p>
                                    </Col>
                                    <Col md={2}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_HECTARES_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.cultivatedHectares}</p>
                                    </Col>
                                    <Col md={2}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_DENSITY_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.density}</p>
                                    </Col>
                                    <Col md={2}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'PRODUCTIONS.PRODUCTION_PLANTATION_FRAMEWORK_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.production.plantationFramework}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'PRODUCTIONS.PRODUCTION_PROPERTY_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.production.property}</p>
                            </Col>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'PRODUCTIONS.PRODUCTION_ADDRESS_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.production.address}</p>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Col>
                            <div style={{ height: `350px`, width: `100% !important`,
                            border:'1px solid #999'}}>
                                <MapContainer 
                                    lat={this.state.production.latitude}
                                    lng={this.state.production.longitude}
                                    API_KEY={process.env.REACT_APP_GOOGLE_API_KEY} 
                                    property={this.state.production.property}
                                    address={this.state.production.address}
                                />
                            </div>
                        </Col>
                    </Card.Body>
                </Card>
                <Modal
                    show={this.state.isLoadErrorModalOpen}
                    onHide={this.toggleLoadErrorModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'PRODUCTIONS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'PRODUCTIONS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            href='/states'
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.loadDetail}
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
export default injectIntl(ProductionDetail);