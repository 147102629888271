import React from 'react';
import {useIntl} from 'react-intl';

function Dashboard() {
    const intl = useIntl();

    return (
        <>
            <p>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</p>
        </>
    );
}

export default Dashboard;
