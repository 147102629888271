export default class Municipality {
    constructor({
        id = 0,
        name = '',
        stateId =0,
        state =''
    } = {}) {
        this.id = id;
        this.name = name;
        this.stateId = stateId;
        this.state = state;
    }
}