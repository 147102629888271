import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import BaseForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Forms from 'react-bootstrap/Form';

import Input from '../Input';
import LoadingButton from '../LoadingButton';
import ConfirmModal from '../ConfirmModal';
import Can from '../../../common/security/Can';

export default function Form(props) {
    const intl = useIntl();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [passwordToggle, setPasswordToggle] = useState(true);
    const [passwordConfirmToggle, setPasswordConfirmToggle] = useState(true);
    const [passwordFieldsToggle, setPasswordFieldsToggle] = useState(false);
    const validationSchema = Yup.object({
        firstName: Yup.string().required(
            intl.formatMessage({id: 'LICENSEES.VALIDATION_NAME_MISSING'}),
        ),
        lastName: Yup.string().required(
            intl.formatMessage({id: 'LICENSEES.VALIDATION_LASTNAME_MISSING'}),
        ),
        email: Yup.string().email(
                intl.formatMessage({id: 'LICENSEES.VALIDATION_EMAIL_INVALID'})
            ).required(
                intl.formatMessage({id: 'LICENSEES.VALIDATION_EMAIL_MISSING'})
        ),
        phone: Yup.string().matches(
            new RegExp(
                '^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$',
            ),
            intl.formatMessage({id: 'LICENSEES.VALIDATION_PHONE_INVALID'})
        ).required(
            intl.formatMessage({id: 'LICENSEES.VALIDATION_PHONE_MISSING'})
        ),
        rfc: Yup.string()
            .matches(
                new RegExp(/^([A-Za-zñÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Za-z\d]{3}))?$/)
                ,
                intl.formatMessage({id: 'LICENSEES.VALIDATION_RFC_INVALID'})
            ).required(
            intl.formatMessage({id: 'LICENSEES.VALIDATION_RFC_MISSING'}),
        ),
        address: Yup.string().required(
            intl.formatMessage({id: 'LICENSEES.VALIDATION_ADDRESS_MISSING'}),
        ),
        location: Yup.string().required(
            intl.formatMessage({id: 'LICENSEES.VALIDATION_LOCATION_MISSING'}),
        ),
        zipCode: Yup.number(
            intl.formatMessage({id: 'LICENSEES.VALIDATION_ZIPCODE_INVALID'}),
        ).required(
            intl.formatMessage({id: 'LICENSEES.VALIDATION_ZIPCODE_MISSING'}),
        ),
        stateId: Yup.number().required(
            intl.formatMessage({id: 'LICENSEES.VALIDATION_STATE_MISSING'}),
        ),
        municipalityId: Yup.number().required(
            intl.formatMessage({id: 'LICENSEES.VALIDATION_MUNICIPALITY_MISSING'}),
        ),
        fiscalType: Yup.string().required(
            intl.formatMessage({id: 'LICENSEES.VALIDATION_FISCALTYPE_MISSING'})
        ),
        fiscalName: Yup.string().when('fiscalType',{
            is: 'moral',
            then: Yup.string().required(intl.formatMessage({id: 'LICENSEES.VALIDATION_FISCALNAME_MISSING'})),
            otherwise: Yup.string().optional()
        }),
        password: Yup.string()
            .when((value, schema) => {
                if (!props.isEdit || (props.isEdit && passwordFieldsToggle)) {
                    return schema.required(
                        intl.formatMessage({
                            id: 'USERS.VALIDATION.PASSWORD_MISSING',
                        }),
                    );
                }
            })
            .min(
                8,
                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_MIN',
                }),
            )
            .max(
                24,
                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_MAX',
                }),
            )
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S*$/,
                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_FORMAT',
                }),
            ),
        passwordConfirmation: Yup.string()
            .when((value, schema) => {
                if (!props.isEdit || (props.isEdit && passwordFieldsToggle)) {
                    return schema.required(
                        intl.formatMessage({
                            id:
                                'USERS.VALIDATION.PASSWORD_CONFIRMATION_MISSING',
                        }),
                    );
                }
            })
            .oneOf(
                [Yup.ref('password')],

                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_CONFIRMATION_DOES_NOT_MATCH',
                }),
            )
            .min(
                8,

                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_MIN',
                }),
            )
            .max(
                24,
                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_MAX',
                }),
            )
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S*$/,
                intl.formatMessage({
                    id: 'USERS.VALIDATION.PASSWORD_FORMAT',
                }),
            ),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const handleTogglePassword = () => setPasswordToggle(!passwordToggle);
    const handleTogglePasswordConfirm = () =>
        setPasswordConfirmToggle(!passwordConfirmToggle);


    const handleToggleIsDeleteModal = () => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const handleStateChange = (event) => {
        if(event) {
            props.stateChange(event.target.value);
        }
    }

    const handleToggleChangePassword = () => {
        setPasswordFieldsToggle(!passwordFieldsToggle);
    };

    return (
        <Formik
            data-test='licenseeFormComponent'
            enableReinitialize={true}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({handleSubmit, values, errors, handleChange}) => (
                <>
                    <div className='mb-4 d-flex justify-content-end'>
                        <Button
                            data-test='backButton'
                            as={NavLink}
                            to={props.backRedirectUrl}
                            className='btn btn-dark btn-bold btn-light-dark'
                        >
                            {props.backText}
                        </Button>
                    </div>

                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'LICENSEES.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                                })}
                            </Card.Header>
                            
                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md='6'>
                                        <Input md='12' type='text' name='firstName' 
                                            value={values.firstName}
                                            label={intl.formatMessage({id: 'LICENSEES.LICENSEE_NAME_FIELD_LABEL'})}
                                            placeholder={intl.formatMessage({id: 'LICENSEES.LICENSEE_NAME_FIELD_PLACEHOLDER'})}
                                            onChange={handleChange}
                                            error={errors.firstName}
                                            isInvalid={errors.firstName} />
                                    </Col>
                                    <Col md='6'>
                                        <Input md='12' type='text' name='lastName' value={values.lastName}
                                            label={intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_LASTNAME_FIELD_LABEL',
                                            })}
                                            placeholder={intl.formatMessage({
                                                    id: 'LICENSEES.LICENSEE_LASTNAME_FIELD_PLACEHOLDER',
                                                },
                                            )}
                                            onChange={handleChange}
                                            error={errors.lastName}
                                            isInvalid={errors.lastName} />
                                    </Col>
                                </BaseForm.Row>
                                
                                <BaseForm.Row>
                                    <Col md='4'>
                                        <Input md='12' type='email' name='email' value={values.email}
                                            label={intl.formatMessage({id: 'LICENSEES.LICENSEE_EMAIL_FIELD_LABEL'})}
                                            placeholder={intl.formatMessage({id: 'LICENSEES.LICENSEE_EMAIL_FIELD_PLACEHOLDER',})}
                                            onChange={handleChange}
                                            error={errors.email}
                                            isInvalid={errors.email} />
                                    </Col>
                                    <Col md='4'>
                                        <Input md='12' type='text' name='phone' value={values.phone}
                                            label={intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_PHONE_FIELD_LABEL',
                                            })}
                                            placeholder={intl.formatMessage({
                                                    id: 'LICENSEES.LICENSEE_PHONE_FIELD_PLACEHOLDER',
                                                })}
                                            error={errors.phone}
                                            onChange={handleChange}
                                            isInvalid={errors.phone} />
                                    </Col>
                                    <Col md='4'>
                                        <Input md='12' type='text' name='rfc' maxLength="14"
                                            style={{textTransform: 'uppercase'}}
                                            value={values.rfc}
                                            label={intl.formatMessage({id: 'LICENSEES.LICENSEE_RFC_FIELD_LABEL'})}
                                            placeholder={intl.formatMessage({id: 'LICENSEES.LICENSEE_RFC_FIELD_PLACEHOLDER'})}
                                            error={errors.rfc}
                                            onChange={handleChange}
                                            isInvalid={errors.rfc} />
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>

                            <Card.Header>
                                {intl.formatMessage({id:'LICENSEES.GENERAL_INFORMATION_FORM_SECTION_ADDRESS'})}
                            </Card.Header>

                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md='5'>
                                        <Input md='12' type='text' name='address' value={values.address}
                                            label={intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_ADDRESS_FIELD_LABEL',
                                            })}
                                            placeholder={intl.formatMessage({
                                                    id: 'LICENSEES.LICENSEE_ADDRESS_FIELD_PLACEHOLDER',
                                                })}
                                            error={errors.address}
                                            onChange={handleChange}
                                            isInvalid={errors.address} />
                                    </Col>
                                    <Col md='2'>
                                        <Input md='12' type='text' name='noInt' value={values.noInt}
                                            label={intl.formatMessage({id: 'LICENSEES.LICENSEE_NOINT_FIELD_LABEL'})}
                                            placeholder={intl.formatMessage({id: 'LICENSEES.LICENSEE_NOINT_FIELD_PLACEHOLDER'})}
                                            onChange={handleChange} />
                                    </Col>
                                    <Col md='5'>
                                        <Input md='12' type='text' name='location' value={values.location}
                                            label={intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_LOCATION_FIELD_LABEL',
                                            })}
                                            placeholder={intl.formatMessage({
                                                    id: 'LICENSEES.LICENSEE_LOCATION_FIELD_PLACEHOLDER',
                                                })}
                                            error={errors.location}
                                            onChange={handleChange}
                                            isInvalid={errors.location} />
                                    </Col>
                                </BaseForm.Row>

                                <BaseForm.Row>
                                    <Col md='3'>
                                        <Input md='12' type='text' name='zipCode' maxLength="10" value={values.zipCode}
                                            label={intl.formatMessage({
                                                id: 'LICENSEES.LICENSEE_ZIPCODE_FIELD_LABEL',
                                            })}
                                            placeholder={intl.formatMessage({
                                                    id: 'LICENSEES.LICENSEE_ZIPCODE_FIELD_PLACEHOLDER',
                                                })}
                                            onChange={handleChange}
                                            error={errors.zipCode}
                                            isInvalid={errors.zipCode} />
                                    </Col>
                                    <Col md='4'>
                                        <Col md='12'>
                                            <label className='form-label'>
                                                {intl.formatMessage({
                                                    id: 'LICENSEES.LICENSEE_FISCALTYPE_FIELD_LABEL',
                                                })}
                                            </label>
                                        </Col>
                                        <Col md='12'>
                                            <Forms.Control
                                                as='select' md='12'
                                                data-test='searchSelect'
                                                value={values.fiscalType}
                                                error={errors.fiscalType}
                                                isInvalid={errors.fiscalType} 
                                                onChange={handleChange}
                                                name="fiscalType"
                                            >
                                                <option value=''>
                                                    {intl.formatMessage({id: 'LICENSEES.LICENSEE_FISCALTYPE_SELECT_ITEM'})}
                                                </option>
                                                {props.fiscalTypes.map(({id, name}) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </Forms.Control>
                                            <small show={errors.fiscalType} className="text-danger form-text">
                                                {errors.fiscalType}
                                            </small>
                                        </Col>
                                    </Col>
                                    <Col md='5'>
                                        <Input md='12' type='text' name='fiscalName' value={values.fiscalName}
                                            label={intl.formatMessage({id: 'LICENSEES.LICENSEE_FISCALNAME_FIELD_LABEL'})}
                                            placeholder={intl.formatMessage({id: 'LICENSEES.LICENSEE_FISCALNAME_FIELD_PLACEHOLDER'})}
                                            onChange={handleChange}
                                            error={errors.fiscalName}
                                            isInvalid={errors.fiscalName} />
                                    </Col>
                                </BaseForm.Row>

                                <BaseForm.Row>
                                    <Col md='6' className='form-group'>
                                        <Col md='12'>
                                            <label className='form-label'>
                                                {intl.formatMessage({
                                                    id: 'LICENSEES.LICENSEE_STATE_FIELD_LABEL',
                                                })}
                                            </label>
                                        </Col>
                                        <Col md='12'>
                                            <Forms.Control
                                                as='select' md='12'
                                                data-test='searchSelect'
                                                value={values.stateId}
                                                name="stateId"
                                                onChange={e => {
                                                    handleChange(e);
                                                    handleStateChange(e);
                                                }}
                                                error={errors.stateId}
                                                isInvalid={errors.stateId}
                                            >
                                                <option value=''>
                                                    {intl.formatMessage({id: 'LICENSEES.LICENSEE_STATE_SELECT_ITEM'})}
                                                </option>
                                                {props.states.map(({id, name}) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </Forms.Control>
                                            <small show={errors.stateId} className="text-danger form-text">
                                                {errors.stateId}
                                            </small>
                                        </Col>
                                    </Col>
                                    <Col md='6' className='form-group'>
                                        <Col md='12'>
                                            <label className='form-label'>
                                                {intl.formatMessage({
                                                    id: 'LICENSEES.LICENSEE_MUNICIPALITY_FIELD_LABEL',
                                                })}
                                            </label>
                                        </Col>
                                        <Col md='12'>
                                            <Forms.Control
                                                as='select'
                                                custom
                                                name='municipalityId'
                                                data-test='searchSelect'
                                                onChange={handleChange}
                                                value={values.municipalityId}
                                                error={errors.municipalityId}
                                                isInvalid={errors.municipalityId}
                                            >
                                                <option value=''>
                                                    {intl.formatMessage({id: 'LICENSEES.LICENSEE_MUNICIPALTY_SELECT_ITEM'})}
                                                </option>
                                                {props.municipalities.map(({id, name}) => (
                                                    <option value={id} key={id}>
                                                        {name}
                                                    </option>
                                                ))}
                                            </Forms.Control>
                                            <small show={errors.municipalityId} className="text-danger form-text">
                                                {errors.municipalityId}
                                            </small>
                                        </Col>
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                            
                            <Card.Header>
                                {intl.formatMessage({id:'LICENSEES.GENERAL_INFORMATION_FORM_SECTION_ACCESS'})}
                            </Card.Header>

                            <Card.Body>
                            {props.isEdit && (
                                    <BaseForm.Row>
                                        <Input
                                            type='switch'
                                            id='changePassword'
                                            placeholder={intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.USER_CHANGE_PASSWORD_FIELD_LABEL',
                                                },
                                            )}
                                            onChange={
                                                handleToggleChangePassword
                                            }
                                            checked={
                                                passwordFieldsToggle
                                            }
                                        />
                                    </BaseForm.Row>
                                )}
                                <BaseForm.Row
                                    hidden={
                                        props.isEdit &&
                                        !passwordFieldsToggle
                                    }>
                                        <Col md='6'>
                                            <Input md='12'
                                                type='password'
                                                name='password'
                                                label={intl.formatMessage({id:'USERS.PASSWORD_FIELD_LABEL'})}
                                                placeholder={intl.formatMessage({id:'USERS.PASSWORD_FIELD_PLACEHOLDER'})}
                                                value={values.password}
                                                onChange={handleChange}
                                                error={errors.password}
                                                isInvalid={errors.password}
                                                hiddenToggle={passwordToggle}
                                                onClickToggle={handleTogglePassword}
                                            />
                                        </Col>
                                        <Col md='6'>
                                            <Input
                                                md='12'
                                                type='password'
                                                name='passwordConfirmation'
                                                label={intl.formatMessage({id:'USERS.CONFIRM_PASSWORD_FIELD_LABEL'})}
                                                placeholder={intl.formatMessage({id:'USERS.CONFIRM_PASSWORD_FIELD_PLACEHOLDER'})}
                                                value={values.passwordConfirmation}
                                                onChange={handleChange}
                                                error={errors.passwordConfirmation}
                                                isInvalid={errors.passwordConfirmation}
                                                hiddenToggle={passwordConfirmToggle}
                                                onClickToggle={handleTogglePasswordConfirm}
                                            />
                                        </Col>
                                </BaseForm.Row>
                            </Card.Body>

                        </Card> 
                    </BaseForm>

                    <div className='d-flex justify-content-between'>
                        {props.isEdit ? (
                            <div>
                                <div>
                                    <Can run='LICENSEES_DELETE' cantRun={<div />}>
                                        <ConfirmModal
                                            titleModal={intl.formatMessage({
                                                id:
                                                    'LICENSEES.DELETE_LICENSEE_MODAL_TITLE',
                                            })}

                                            handleConfirm = {
                                                props.handleDeleteItem
                                            }
                                            handleShow={
                                                handleToggleIsDeleteModal
                                            }
                                            handleClose={
                                                handleToggleIsDeleteModal
                                            }
                                            show={isDeleteModalOpen}
                                            variant='danger'
                                            buttonClassName='btn-bold'
                                            buttonLabel={intl.formatMessage({
                                                id:
                                                    'LICENSEES.DELETE_LICENSEE_BUTTON_LABEL',
                                            })}
                                            buttonAcceptLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'LICENSEES.CONFIRM_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                            buttonCloseLabel={intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.CANCEL_DELETE_BUTTON_LABEL',
                                                },
                                            )}
                                        >
                                            <p>
                                                {intl.formatMessage({
                                                    id:
                                                        'LICENSEES.DELETE_LICENSEE_CONFIRMATION_MESSAGE',
                                                })}
                                            </p>
                                        </ConfirmModal>
                                    </Can>
                                </div>
                            </div>
                        ) : (
                            <div/>
                        )}

                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'GENERAL.SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
