import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import MunicipalitiesList from './MunicipalitiesList';
import CreateMunicipality from './CreateMunicipality';
import MunicipalityDetail from './MunicipalityDetail';
import EditMunicipality from './EditMunicipality';
import withLayout from '../../components/ui/Layout';


function MunicipalitiesRoutes() {
    const intl = useIntl();

    return (
        <Switch data-test='stateComponent'>
            <PrivateRoute  permission='MUNICIPALTIES_INDEX'
                exact
                path='/municipalities'
                component={withLayout(
                    MunicipalitiesList,
                    intl.formatMessage({id: 'ROUTES.MUNICIPALITIES.INDEX'}),
                )}
            />

            <PrivateRoute  permission='MUNICIPALTIES_CREATE'
                exact
                path='/municipalities/create'
                component={withLayout(
                    CreateMunicipality,
                    intl.formatMessage({id: 'ROUTES.MUNICIPALITIES.CREATE'}),
                )}
            />

            <PrivateRoute permission='MUNICIPALITIES_SHOW'
                exact
                path='/municipalities/:id'
                component={withLayout(
                    MunicipalityDetail,
                    intl.formatMessage({id: 'ROUTES.MUNICIPALITIES.SHOW'}),
                )}
            />

            <PrivateRoute permission='MUNICIPALITIES_SHOW'
                exact
                path='/municipalities/:id/edit'
                component={withLayout(
                    EditMunicipality,
                    intl.formatMessage({id: 'ROUTES.MUNICIPALITIES.EDIT'}),
                )}
            />

        </Switch>
    );
}

export default MunicipalitiesRoutes;
