import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import SoilTypesList from 'containers/soil-types/SoilTypesList';
import SoilTypeDetail from 'containers/soil-types/SoilTypeDetail';
import CreateSoilType from 'containers/soil-types/CreateSoilType';
import EditSoilType from 'containers/soil-types/EditSoilType';

function SoilTypesRoutes() {
  const intl = useIntl();

  return (
    <Switch data-test='soilTypesComponent'>
      <PrivateRoute
        permission='SOILTYPES_INDEX'
        exact
        path='/soil-types'
        component={withLayout(
          SoilTypesList,
          intl.formatMessage({id: 'ROUTES.SOILTYPES.INDEX'}),
        )}
      />
      <PrivateRoute
        permission='SOILTYPES_CREATE'
        exact
        path='/soil-types/create'
        component={withLayout(
          CreateSoilType,
          intl.formatMessage({id: 'ROUTES.SOILTYPES.CREATE'}),
        )}
      />
      <PrivateRoute
        permission='SOILTYPES_SHOW'
        exact
        path='/soil-types/:id'
        component={withLayout(
          SoilTypeDetail,
          intl.formatMessage({id: 'ROUTES.SOILTYPES.SHOW'}),
        )}
      />
      <PrivateRoute
        permission='SOILTYPES_EDIT'
        exact
        path='/soil-types/:id/edit'
        component={withLayout(
          EditSoilType,
          intl.formatMessage({id: 'ROUTES.SOILTYPES.EDIT'}),
        )}
      />
    </Switch>
  );
}

export default SoilTypesRoutes;
